const Menu = {
    menuLabel: 'Inventory Manager',
    menus: [
        { label: 'Location', path: '/client/inventory-manager/location', faIconClass: 'fa-sharp fa-solid fa-location-dot' },
        { label: 'Accomodation Type', path: '/client/inventory-manager/accomodationtype', faIconClass: 'fa-solid fa-hotel' },
        { label: 'Units', path: '/client/inventory-manager/units', faIconClass: 'fa-solid fa-building-un' },
        { label: 'Add-ons', path: '/client/inventory-manager/addons', faIconClass: 'fa-solid fa-puzzle-piece' },
    ],
};

export default Menu;
