import React from 'react';
import { Badge, Dropdown } from 'react-bootstrap';

const AlertsDropdown = () => {
    return (
        <>
            {/* Nav Item - Search Dropdown (Visible Only XS) */}
            <Dropdown className="nav-item dropdown no-arrow mx-1">
                <Dropdown.Toggle className="nav-link dropdown-toggle" id="alertsDropdown">
                    <i className="fas fa-bell fa-fw text-black" />
                    {/* Counter - Alerts */}
                    <Badge className="badge badge-danger badge-counter">3+</Badge>
                </Dropdown.Toggle>
                {/* Dropdown - Alerts */}
                <Dropdown.Menu
                    align="end"
                    className="dropdown-list dropdown-menu dropdown-menu-right shadow animated--grow-in"
                >
                    <Dropdown.Header>Alerts Center</Dropdown.Header>
                    <Dropdown.Item className="d-flex align-items-center">
                        <div className="mr-3">
                            <div className="icon-circle bg-primary">
                                <i className="fas fa-file-alt text-white" />
                            </div>
                        </div>
                        <div>
                            <div className="small text-gray-500">December 12, 2019</div>
                            <span className="font-weight-bold">A new monthly report is ready to download!</span>
                        </div>
                    </Dropdown.Item>
                    <Dropdown.Item className="d-flex align-items-center">
                        <div className="mr-3">
                            <div className="icon-circle bg-success">
                                <i className="fas fa-donate text-white" />
                            </div>
                        </div>
                        <div>
                            <div className="small text-gray-500">December 7, 2019</div>
                            $290.29 has been deposited into your account!
                        </div>
                    </Dropdown.Item>
                    <Dropdown.Item className="d-flex align-items-center">
                        <div className="mr-3">
                            <div className="icon-circle bg-warning">
                                <i className="fas fa-exclamation-triangle text-white" />
                            </div>
                        </div>
                        <div>
                            <div className="small text-gray-500">December 2, 2019</div>
                            Spending Alert: noticed unusually high spending for your account.
                        </div>
                    </Dropdown.Item>
                    <Dropdown.Item className="text-center small text-gray-500">Show All Alerts</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        </>
    );
};

export default AlertsDropdown;
