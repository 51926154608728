import { To } from 'react-router-dom';

const Menu = {
    dashboard: {
        faIconClass: 'fa-tachometer-alt',
        path: '/' as To,
        label: 'Dashboard',
    },
    clientProfile: {
        menuHeader: 'Manage Clients',
        basePath: '/admin',
        menuLabel: 'Client Profiles',
        menuLinks: [
            {
                label: 'Create Profile',
                path: '/create',
            },
            {
                label: 'View List',
                path: '/view/list',
            },
        ],
        faIconClass: 'fa-user-tie',
    },
};

export default Menu;
