import React from 'react';
import { Form } from 'react-bootstrap';

export const SearchReservation = () => {
    return (
        <div className="container-fluid" style={{ marginTop: '1rem' }}>
            <div className="row">
                {/* Left Sidebar for Search Filters */}
                <div className="col-md-4">
                    <div className="card shadow">
                        <div className="card-body">
                            <h5 className="card-title">
                                <strong> Search Reservations </strong>
                                <i className="fa-solid fa-circle-question" />
                            </h5>
                            <input type="text" className="form-control mb-3" placeholder="E.g. Smith, nr. 15" />
                            {/* Filter Sections */}
                            <div style={{ height: '460px', overflowY: 'auto' }}>
                                <div>
                                    <details>
                                        <summary>
                                            <strong>Customer</strong>
                                        </summary>
                                        <div className="card-body">
                                            <Form>
                                                <Form.Group controlId="formcustomerid">
                                                    <Form.Label>
                                                        <strong>Customer ID</strong>
                                                    </Form.Label>
                                                    <Form.Control type="text" defaultValue="" />
                                                </Form.Group>
                                                <br />
                                                <Form.Group controlId="formlastname">
                                                    <Form.Label>
                                                        <strong>Last name</strong>
                                                    </Form.Label>
                                                    <Form.Control type="text" defaultValue="" />
                                                </Form.Group>
                                                <br />
                                                <Form.Group controlId="formfistname">
                                                    <Form.Label>
                                                        <strong>First name</strong>
                                                    </Form.Label>
                                                    <Form.Control type="text" defaultValue="" />
                                                </Form.Group>
                                                <br />
                                                <Form.Group controlId="formaddress">
                                                    <Form.Label>
                                                        <strong>Address</strong>
                                                    </Form.Label>
                                                    <Form.Control type="text" defaultValue="" />
                                                </Form.Group>
                                                <br />
                                                <Form.Group controlId="formpostalcode">
                                                    <Form.Label>
                                                        <strong>Postal code</strong>
                                                    </Form.Label>
                                                    <Form.Control type="text" defaultValue="" />
                                                </Form.Group>
                                                <br />
                                                <Form.Group controlId="formhousenumber">
                                                    <Form.Label>
                                                        <strong>House number</strong>
                                                    </Form.Label>
                                                    <Form.Control type="text" defaultValue="" />
                                                </Form.Group>
                                                <br />
                                                <Form.Group controlId="formcity">
                                                    <Form.Label>
                                                        <strong>City</strong>
                                                    </Form.Label>
                                                    <Form.Control type="text" defaultValue="" />
                                                </Form.Group>
                                                <br />
                                                <Form.Group controlId="formtelephonenumber">
                                                    <Form.Label>
                                                        <strong>Telephone number</strong>
                                                    </Form.Label>
                                                    <Form.Control type="text" defaultValue="" />
                                                </Form.Group>
                                                <br />
                                                <Form.Group controlId="formcompany">
                                                    <Form.Label>
                                                        <strong>Company</strong>
                                                    </Form.Label>
                                                    <Form.Control type="text" defaultValue="" />
                                                </Form.Group>
                                                <br />
                                                <Form.Group controlId="formcountry">
                                                    <Form.Label>
                                                        <strong>Country</strong>
                                                    </Form.Label>
                                                    <div className="dropdown-with-caret">
                                                        <Form.Control as="select">
                                                            <option>All</option>
                                                        </Form.Control>
                                                        <span className="caret" />
                                                    </div>
                                                </Form.Group>
                                                <br />
                                                <Form.Group controlId="formlicenseplateno.">
                                                    <Form.Label>
                                                        <strong>License plate number</strong>
                                                    </Form.Label>
                                                    <Form.Control type="text" defaultValue="" />
                                                </Form.Group>
                                                <br />
                                                <Form.Group controlId="formlicenseplatedetail">
                                                    <Form.Label>
                                                        <strong>License plate details</strong>
                                                    </Form.Label>
                                                    <div className="dropdown-with-caret">
                                                        <Form.Control as="select">
                                                            <option>None</option>
                                                        </Form.Control>
                                                        <span className="caret" />
                                                    </div>
                                                </Form.Group>
                                                <br />
                                                <Form.Group controlId="formlastnametralparty">
                                                    <Form.Label>
                                                        <strong>Last name travel party</strong>
                                                    </Form.Label>
                                                    <Form.Control type="text" defaultValue="" />
                                                </Form.Group>
                                                <br />
                                                <Form.Group controlId="formtravelpartydetails">
                                                    <Form.Label>
                                                        <strong>Travel party details</strong>
                                                    </Form.Label>
                                                    <div className="dropdown-with-caret">
                                                        <Form.Control as="select">
                                                            <option>None</option>
                                                        </Form.Control>
                                                        <span className="caret" />
                                                    </div>
                                                </Form.Group>
                                                <br />
                                                <Form.Group controlId="formsource">
                                                    <Form.Label>
                                                        <strong>Source</strong>
                                                    </Form.Label>
                                                    <div className="dropdown-with-caret">
                                                        <Form.Control as="select">
                                                            <option>All</option>
                                                        </Form.Control>
                                                        <span className="caret" />
                                                    </div>
                                                </Form.Group>
                                                <br />
                                                <Form.Group controlId="formibannumber">
                                                    <Form.Label>
                                                        <strong>IBAN number</strong>
                                                    </Form.Label>
                                                    <Form.Control type="text" defaultValue="" />
                                                </Form.Group>
                                                <br />
                                            </Form>
                                        </div>
                                    </details>
                                    <hr />
                                    <details>
                                        <summary>
                                            <strong>Reservation</strong>
                                        </summary>
                                        <div className="card-body">
                                            <Form>
                                                <Form.Group controlId="formreservationid">
                                                    <Form.Label>
                                                        <strong>Reservation ID(s)</strong>
                                                        <i className="fa-solid fa-circle-question" />
                                                    </Form.Label>
                                                    <Form.Control type="text" defaultValue="E.g. 123456,654321" />
                                                </Form.Group>
                                                <br />
                                                <Form.Group controlId="formreservationno.">
                                                    <Form.Label>
                                                        <strong>Reservation number(s)</strong>
                                                        <i className="fa-solid fa-circle-question" />
                                                    </Form.Label>
                                                    <Form.Control type="text" defaultValue="E.g. 002400200, 002400200" />
                                                </Form.Group>
                                                <br />
                                                <Form.Group controlId="formreservationNo.from-to">
                                                    <Form.Label>
                                                        <strong>Reservation number from-to</strong>
                                                    </Form.Label>
                                                    <Form.Control type="text" defaultValue="" />
                                                </Form.Group>
                                                <br />
                                                <Form.Group controlId="formtypesofreservation">
                                                    <Form.Label>
                                                        <strong>Type of reservation</strong>
                                                    </Form.Label>
                                                    <div className="dropdown-with-caret">
                                                        <Form.Control as="select">
                                                            <option>All</option>
                                                            <option>Changed</option>
                                                            <option>Quotations</option>
                                                            <option>Completed</option>
                                                        </Form.Control>
                                                        <span className="caret" />
                                                    </div>
                                                </Form.Group>
                                                <br />
                                                <Form.Group controlId="formreseravtionstatus">
                                                    <Form.Label>
                                                        <strong>Reservation status</strong>
                                                    </Form.Label>
                                                    <div className="dropdown-with-caret">
                                                        <Form.Control as="select">
                                                            <option>All</option>
                                                            <option>Definitive</option>
                                                            <option>Checked In</option>
                                                            <option>Pre-Booking</option>
                                                            <option>Checked Out</option>
                                                            <option>Declined</option>
                                                            <option>Provisional</option>
                                                            <option>Quotation</option>
                                                            <option>Optional</option>
                                                        </Form.Control>
                                                        <span className="caret" />
                                                    </div>
                                                </Form.Group>
                                                <br />
                                                <Form.Group controlId="formaccomodationkind">
                                                    <Form.Label>
                                                        <strong>Accomodation Kind</strong>
                                                    </Form.Label>
                                                    <div className="dropdown-with-caret">
                                                        <Form.Control as="select">
                                                            <option>All</option>
                                                        </Form.Control>
                                                        <span className="caret" />
                                                    </div>
                                                </Form.Group>
                                                <br />
                                                <Form.Group controlId="formsupplier">
                                                    <Form.Label>
                                                        <strong>Supplier</strong>
                                                    </Form.Label>
                                                    <div className="dropdown-with-caret">
                                                        <Form.Control as="select">
                                                            <option>All</option>
                                                        </Form.Control>
                                                        <span className="caret" />
                                                    </div>
                                                </Form.Group>
                                                <br />
                                                <Form.Group controlId="formbrands">
                                                    <Form.Label>
                                                        <strong>Brands</strong>
                                                    </Form.Label>
                                                    <div className="dropdown-with-caret">
                                                        <Form.Control as="select">
                                                            <option>All</option>
                                                        </Form.Control>
                                                        <span className="caret" />
                                                    </div>
                                                </Form.Group>
                                                <br />
                                                <Form.Group controlId="formlocation">
                                                    <Form.Label>
                                                        <strong>Location</strong>
                                                    </Form.Label>
                                                    <div className="dropdown-with-caret">
                                                        <Form.Control as="select">
                                                            <option>All</option>
                                                            <option>Verina</option>
                                                            <option>Siena</option>
                                                            <option>Sorrento</option>
                                                            <option>Capri</option>
                                                            <option>Frankfrut</option>
                                                        </Form.Control>
                                                        <span className="caret" />
                                                    </div>
                                                </Form.Group>
                                                <br />
                                                <Form.Group controlId="formaccomodationtype">
                                                    <Form.Label>
                                                        <strong>Accomodation type</strong>
                                                    </Form.Label>
                                                    <div className="dropdown-with-caret">
                                                        <Form.Control as="select">
                                                            <option>All</option>
                                                            <option>Building A</option>
                                                            <option>Building B</option>
                                                            <option>Building C</option>
                                                            <option>Bulding D</option>
                                                            <option>Building E</option>
                                                        </Form.Control>
                                                        <span className="caret" />
                                                    </div>
                                                </Form.Group>
                                                <br />
                                                <Form.Group controlId="formoffers">
                                                    <Form.Label>
                                                        <strong>Offers</strong>
                                                    </Form.Label>
                                                    <div className="dropdown-with-caret">
                                                        <Form.Control as="select">
                                                            <option>All</option>
                                                        </Form.Control>
                                                        <span className="caret" />
                                                    </div>
                                                </Form.Group>
                                                <br />
                                                <Form.Group controlId="formadd-ons">
                                                    <Form.Label>
                                                        <strong>Add-ons</strong>
                                                    </Form.Label>
                                                    <div className="dropdown-with-caret">
                                                        <Form.Control as="select">
                                                            <option>All</option>
                                                            <option>BreakFast</option>
                                                            <option>Lunch</option>
                                                            <option>Dinner</option>
                                                        </Form.Control>
                                                        <span className="caret" />
                                                    </div>
                                                </Form.Group>
                                                <br />
                                                <Form.Group controlId="formunits">
                                                    <Form.Label>
                                                        <strong>Units</strong>
                                                    </Form.Label>
                                                    <div className="dropdown-with-caret">
                                                        <Form.Control as="select">
                                                            <option>All</option>
                                                            <option>Room A1</option>
                                                            <option>Room B2</option>
                                                            <option>Room C3</option>
                                                            <option>Room D4</option>
                                                            <option>Room E5</option>
                                                        </Form.Control>
                                                        <span className="caret" />
                                                    </div>
                                                </Form.Group>
                                                <br />
                                                <Form.Group controlId="formunitassigned">
                                                    <Form.Label>
                                                        <strong>Unit assigned</strong>
                                                    </Form.Label>
                                                    <div className="dropdown-with-caret">
                                                        <Form.Control as="select">
                                                            <option>All</option>
                                                        </Form.Control>
                                                        <span className="caret" />
                                                    </div>
                                                </Form.Group>
                                                <br />
                                                <Form.Group controlId="formdisributionchannel">
                                                    <Form.Label>
                                                        <strong>Distribution channel</strong>
                                                    </Form.Label>
                                                    <div className="dropdown-with-caret">
                                                        <Form.Control as="select">
                                                            <option>All</option>
                                                            <option>Booking.com</option>
                                                            <option>Ticket.com</option>
                                                            <option>Trip.com</option>
                                                            <option>Hotelbeds.com</option>
                                                            <option>Traveloka.com</option>
                                                            <option>go.mmt.com</option>
                                                        </Form.Control>
                                                        <span className="caret" />
                                                    </div>
                                                </Form.Group>
                                                <br />
                                                <Form.Group controlId="formexcludedDC">
                                                    <Form.Label>
                                                        <strong>Excluded distribution channel</strong>
                                                    </Form.Label>
                                                    <div className="dropdown-with-caret">
                                                        <Form.Control as="select">
                                                            <option>None</option>
                                                        </Form.Control>
                                                        <span className="caret" />
                                                    </div>
                                                </Form.Group>
                                                <br />
                                                <Form.Group controlId="formbookrestrictiontype">
                                                    <Form.Label>
                                                        <strong>Book restriction type</strong>
                                                    </Form.Label>
                                                    <div className="dropdown-with-caret">
                                                        <Form.Control as="select">
                                                            <option>All</option>
                                                        </Form.Control>
                                                        <span className="caret" />
                                                    </div>
                                                </Form.Group>
                                                <br />
                                                <Form.Group controlId="formreservationcategory">
                                                    <Form.Label>
                                                        <strong>Reservation category</strong>
                                                    </Form.Label>
                                                    <div className="dropdown-with-caret">
                                                        <Form.Control as="select">
                                                            <option>All</option>
                                                            <option>Regular</option>
                                                            <option>Pre Booking</option>
                                                            <option>Tool Operator</option>
                                                        </Form.Control>
                                                        <span className="caret" />
                                                    </div>
                                                </Form.Group>
                                                <br />
                                                <Form.Group controlId="formemployee">
                                                    <Form.Label>
                                                        <strong>Employee</strong>
                                                    </Form.Label>
                                                    <div className="dropdown-with-caret">
                                                        <Form.Control as="select">
                                                            <option>All</option>
                                                            <option>John</option>
                                                            <option>Thomas</option>
                                                            <option>Maria</option>
                                                            <option>Jerry</option>
                                                            <option>Larry</option>
                                                        </Form.Control>
                                                        <span className="caret" />
                                                    </div>
                                                </Form.Group>
                                                <br />
                                                <Form.Group controlId="formsubjects">
                                                    <Form.Label>
                                                        <strong>Subjects</strong>
                                                    </Form.Label>
                                                    <div className="dropdown-with-caret">
                                                        <Form.Control as="select">
                                                            <option>All</option>
                                                        </Form.Control>
                                                        <span className="caret" />
                                                    </div>
                                                </Form.Group>
                                                <div className="card-body">
                                                    <Form.Group controlId="formCheckbox">
                                                        <strong>
                                                            <Form.Check type="checkbox" label="Upgraded accomodation type" />
                                                        </strong>
                                                    </Form.Group>
                                                </div>
                                            </Form>
                                        </div>
                                    </details>
                                    <hr />
                                    <details>
                                        <summary>
                                            <strong>Dates</strong>
                                        </summary>
                                        <div className="card-body">
                                            <Form>
                                                <label>
                                                    <strong>Arrival date from - till</strong>
                                                </label>
                                                <div className="d-flex align-items-center">
                                                    <input type="date" className="form-control-sm" />
                                                    <i className="fa-solid fa-arrow-right mx-2" />
                                                    <input type="date" className="form-control-sm" />
                                                </div>
                                            </Form>
                                            <br />
                                            <Form>
                                                <label>
                                                    <strong>Departure date from - till</strong>
                                                </label>
                                                <div className="d-flex align-items-center">
                                                    <input type="date" className="form-control-sm" />
                                                    <i className="fa-solid fa-arrow-right mx-2" />
                                                    <input type="date" className="form-control-sm" />
                                                </div>
                                            </Form>
                                            <br />
                                            <Form>
                                                <label>
                                                    <strong>Stay date from - till</strong>
                                                </label>
                                                <div className="d-flex align-items-center">
                                                    <input type="date" className="form-control-sm" />
                                                    <i className="fa-solid fa-arrow-right mx-2" />
                                                    <input type="date" className="form-control-sm" />
                                                </div>
                                            </Form>
                                            <br />
                                            <Form>
                                                <label>
                                                    <strong>Reservation date from - till</strong>
                                                </label>
                                                <div className="d-flex align-items-center">
                                                    <input type="date" className="form-control-sm" />
                                                    <i className="fa-solid fa-arrow-right mx-2" />
                                                    <input type="date" className="form-control-sm" />
                                                </div>
                                            </Form>
                                            <br />
                                            <Form>
                                                <label>
                                                    <strong>Expire date from - till</strong>
                                                </label>
                                                <div className="d-flex align-items-center">
                                                    <input type="date" className="form-control-sm" />
                                                    <i className="fa-solid fa-arrow-right mx-2" />
                                                    <input type="date" className="form-control-sm" />
                                                </div>
                                            </Form>
                                            <br />
                                            <Form>
                                                <label>
                                                    <strong>Cancellation date from - till</strong>
                                                </label>
                                                <div className="d-flex align-items-center">
                                                    <input type="date" className="form-control-sm" />
                                                    <i className="fa-solid fa-arrow-right mx-2" />
                                                    <input type="date" className="form-control-sm" />
                                                </div>
                                            </Form>
                                            <br />
                                        </div>
                                    </details>
                                    <hr />
                                    <details>
                                        <summary>
                                            <strong>References</strong>
                                        </summary>
                                        <div className="card-body">
                                            <Form.Group controlId="formbookingreference">
                                                <Form.Label>
                                                    <strong>Booking reference</strong>
                                                </Form.Label>
                                                <Form.Control type="text" defaultValue="" />
                                            </Form.Group>
                                            <br />
                                            <Form.Group controlId="formsupplierbookingreference">
                                                <Form.Label>
                                                    <strong>Supplier booking reference</strong>
                                                </Form.Label>
                                                <Form.Control type="text" defaultValue="" />
                                            </Form.Group>
                                            <br />
                                            <Form.Group controlId="formvoucher">
                                                <Form.Label>
                                                    <strong>Voucher</strong>
                                                </Form.Label>
                                                <Form.Control type="text" defaultValue="" />
                                            </Form.Group>
                                        </div>
                                    </details>
                                    <hr />
                                    <details>
                                        <summary>
                                            <strong>Validation</strong>
                                        </summary>
                                        <div className="card-body">
                                            <Form.Group controlId="formreservationvalidatestatus">
                                                <Form.Label>
                                                    <strong>Reservation validation status</strong>
                                                </Form.Label>
                                                <div className="dropdown-with-caret">
                                                    <Form.Control as="select">
                                                        <option>All</option>
                                                    </Form.Control>
                                                    <span className="caret" />
                                                </div>
                                            </Form.Group>
                                            <br />
                                            <Form.Group controlId="formarrivalinfostatus">
                                                <Form.Label>
                                                    <strong>Arrival information status</strong>
                                                </Form.Label>
                                                <div className="dropdown-with-caret">
                                                    <Form.Control as="select">
                                                        <option>All</option>
                                                    </Form.Control>
                                                    <span className="caret" />
                                                </div>
                                            </Form.Group>
                                        </div>
                                    </details>
                                    <hr />
                                    <details>
                                        <summary>
                                            <strong>Financial</strong>
                                        </summary>
                                        <div className="card-body">
                                            <Form.Group controlId="formpositivedueamount">
                                                <Form.Label>
                                                    <strong>Positive due amount</strong>
                                                </Form.Label>
                                                <div className="dropdown-with-caret">
                                                    <Form.Control as="select">
                                                        <option>All</option>
                                                    </Form.Control>
                                                    <span className="caret" />
                                                </div>
                                            </Form.Group>
                                            <br />
                                            <Form.Group controlId="formzerodueamount">
                                                <Form.Label>
                                                    <strong>Zero due amount</strong>
                                                </Form.Label>
                                                <div className="dropdown-with-caret">
                                                    <Form.Control as="select">
                                                        <option>All</option>
                                                    </Form.Control>
                                                    <span className="caret" />
                                                </div>
                                            </Form.Group>
                                            <br />
                                            <Form.Group controlId="formnegativedueamount">
                                                <Form.Label>
                                                    <strong>Negative due amount</strong>
                                                </Form.Label>
                                                <div className="dropdown-with-caret">
                                                    <Form.Control as="select">
                                                        <option>All</option>
                                                    </Form.Control>
                                                    <span className="caret" />
                                                </div>
                                            </Form.Group>
                                            <br />
                                            <Form.Group controlId="formpaymenttermsets">
                                                <Form.Label>
                                                    <strong>Payment term sets</strong>
                                                </Form.Label>
                                                <div className="dropdown-with-caret">
                                                    <Form.Control as="select">
                                                        <option>All</option>
                                                    </Form.Control>
                                                    <span className="caret" />
                                                </div>
                                            </Form.Group>
                                            <div className="card-body">
                                                <Form.Group controlId="formCheckbox">
                                                    <strong>
                                                        <Form.Check type="checkbox" label="Price has been over hidden" />
                                                    </strong>
                                                </Form.Group>
                                            </div>
                                        </div>
                                    </details>
                                    <hr />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Right Section for the Table */}
                <div className="col-md-8">
                    <div className="card shadow">
                        <div className="card-body">
                            {/* Table with horizontal scroll */}
                            <div style={{ height: '550px', overflowX: 'auto', overflowY: 'auto', maxWidth: '100%' }}>
                                <table
                                    className="employee-table"
                                    style={{
                                        tableLayout: 'fixed',
                                        minWidth: '2500px', // Ensure horizontal scroll
                                    }}
                                >
                                    <thead>
                                        <tr>
                                            <th>Status</th>
                                            <th>
                                                Res nr. <i className="fas fa-sort ml-2" />
                                            </th>
                                            <th>
                                                Name <i className="fas fa-sort ml-2" />
                                            </th>
                                            <th>
                                                Location <i className="fas fa-sort ml-2" />
                                            </th>
                                            <th>
                                                Accomodation types <i className="fas fa-sort ml-2" />
                                            </th>
                                            <th>
                                                Unit name <i className="fas fa-sort ml-2" />
                                            </th>
                                            <th>
                                                Arrival-departure <i className="fas fa-sort ml-2" />
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <span className="badge bg-primary">Definitive</span>
                                            </td>
                                            <td>000025001</td>
                                            <td>John</td>
                                            <td>Verina</td>
                                            <td>Building A</td>
                                            <td>Room A1</td>
                                            <td>Fri 12 Dec 2025 - Mon 15 Dec 2025,</td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <span className="badge bg-success">Checked In</span>
                                            </td>
                                            <td>000026002</td>
                                            <td>Thomas</td>
                                            <td>Siena</td>
                                            <td>Building B</td>
                                            <td>Room B2</td>
                                            <td>Sat 12 Dec 2025 - Sund 15 Dec 2025,</td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <span className="badge bg-warning text-black">Pre-Booking</span>
                                            </td>
                                            <td>000027003</td>
                                            <td>Maria</td>
                                            <td>Sorrento</td>
                                            <td>Building C</td>
                                            <td>Room C3</td>
                                            <td>Mon 10 Feb 2025 - Sat 6 Mar 2025,</td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <span className="badge bg-light text-black">Checked Out</span>
                                            </td>
                                            <td>000028004</td>
                                            <td>Jerry</td>
                                            <td>Capri</td>
                                            <td>Building D</td>
                                            <td>Room D4</td>
                                            <td>Wed 2 Mar 2025 - Mon 15 Jun 2025,</td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <span className="badge bg-danger">Declined</span>
                                            </td>
                                            <td>000029005</td>
                                            <td>Larry</td>
                                            <td>frankfrut</td>
                                            <td>Building E</td>
                                            <td>Room E5</td>
                                            <td>Thurs 31 Nov 2025 - Fri 15 Sept 2025,</td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <span
                                                    className="badge-purple"
                                                    style={{
                                                        backgroundColor: ' #FF7518',
                                                        borderRadius: '6px',
                                                        padding: '3px 7px',
                                                        fontSize: '12px',
                                                        fontWeight: 'bold',
                                                        verticalAlign: 'middle',
                                                        textAlign: 'center',
                                                        color: '#000',
                                                    }}
                                                >
                                                    Provisional
                                                </span>
                                            </td>
                                            <td>000031007</td>
                                            <td>Pedro</td>
                                            <td>Prawet</td>
                                            <td>Building G</td>
                                            <td>Room G7</td>
                                            <td>Mon 1 sept 2025 - Fri 15 Dec 2025,</td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <span className="badge bg-secondary">Quotation</span>
                                            </td>
                                            <td>000032008</td>
                                            <td>Andre</td>
                                            <td>Dublin</td>
                                            <td>Building H</td>
                                            <td>Room H8</td>
                                            <td>Fri 29 Nov 2025 - Mon 5 Sept 2025,</td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <span
                                                    className="badge-purple"
                                                    style={{
                                                        backgroundColor: ' #7B3CEC',
                                                        borderRadius: '6px',
                                                        padding: '3px 7px',
                                                        fontSize: '12px',
                                                        fontWeight: 'bold',
                                                        verticalAlign: 'middle',
                                                        textAlign: 'center',
                                                        color: '#fff',
                                                    }}
                                                >
                                                    Optional
                                                </span>
                                            </td>
                                            <td>000033009</td>
                                            <td>Samir</td>
                                            <td>Mumbai</td>
                                            <td>Building I</td>
                                            <td>Room I9</td>
                                            <td>Wed 3 Jun 2025 - Mon 25 Aug 2025,</td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <span className="badge bg-primary">Definitive</span>
                                            </td>
                                            <td>000025001</td>
                                            <td>John</td>
                                            <td>Verina</td>
                                            <td>Building A</td>
                                            <td>Room A1</td>
                                            <td>Fri 12 Dec 2025 - Mon 15 Dec 2025,</td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <span className="badge bg-success">Checked In</span>
                                            </td>
                                            <td>000026002</td>
                                            <td>Thomas</td>
                                            <td>Siena</td>
                                            <td>Building B</td>
                                            <td>Room B2</td>
                                            <td>Sat 12 Dec 2025 - Sund 15 Dec 2025,</td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <span className="badge bg-warning text-black">Pre-Booking</span>
                                            </td>
                                            <td>000027003</td>
                                            <td>Maria</td>
                                            <td>Sorrento</td>
                                            <td>Building C</td>
                                            <td>Room C3</td>
                                            <td>Mon 10 Feb 2025 - Sat 6 Mar 2025,</td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <span className="badge bg-primary">Definitive</span>
                                            </td>
                                            <td>000025001</td>
                                            <td>John</td>
                                            <td>Verina</td>
                                            <td>Building A</td>
                                            <td>Room A1</td>
                                            <td>Fri 12 Dec 2025 - Mon 15 Dec 2025,</td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <span className="badge bg-success">Checked In</span>
                                            </td>
                                            <td>000026002</td>
                                            <td>Thomas</td>
                                            <td>Siena</td>
                                            <td>Building B</td>
                                            <td>Room B2</td>
                                            <td>Sat 12 Dec 2025 - Sund 15 Dec 2025,</td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <span className="badge bg-warning text-black">Pre-Booking</span>
                                            </td>
                                            <td>000027003</td>
                                            <td>Maria</td>
                                            <td>Sorrento</td>
                                            <td>Building C</td>
                                            <td>Room C3</td>
                                            <td>Mon 10 Feb 2025 - Sat 6 Mar 2025,</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SearchReservation;
