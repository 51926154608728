import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap'; // Added Form for form controls
import { Link } from 'react-router-dom'; // Import Link for navigation
import { PageHeaderType } from '../../../../types/menu';
import PageHeader from '../../../common/Header/PageHeader';
import './Employee.css';
import CreateEmployeeModal from './CreateEmployeeModal';

export const Employee = () => {
    const [showModal, setShowModal] = useState(false);
    const [createEmployeeShowModal, setCreateEmployeeShowModal] = useState(false);

    // State for actual columns display
    const [columns, setColumns] = useState({
        firstName: true,
        lastName: true,
        code: true,
        employeeId: true,
    });

    // Temporary state for modal
    const [tempColumns, setTempColumns] = useState(columns);

    const handleCloseCreateEmployeeShowModal = () => {
        setCreateEmployeeShowModal(false);
    };

    const handleShowCreateEmployeeShowModal = () => {
        setCreateEmployeeShowModal(true);
    };

    const pageHeader: PageHeaderType = {
        title: 'Employee',
        prevNavLabel: 'Dashboard',
        prevNavLink: '/client/landing',
        iconClass: 'fa-user-tie',
        modal: {
            showModalHandler: handleShowCreateEmployeeShowModal,
        },
        createPageType: 'modal',
    };

    const handleConfigureColumnsClick = () => {
        setTempColumns(columns); // Sync tempColumns with current columns state
        setShowModal(true);
    };

    const handleClose = () => {
        setShowModal(false);
    };

    // Function to handle checkbox changes in modal
    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { id, checked } = event.target;
        setTempColumns((prevState) => ({
            ...prevState,
            [id]: checked,
        }));
    };

    // Function to save changes
    const handleSave = () => {
        setColumns(tempColumns);
        handleClose();
    };

    return (
        <div className="container-fluid">
            <PageHeader {...pageHeader} />
            <CreateEmployeeModal showModal={createEmployeeShowModal} handleClose={handleCloseCreateEmployeeShowModal} />
            <div className="main-container">
                <div className="configure-columns" onClick={handleConfigureColumnsClick}>
                    <i className="fas fa-cogs configure-icon" />
                    <span>Configure Columns</span>
                </div>
                <div className="employee-container">
                    <table className="employee-table">
                        <thead>
                            <tr>
                                {columns.firstName && (
                                    <th>
                                        First Name
                                        <i className="fas fa-sort ml-2" />
                                    </th>
                                )}
                                {columns.lastName && (
                                    <th>
                                        Last Name
                                        <i className="fas fa-sort ml-2" />
                                    </th>
                                )}
                                {columns.code && (
                                    <th>
                                        Code
                                        <i className="fas fa-sort ml-2" />
                                    </th>
                                )}
                                {columns.employeeId && (
                                    <th>
                                        Employee ID
                                        <i className="fas fa-sort ml-2" />
                                    </th>
                                )}
                            </tr>
                        </thead>
                        <tbody>
                            {/* Your table rows */}
                            <tr>
                                {columns.firstName && (
                                    <td>
                                        <Link to="/client/backoffice-manager/employee/employee-detail">John</Link>
                                    </td>
                                )}
                                {columns.lastName && (
                                    <td>
                                        <Link to="/client/backoffice-manager/employee/employee-detail">Coffey</Link>
                                    </td>
                                )}
                                {columns.code && (
                                    <td>
                                        <Link to="/client/backoffice-manager/employee/employee-detail">JD123</Link>
                                    </td>
                                )}
                                {columns.employeeId && (
                                    <td>
                                        <Link to="/client/backoffice-manager/employee/employee-detail">66017</Link>
                                    </td>
                                )}
                            </tr>
                            <tr>
                                {columns.firstName && (
                                    <td>
                                        <Link to="/client/backoffice-manager/employee/employee-detail">Thomas</Link>
                                    </td>
                                )}
                                {columns.lastName && (
                                    <td>
                                        <Link to="/client/backoffice-manager/employee/employee-detail">McCall</Link>
                                    </td>
                                )}
                                {columns.code && (
                                    <td>
                                        <Link to="/client/backoffice-manager/employee/employee-detail">TH123</Link>
                                    </td>
                                )}
                                {columns.employeeId && (
                                    <td>
                                        <Link to="/client/backoffice-manager/employee/employee-detail">67801</Link>
                                    </td>
                                )}
                            </tr>
                            <tr>
                                {columns.firstName && (
                                    <td>
                                        <Link to="/client/backoffice-manager/employee/employee-detail">Maria</Link>
                                    </td>
                                )}
                                {columns.lastName && (
                                    <td>
                                        <Link to="/client/backoffice-manager/employee/employee-detail">Noble</Link>
                                    </td>
                                )}
                                {columns.code && (
                                    <td>
                                        <Link to="/client/backoffice-manager/employee/employee-detail">M123</Link>
                                    </td>
                                )}
                                {columns.employeeId && (
                                    <td>
                                        <Link to="/client/backoffice-manager/employee/employee-detail">66989</Link>
                                    </td>
                                )}
                            </tr>
                            <tr>
                                {columns.firstName && (
                                    <td>
                                        <Link to="/client/backoffice-manager/employee/employee-detail">Jerry</Link>
                                    </td>
                                )}
                                {columns.lastName && (
                                    <td>
                                        <Link to="/client/backoffice-manager/employee/employee-detail">Novak</Link>
                                    </td>
                                )}
                                {columns.code && (
                                    <td>
                                        <Link to="/client/backoffice-manager/employee/employee-detail">JJ123</Link>
                                    </td>
                                )}
                                {columns.employeeId && (
                                    <td>
                                        <Link to="/client/backoffice-manager/employee/employee-detail">98642</Link>
                                    </td>
                                )}
                            </tr>
                            <tr>
                                {columns.firstName && (
                                    <td>
                                        <Link to="/client/backoffice-manager/employee/employee-detail">Larry</Link>
                                    </td>
                                )}
                                {columns.lastName && (
                                    <td>
                                        <Link to="/client/backoffice-manager/employee/employee-detail">Yang</Link>
                                    </td>
                                )}
                                {columns.code && (
                                    <td>
                                        <Link to="/client/backoffice-manager/employee/employee-detail">L123</Link>
                                    </td>
                                )}
                                {columns.employeeId && (
                                    <td>
                                        <Link to="/client/backoffice-manager/employee/employee-detail">68766</Link>
                                    </td>
                                )}
                            </tr>
                            <tr>
                                {columns.firstName && (
                                    <td>
                                        <Link to="/client/backoffice-manager/employee/employee-detail">Dennis</Link>
                                    </td>
                                )}
                                {columns.lastName && (
                                    <td>
                                        <Link to="/client/backoffice-manager/employee/employee-detail">Lester</Link>
                                    </td>
                                )}
                                {columns.code && (
                                    <td>
                                        <Link to="/client/backoffice-manager/employee/employee-detail">DD123</Link>
                                    </td>
                                )}
                                {columns.employeeId && (
                                    <td>
                                        <Link to="/client/backoffice-manager/employee/employee-detail">99863</Link>
                                    </td>
                                )}
                            </tr>
                            <tr>
                                {columns.firstName && (
                                    <td>
                                        <Link to="/client/backoffice-manager/employee/employee-detail">Ruby</Link>
                                    </td>
                                )}
                                {columns.lastName && (
                                    <td>
                                        <Link to="/client/backoffice-manager/employee/employee-detail">Walter</Link>
                                    </td>
                                )}
                                {columns.code && (
                                    <td>
                                        <Link to="/client/backoffice-manager/employee/employee-detail">R123</Link>
                                    </td>
                                )}
                                {columns.employeeId && (
                                    <td>
                                        <Link to="/client/backoffice-manager/employee/employee-detail">11922</Link>
                                    </td>
                                )}
                            </tr>
                            <tr>
                                {columns.firstName && (
                                    <td>
                                        <Link to="/client/backoffice-manager/employee/employee-detail">Angela</Link>
                                    </td>
                                )}
                                {columns.lastName && (
                                    <td>
                                        <Link to="/client/backoffice-manager/employee/employee-detail">Holmes</Link>
                                    </td>
                                )}
                                {columns.code && (
                                    <td>
                                        <Link to="/client/backoffice-manager/employee/employee-detail">AN123</Link>
                                    </td>
                                )}
                                {columns.employeeId && (
                                    <td>
                                        <Link to="/client/backoffice-manager/employee/employee-detail">10011</Link>
                                    </td>
                                )}
                            </tr>
                            <tr>
                                {columns.firstName && (
                                    <td>
                                        <Link to="/client/backoffice-manager/employee/employee-detail">Fances</Link>
                                    </td>
                                )}
                                {columns.lastName && (
                                    <td>
                                        <Link to="/client/backoffice-manager/employee/employee-detail">Hayden</Link>
                                    </td>
                                )}
                                {columns.code && (
                                    <td>
                                        <Link to="/client/backoffice-manager/employee/employee-detail">F123</Link>
                                    </td>
                                )}
                                {columns.employeeId && (
                                    <td>
                                        <Link to="/client/backoffice-manager/employee/employee-detail">78892</Link>
                                    </td>
                                )}
                            </tr>
                            <tr>
                                {columns.firstName && (
                                    <td>
                                        <Link to="/client/backoffice-manager/employee/employee-detail">Brandon</Link>
                                    </td>
                                )}
                                {columns.lastName && (
                                    <td>
                                        <Link to="/client/backoffice-manager/employee/employee-detail">Skinner</Link>
                                    </td>
                                )}
                                {columns.code && (
                                    <td>
                                        <Link to="/client/backoffice-manager/employee/employee-detail">BD123</Link>
                                    </td>
                                )}
                                {columns.employeeId && (
                                    <td>
                                        <Link to="/client/backoffice-manager/employee/employee-detail">11001</Link>
                                    </td>
                                )}
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <Modal show={showModal} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Configure Columns</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Check
                            type="checkbox"
                            id="firstName"
                            label="First Name"
                            checked={tempColumns.firstName}
                            onChange={handleCheckboxChange}
                        />
                        <Form.Check
                            type="checkbox"
                            id="lastName"
                            label="Last Name"
                            checked={tempColumns.lastName}
                            onChange={handleCheckboxChange}
                        />
                        <Form.Check
                            type="checkbox"
                            id="code"
                            label="Code"
                            checked={tempColumns.code}
                            onChange={handleCheckboxChange}
                        />
                        <Form.Check
                            type="checkbox"
                            id="employeeId"
                            label="Employee ID"
                            checked={tempColumns.employeeId}
                            onChange={handleCheckboxChange}
                        />
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleSave}>
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default Employee;
