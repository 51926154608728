import { request } from '../api/';
import { LoggedInUser, UserDetails } from '../types/user';

export const getLoggedInUserDetails = async () => {
    const requestOptions = {
        method: 'GET',
        url: `${process.env.REACT_APP_API_ENDPOINT}/user/logged-in-user`,
    };

    return request<{ data: LoggedInUser }>(requestOptions);
};

export const getTemporaryUserDetails = async () => {
    const requestOptions = {
        method: 'GET',
        url: `${process.env.REACT_APP_API_ENDPOINT}/user/state-less`,
    };

    return request<{ data: LoggedInUser }>(requestOptions);
};

export const createClientBOUser = async (clientProfile: Partial<UserDetails>) => {
    const requestOptions = {
        method: 'POST',
        url: `${process.env.REACT_APP_API_ENDPOINT}/client/user/create`,
        data: { ...clientProfile },
    };
    return request<{ message: string }>(requestOptions);
};
