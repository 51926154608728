import React from 'react';
import { Navbar } from 'react-bootstrap';
//import { useSelector } from '../../../store';
//import { isClientFlowActive, makeSelectClientName } from '../../../selectors/clientSelector';
import AlertsDropdown from './AlertsDropdown';
import UserInfoDropDown from './UserInfoDropDown';

const Header = () => {
    return (
        <nav className="header-navbar navbar navbar-expand navbar-light topbar mb-3 static-top ">
            {/* Sidebar Toggle (Topbar) */}
            <button id="sidebarToggleTop" className="btn btn-link d-md-none rounded-circle mr-3">
                <i className="fa fa-bars" />
            </button>
            {/* Topbar Navbar */}
            <Navbar className="navbar-nav ml-auto">
                <AlertsDropdown />
                <div className="topbar-divider d-none d-sm-block" />
                <UserInfoDropDown />
            </Navbar>
        </nav>
    );
};

export default Header;
