import { request } from '../api/';
import { UserCreds } from '../types/user';

export const loginUser = async (creds: UserCreds) => {
    const requestOptions = {
        method: 'POST',
        url: `${process.env.REACT_APP_API_ENDPOINT}/auth/login`,
        skipAuth: true,
        showError: false,
        data: { ...creds },
    };

    return request(requestOptions);
};

export const logoutUser = async () => {
    const requestOptions = {
        method: 'GET',
        url: `${process.env.REACT_APP_API_ENDPOINT}/auth/logout`,
    };
    return request(requestOptions);
};

export const validateRegisterationLink = async (key: string, sec: string) => {
    const requestOptions = {
        method: 'POST',
        url: `${process.env.REACT_APP_API_ENDPOINT}/non-auth/validate-registeration-link`,
        skipAuth: true,
        data: { key, sec },
    };

    return request<{ isValid: boolean; name: string }>(requestOptions);
};

export const extendSession = async () => {
    const requestOptions = {
        method: 'GET',
        url: `${process.env.REACT_APP_API_ENDPOINT}/auth/user/extend-session`,
    };
    return request(requestOptions);
};
