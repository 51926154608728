import React, { useState } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import cn from 'classnames';
import { PageHeaderType } from '../../../types/menu';
import PageHeader from '../../common/Header/PageHeader';
import { AlphaNumericRegex, PasswordRegex, PhoneRegex } from '../../../helpers/form/validations';
import { RequiredField } from '../../common/Light/MarkUps';
import { useNotificationContext } from '../../../hooks/useNotificationContext';
import { makeSelectOfficialEmail } from '../../../selectors/userSelector';
import { useSelector } from '../../../store';
import { createClientBOUser } from '../../../services/user';
import { Gender } from '../../../types/app';
import { useAuth } from '../../../hooks/useAuth';

const validationSchema = Yup.object().shape({
    firstName: Yup.string()
        .matches(AlphaNumericRegex, 'Only alphabet, space and number allowed.')
        .required('First name is required'),
    lastName: Yup.string()
        .matches(AlphaNumericRegex, 'Only alphabet, space and number allowed.')
        .required('Last name is required'),
    dateOfBirth: Yup.date().required('Date of birth is required'),
    gender: Yup.string().required('Gender is required'),
    contactNumber: Yup.string().matches(PhoneRegex, 'Invalid phone number format').required('Contact number is required'),
    password: Yup.string()
        .matches(
            PasswordRegex,
            'Password must be at least 8 characters long, contain one uppercase letter, one lowercase letter, one number, and one special character.'
        )
        .required('Password is required'),
    confirmPassword: Yup.string()
        .required('Confirm password is required')
        .oneOf([Yup.ref('password')], 'Passwords must match'),
});

const CreateClientUserProfile = () => {
    const { onLogin } = useAuth();
    const { triggerSuccess } = useNotificationContext();

    const officialEmail = useSelector(makeSelectOfficialEmail);

    const pageHeader: PageHeaderType = {
        title: 'Create User Profile',
        iconClass: 'fa-user-tie',
    };

    const formik = useFormik({
        initialValues: {
            password: '',
            confirmPassword: '',
            firstName: '',
            lastName: '',
            dateOfBirth: '',
            gender: '',
            contactNumber: '',
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            const userData = {
                email: officialEmail,
                password: values.password,
                firstName: values.firstName,
                lastName: values.lastName,
                dateOfBirth: values.dateOfBirth,
                gender: values.gender as Gender,
                contactNumber: values.contactNumber,
            };
            // eslint-disable-next-line no-console
            const res = await createClientBOUser(userData);
            if (res.message) {
                triggerSuccess(res.message);
                onLogin({ username: officialEmail, password: values.password });
            }
        },
    });
    const [activeTab, setActiveTab] = useState('account-information');
    return (
        <div className="container-fluid">
            <PageHeader {...pageHeader} />
            <div className="create-user-form">
                <div className="container-xl px-4 mt-4">
                    <div className="col-12">
                        <div className="card mb-4">
                            <div className="container-breadcrumbs">
                                <nav className="breadcrumbs">
                                    <span
                                        className={cn('breadcrumbs__item', {
                                            'is-active': activeTab === 'account-information',
                                        })}
                                    >
                                        Account Information
                                    </span>
                                    <span
                                        className={cn('breadcrumbs__item', {
                                            'is-active': activeTab === 'general-information',
                                        })}
                                    >
                                        General Information
                                    </span>
                                </nav>
                            </div>
                            <div className="card-body">
                                <p>
                                    Fields marked with <i className="fa fa-xs fa-asterisk text-danger" /> are required.
                                </p>
                                <form onSubmit={formik.handleSubmit}>
                                    <div
                                        className={cn('account-information', {
                                            'd-none': activeTab !== 'account-information',
                                        })}
                                    >
                                        <div className="mb-3">
                                            <div className="row text-gray-700 p-3 bg-light">
                                                <div className="col-auto align-self-center">
                                                    <i className="fa fa-circle-info fa-xl" />
                                                </div>
                                                <div className="col">
                                                    This account will be exclusively yours, granting you full permission
                                                    rights to manage your company profile. With this account, you can invite
                                                    other users, oversee data, manage bookings, and do much more.
                                                </div>
                                            </div>
                                        </div>

                                        <div className="mb-3">
                                            <label className="small mb-2 ps-1 text-gray-700" htmlFor="inputOfficialEmail">
                                                Your Email:
                                            </label>
                                            <span className="fw-bold mx-2">{officialEmail}</span>
                                        </div>
                                        <div className="mb-3">
                                            <label className="small mb-2 ps-1 text-gray-700" htmlFor="inputPassword">
                                                New Password <RequiredField />
                                            </label>
                                            <input
                                                className="form-control"
                                                id="inputPassword"
                                                type="password"
                                                name="password"
                                                placeholder="Enter password"
                                                value={formik.values.password}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                            <div className="micro-text ms-1">
                                                Should be 8 characters long, including an uppercase letter, a lowercase
                                                letter, a number, and a special character.
                                            </div>
                                            {formik.touched.password && formik.errors.password ? (
                                                <div className="text-danger">{formik.errors.password}</div>
                                            ) : null}
                                        </div>
                                        <div className="mb-3">
                                            <label className="small mb-2 ps-1 text-gray-700" htmlFor="inputConfirmPassword">
                                                Confirm Password <RequiredField />
                                            </label>
                                            <input
                                                className="form-control"
                                                id="inputConfirmPassword"
                                                type="password"
                                                name="confirmPassword"
                                                placeholder="Enter password again"
                                                value={formik.values.confirmPassword}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                            {formik.touched.confirmPassword && formik.errors.confirmPassword ? (
                                                <div className="text-danger">{formik.errors.confirmPassword}</div>
                                            ) : null}
                                        </div>
                                        <div className="row gx-3 mb-3 text-end">
                                            <div className="col-12">
                                                <button
                                                    className="btn btn-primary px-4"
                                                    type="button"
                                                    disabled={formik.isSubmitting || formik.isValidating}
                                                    onClick={() => setActiveTab('general-information')}
                                                >
                                                    Next
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className={cn('general-information', {
                                            'd-none': activeTab !== 'general-information',
                                        })}
                                    >
                                        <div className="mb-3">
                                            <label className="small mb-2 ps-1 text-gray-700" htmlFor="inputFirstName">
                                                First Name <RequiredField />
                                            </label>
                                            <input
                                                className="form-control"
                                                id="inputFirstName"
                                                type="text"
                                                name="firstName"
                                                placeholder="Enter first name"
                                                value={formik.values.firstName}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                            {formik.touched.firstName && formik.errors.firstName ? (
                                                <div className="text-danger">{formik.errors.firstName}</div>
                                            ) : null}
                                        </div>
                                        <div className="mb-3">
                                            <label className="small mb-2 ps-1 text-gray-700" htmlFor="inputLastName">
                                                Last Name <RequiredField />
                                            </label>
                                            <input
                                                className="form-control"
                                                id="inputLastName"
                                                type="text"
                                                name="lastName"
                                                placeholder="Enter last name"
                                                value={formik.values.lastName}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                            {formik.touched.lastName && formik.errors.lastName ? (
                                                <div className="text-danger">{formik.errors.lastName}</div>
                                            ) : null}
                                        </div>
                                        <div className="mb-3">
                                            <label className="small mb-2 ps-1 text-gray-700" htmlFor="inputDateOfBirth">
                                                Date of Birth <RequiredField />
                                            </label>
                                            <input
                                                className="form-control"
                                                id="inputDateOfBirth"
                                                type="date"
                                                name="dateOfBirth"
                                                placeholder="Enter date of birth"
                                                value={formik.values.dateOfBirth}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                            {formik.touched.dateOfBirth && formik.errors.dateOfBirth ? (
                                                <div className="text-danger">{formik.errors.dateOfBirth}</div>
                                            ) : null}
                                        </div>
                                        <div className="mb-3">
                                            <label className="small mb-2 ps-1 text-gray-700" htmlFor="inputGender">
                                                Gender <RequiredField />
                                            </label>
                                            <select
                                                className="form-select"
                                                id="inputGender"
                                                name="gender"
                                                value={formik.values.gender}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            >
                                                <option value="">Select gender:</option>
                                                <option value="Male">Male</option>
                                                <option value="Female">Female</option>
                                                <option value="Other">Other</option>
                                            </select>
                                            {formik.touched.gender && formik.errors.gender ? (
                                                <div className="text-danger">{formik.errors.gender}</div>
                                            ) : null}
                                        </div>
                                        <div className="mb-3">
                                            <label className="small mb-2 ps-1 text-gray-700" htmlFor="inputContactNumber">
                                                Contact Number <RequiredField />
                                            </label>
                                            <input
                                                className="form-control"
                                                id="inputContactNumber"
                                                type="text"
                                                name="contactNumber"
                                                placeholder="Enter contact number (+91 9890911111)"
                                                value={formik.values.contactNumber}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                            {formik.touched.contactNumber && formik.errors.contactNumber ? (
                                                <div className="text-danger">{formik.errors.contactNumber}</div>
                                            ) : null}
                                        </div>
                                        <div className="row gx-3 mb-3 text-end">
                                            <div className="col-12">
                                                <button
                                                    className="btn btn-primary px-4 me-1"
                                                    type="button"
                                                    disabled={formik.isSubmitting || formik.isValidating}
                                                    onClick={() => setActiveTab('account-information')}
                                                >
                                                    Previous
                                                </button>
                                                <button
                                                    className="btn btn-primary ms-1"
                                                    type="submit"
                                                    disabled={formik.isSubmitting || formik.isValidating}
                                                >
                                                    Create My Profile
                                                    {(formik.isSubmitting || formik.isValidating) && (
                                                        <i className="fa-solid fa-circle-notch fa-spin ms-2" />
                                                    )}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CreateClientUserProfile;
