import React, { useRef, useState } from 'react';
import { Badge, Button, Col, Container, Dropdown, Form, Modal, Row } from 'react-bootstrap';

const RatetypeDetail: React.FC = () => {
    const cardARef = useRef<HTMLDivElement>(null);
    const cardBRef = useRef<HTMLDivElement>(null);
    const cardCRef = useRef<HTMLDivElement>(null);
    const cardDRef = useRef<HTMLDivElement>(null);

    const scrollToCard = (ref: React.RefObject<HTMLDivElement>) => {
        if (ref.current) {
            const topOffset = ref.current.getBoundingClientRect().top + window.pageYOffset - 140; // Adjust '100' as needed for header height or padding
            window.scrollTo({
                top: topOffset,
                behavior: 'smooth',
            });
        }
    };

    //Modal function to edit rentability rule//
    const [showEditModal, setShowEditModal] = useState(false);

    const handleEditClose = () => {
        setShowEditModal(false);
    };

    const handleEditShow = () => {
        setShowEditModal(true);
    };

    const handleEditSave = () => {
        handleEditClose();
    };

    // Modal function to create Renatability rule//
    const [showModal, setShowModal] = useState(false);

    const handleClose = () => {
        setShowModal(false);
    };

    const handleShow = () => {
        setShowModal(true);
    };

    const handleSave = () => {
        handleClose();
    };

    //Function for location selection
    const [showDropdown, setShowDropdown] = useState(false);
    const [selectedLocations, setSelectedLocations] = useState<string[]>([]);
    const locations = ['verina', 'Siena', 'Sorrento', 'Capri', 'Frankfrut'];

    const handleLocationCheckboxChange = (location: string) => {
        setSelectedLocations(
            (prevSelected) =>
                prevSelected.includes(location)
                    ? prevSelected.filter((loc) => loc !== location) // Remove if already selected
                    : [...prevSelected, location] // Add if not selected
        );
    };

    const handleSelectAllLocations = () => {
        setSelectedLocations(locations); // Select all locations
    };

    const handleDeselectAllLocations = () => {
        setSelectedLocations([]); // Clear all selected locations
    };

    const displaylocation = selectedLocations.length > 0 ? selectedLocations.join(', ') : 'All';
    // Check if all locations are selected if selected then deselect options will hover
    const allLocationsSelected = selectedLocations.length === locations.length;

    // Function for arrival and departure days
    const [selectedArrivalDays, setSelectedArrivalDays] = useState<string[]>([]);
    const [selectedDepartureDays, setSelectedDepartureDays] = useState<string[]>([]);

    const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

    const handleArrivalCheckboxChange = (day: string) => {
        setSelectedArrivalDays(
            (prevSelected) =>
                prevSelected.includes(day)
                    ? prevSelected.filter((d) => d !== day) // Remove if already selected
                    : [...prevSelected, day] // Add if not selected
        );
    };

    const handleDepartureCheckboxChange = (day: string) => {
        setSelectedDepartureDays(
            (prevSelected) =>
                prevSelected.includes(day)
                    ? prevSelected.filter((d) => d !== day) // Remove if already selected
                    : [...prevSelected, day] // Add if not selected
        );
    };

    // Function to handle "Select All" for arrival days
    const handleSelectAllArrivalDays = () => {
        setSelectedArrivalDays(days); // Clear all selected arrival days
    };

    // Function to handle "Select All" for departure days
    const handleSelectAllDepartureDays = () => {
        setSelectedDepartureDays(days); // Clear all selected departure days
    };

    // Function to handle "Deselect All" for arrival days
    const handleDeselectAllArrivalDays = () => {
        setSelectedArrivalDays([]); // Clear all selected arrival days
    };

    // Function to handle "Deselect All" for departure days
    const handleDeselectAllDepartureDays = () => {
        setSelectedDepartureDays([]); // Clear all selected departure days
    };

    // Create a display value for the dropdown based on selected days
    const displayValue = selectedArrivalDays.length > 0 ? selectedArrivalDays.join(', ') : 'Select Arrival Days';
    const departureDisplayValue =
        selectedDepartureDays.length > 0 ? selectedDepartureDays.join(', ') : 'Select Departure Days';

    // Modal function to link representation
    const [showLinkRepresentationModal, setShowLinkRepresentationModal] = useState(false);
    const [isCheckboxSelected, setIsCheckboxSelected] = useState(false);
    const [step, setStep] = useState(1);

    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsCheckboxSelected(event.target.checked);
    };

    const nextStep = () => {
        setStep(step + 1);
        setIsCheckboxSelected(false); // Reset checkbox for the next step
    };

    const prevStep = () => {
        if (step > 1) {
            setStep(step - 1);
        }
    };

    const handleLinkRepresentationShow = () => {
        setShowLinkRepresentationModal(true);
    };

    const handleLinkRepresentationClose = () => {
        setShowLinkRepresentationModal(false);
        setStep(1); // Reset to first step when modal is closed
        setIsCheckboxSelected(false);
    };

    return (
        <div className="container">
            {/* Sticky Card Name Container */}
            <div className="cardname-container">
                <div className="name-container row pl-1">
                    <h3>
                        <strong>Royal Retreat</strong>
                    </h3>
                </div>
                <div className="col-12 pl-3 pt-3 card-container">
                    <div onClick={() => scrollToCard(cardARef)}>
                        <strong>General Info</strong>
                    </div>
                    <div onClick={() => scrollToCard(cardBRef)}>
                        <strong>Rentability rules</strong>
                    </div>
                    <div onClick={() => scrollToCard(cardCRef)}>
                        <strong>Link Representation</strong>
                    </div>
                    <div onClick={() => scrollToCard(cardDRef)}>
                        <strong>Card D</strong>
                    </div>
                </div>
            </div>

            <div className="card-detail">
                {/* Basic Card-1*/}
                <div ref={cardARef} className="p-4">
                    {''}
                </div>
                <div className="card shadow mb-4">
                    <div className="card-header py-4">
                        <h6 className="m-0 font-weight-bold text-black">
                            <strong>General Info</strong>
                        </h6>
                    </div>
                    <div className="details-container">
                        <div className="col-12">
                            <div className="card-body">
                                <div className="info-row">
                                    <p>
                                        <strong>Name: </strong>Royal Retreat
                                    </p>
                                    <p>
                                        <strong>Start Date: </strong>18/09/2024
                                    </p>
                                    <p>
                                        <strong>Location: </strong>Texas
                                    </p>
                                    <p>
                                        <strong>End Date: </strong>20/09/2025
                                    </p>
                                    <p>
                                        <strong>Code: </strong>RR31
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Basic Card-2*/}
                <div ref={cardBRef} className="card shadow mb-4">
                    <div className="card-header py-3">
                        <h6 className="m-0 font-weight-bold text-black">
                            <strong>Rentability rules</strong>
                        </h6>
                    </div>
                    <div className="d-flex pt-2">
                        <div className="ml-4">
                            <label>
                                <strong>Stay date from - to and including</strong>
                            </label>
                            <div className="d-flex align-items-center">
                                <input type="date" className="form-control" />
                                <i className="fa-solid fa-arrow-right mx-2" />
                                <input type="date" className="form-control" />
                            </div>
                        </div>
                        <div className="ml-5">
                            <label>
                                <strong>Book date from - to</strong>
                            </label>
                            <div className="d-flex align-items-center">
                                <input type="date" className="form-control" />
                                <i className="fa-solid fa-arrow-right mx-2" />
                                <input type="date" className="form-control" />
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div className="card-body">
                        <div style={{ height: '250px', overflowY: 'auto' }}>
                            <table className="employee-table" style={{ width: '100%', tableLayout: 'fixed' }}>
                                <thead>
                                    <tr>
                                        <th style={{ width: '150px' }}>Stay date from</th>
                                        <th style={{ width: '150px' }}>Stay date to</th>
                                        <th style={{ width: '130px' }}>Length of stay</th>
                                        <th style={{ width: '100px' }}>Deviation</th>
                                        <th style={{ width: '230px' }}>Gap between two bookings</th>
                                        <th style={{ width: '180px' }}>Book dates (UTC +05:30)</th>
                                        <th style={{ width: '200px' }}>Days of arrival/departure</th>
                                        <th style={{ width: '100px' }}>Actions</th>
                                    </tr>
                                </thead>
                            </table>

                            <table className="employee-table" style={{ width: '100%', tableLayout: 'fixed' }}>
                                <tbody>
                                    <tr>
                                        <td style={{ width: '150px' }}>Fri 24/05/2024</td>
                                        <td style={{ width: '150px' }}>Mon 06/01/2025</td>
                                        <td style={{ width: '130px' }}>Min: 3</td>
                                        <td style={{ width: '100px' }}>-</td>
                                        <td style={{ width: '230px' }}>-</td>
                                        <td style={{ width: '180px' }}>
                                            Sun 01/01/2023,
                                            <br />
                                            04:30
                                        </td>
                                        <td style={{ width: '200px' }}>
                                            Arr. days: Mon, Fri
                                            <br />
                                            Dep. days: Mon, Fri
                                        </td>
                                        <td style={{ width: '100px' }}>
                                            <i
                                                className="fa-solid fa-pencil pl-2"
                                                onClick={handleEditShow}
                                                style={{ cursor: 'pointer', color: 'primary' }}
                                            />
                                            <i
                                                className="fa-solid fa-ban pl-2"
                                                style={{
                                                    cursor: 'pointer',
                                                    color: 'primary',
                                                }}
                                            />
                                        </td>
                                        {/* Modal for Edit roles */}
                                        <Modal
                                            show={showEditModal}
                                            onHide={handleEditClose}
                                            size="xl"
                                            backdrop="static"
                                            keyboard={false}
                                        >
                                            <Modal.Header style={{ color: 'black', background: '#fff' }} closeButton>
                                                <Modal.Title>
                                                    <strong>Edit Rentability Rule</strong>
                                                </Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body>
                                                <div style={{ height: '330px', overflow: 'auto' }}>
                                                    <div className="container-lg px-4 mt-4">
                                                        <Row>
                                                            <div className="card-body">
                                                                <Form>
                                                                    <div className="d-flex pt-2">
                                                                        <div className="mr-5">
                                                                            <label>
                                                                                <strong>
                                                                                    Stay date from - to and including
                                                                                </strong>
                                                                            </label>
                                                                            <div className="d-flex align-items-center">
                                                                                <input
                                                                                    type="date"
                                                                                    className="form-control"
                                                                                />
                                                                                <i className="fa-solid fa-arrow-right mx-2" />
                                                                                <input
                                                                                    type="date"
                                                                                    className="form-control"
                                                                                />
                                                                            </div>
                                                                            <div className="mt-2">
                                                                                <Form.Group controlId="formProduct">
                                                                                    <Form.Label>
                                                                                        <strong>Arrival Days</strong>
                                                                                    </Form.Label>
                                                                                    <Dropdown>
                                                                                        <Dropdown.Toggle
                                                                                            variant="light"
                                                                                            id="dropdown-basic"
                                                                                            className="text-start"
                                                                                        >
                                                                                            {displayValue}
                                                                                        </Dropdown.Toggle>

                                                                                        <Dropdown.Menu>
                                                                                            <div
                                                                                                className="dropdown-item"
                                                                                                onClick={
                                                                                                    handleSelectAllArrivalDays
                                                                                                }
                                                                                            >
                                                                                                <strong>Select All</strong>
                                                                                                {/* Deselect All option */}
                                                                                            </div>
                                                                                            <Dropdown.Divider />
                                                                                            {days.map((day) => (
                                                                                                <div
                                                                                                    key={day}
                                                                                                    className="dropdown-item"
                                                                                                >
                                                                                                    <Form.Check
                                                                                                        type="checkbox"
                                                                                                        label={day}
                                                                                                        checked={selectedArrivalDays.includes(
                                                                                                            day
                                                                                                        )}
                                                                                                        onChange={() =>
                                                                                                            handleArrivalCheckboxChange(
                                                                                                                day
                                                                                                            )
                                                                                                        }
                                                                                                    />
                                                                                                </div>
                                                                                            ))}
                                                                                            <Dropdown.Divider />
                                                                                            <div
                                                                                                className="dropdown-item"
                                                                                                onClick={
                                                                                                    handleDeselectAllArrivalDays
                                                                                                }
                                                                                            >
                                                                                                <strong>Deselect All</strong>
                                                                                                {/* Deselect All option */}
                                                                                            </div>
                                                                                        </Dropdown.Menu>
                                                                                    </Dropdown>
                                                                                </Form.Group>
                                                                            </div>
                                                                            <div className="d-flex mt-2">
                                                                                <div className="mr-2">
                                                                                    <label>
                                                                                        <strong>Min. length of stay</strong>
                                                                                    </label>
                                                                                    <input
                                                                                        type="number"
                                                                                        className="form-control"
                                                                                    />
                                                                                </div>
                                                                                <div>
                                                                                    <label>
                                                                                        <strong>Max. length of stay</strong>
                                                                                    </label>
                                                                                    <input
                                                                                        type="number"
                                                                                        className="form-control"
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                            <div className="mt-2">
                                                                                <label>
                                                                                    <strong>Gap between two bookings</strong>
                                                                                </label>
                                                                                <input
                                                                                    type="number"
                                                                                    className="form-control"
                                                                                />
                                                                            </div>
                                                                        </div>

                                                                        <div className="ml-5">
                                                                            <label>
                                                                                <strong>Book date from - to</strong>
                                                                            </label>
                                                                            <div className="d-flex align-items-center">
                                                                                <input
                                                                                    type="date"
                                                                                    className="form-control"
                                                                                />
                                                                                <i className="fa-solid fa-arrow-right mx-2" />
                                                                                <input
                                                                                    type="date"
                                                                                    className="form-control"
                                                                                />
                                                                            </div>
                                                                            <div className="mt-2">
                                                                                <Form.Group controlId="formDepartureDays">
                                                                                    <Form.Label>
                                                                                        <strong>Departure Days</strong>
                                                                                    </Form.Label>
                                                                                    <Dropdown>
                                                                                        <Dropdown.Toggle
                                                                                            variant="light"
                                                                                            id="dropdown-departure"
                                                                                            className="text-start"
                                                                                        >
                                                                                            {departureDisplayValue}
                                                                                        </Dropdown.Toggle>

                                                                                        <Dropdown.Menu>
                                                                                            <div
                                                                                                className="dropdown-item"
                                                                                                onClick={
                                                                                                    handleSelectAllDepartureDays
                                                                                                }
                                                                                            >
                                                                                                <strong>Select All</strong>
                                                                                                {/* Select All option */}
                                                                                            </div>
                                                                                            <Dropdown.Divider />
                                                                                            {days.map((day) => (
                                                                                                <div
                                                                                                    key={day}
                                                                                                    className="dropdown-item"
                                                                                                >
                                                                                                    <Form.Check
                                                                                                        type="checkbox"
                                                                                                        label={day}
                                                                                                        checked={selectedDepartureDays.includes(
                                                                                                            day
                                                                                                        )}
                                                                                                        onChange={() =>
                                                                                                            handleDepartureCheckboxChange(
                                                                                                                day
                                                                                                            )
                                                                                                        }
                                                                                                    />
                                                                                                </div>
                                                                                            ))}
                                                                                            <Dropdown.Divider />
                                                                                            <div
                                                                                                className="dropdown-item"
                                                                                                onClick={
                                                                                                    handleDeselectAllDepartureDays
                                                                                                }
                                                                                            >
                                                                                                <strong>Deselect All</strong>
                                                                                                {/* Deselect All option */}
                                                                                            </div>
                                                                                        </Dropdown.Menu>
                                                                                    </Dropdown>
                                                                                </Form.Group>
                                                                            </div>
                                                                            <div className="d-flex mt-2">
                                                                                <div className="mr-2">
                                                                                    <label>
                                                                                        <strong>Min. deviation</strong>
                                                                                    </label>
                                                                                    <input
                                                                                        type="number"
                                                                                        className="form-control"
                                                                                    />
                                                                                </div>
                                                                                <div>
                                                                                    <label>
                                                                                        <strong>Max. deviation</strong>
                                                                                    </label>
                                                                                    <input
                                                                                        type="number"
                                                                                        className="form-control"
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </Form>
                                                            </div>
                                                        </Row>
                                                    </div>
                                                </div>
                                            </Modal.Body>
                                            <Modal.Footer>
                                                <Button variant="secondary" onClick={handleEditClose}>
                                                    Close
                                                </Button>
                                                <Button variant="primary" onClick={handleEditSave}>
                                                    Save
                                                </Button>
                                            </Modal.Footer>
                                        </Modal>
                                    </tr>
                                </tbody>
                            </table>
                            <div className="text-end pt-3">
                                {/* Search Bar with Configure Column Button */}
                                <Button variant="primary mr-4" onClick={handleShow}>
                                    Add Rentability rule
                                </Button>
                            </div>
                            {/* Modal for create rules */}
                            <Modal show={showModal} onHide={handleClose} size="xl" backdrop="static" keyboard={false}>
                                <Modal.Header style={{ color: 'black', background: '#fff' }} closeButton>
                                    <Modal.Title>
                                        <strong>Create Rentability Rule</strong>
                                    </Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <div style={{ height: '330px', overflow: 'auto' }}>
                                        <div className="container-lg px-4 mt-4">
                                            <Row>
                                                <div className="card-body">
                                                    <Form>
                                                        <div className="d-flex pt-2">
                                                            <div className="mr-5">
                                                                <label>
                                                                    <strong>Stay date from - to and including</strong>
                                                                </label>
                                                                <div className="d-flex align-items-center">
                                                                    <input type="date" className="form-control" />
                                                                    <i className="fa-solid fa-arrow-right mx-2" />
                                                                    <input type="date" className="form-control" />
                                                                </div>
                                                                <div className="mt-2">
                                                                    <Form.Group controlId="formProduct">
                                                                        <Form.Label>
                                                                            <strong>Arrival Days</strong>
                                                                        </Form.Label>
                                                                        <Dropdown>
                                                                            <Dropdown.Toggle
                                                                                variant="light"
                                                                                id="dropdown-basic"
                                                                                className="text-start"
                                                                            >
                                                                                {displayValue}
                                                                            </Dropdown.Toggle>

                                                                            <Dropdown.Menu>
                                                                                <div
                                                                                    className="dropdown-item"
                                                                                    onClick={handleSelectAllArrivalDays}
                                                                                >
                                                                                    <strong>Select All</strong>
                                                                                    {/* Deselect All option */}
                                                                                </div>
                                                                                <Dropdown.Divider />
                                                                                {days.map((day) => (
                                                                                    <div key={day} className="dropdown-item">
                                                                                        <Form.Check
                                                                                            type="checkbox"
                                                                                            label={day}
                                                                                            checked={selectedArrivalDays.includes(
                                                                                                day
                                                                                            )}
                                                                                            onChange={() =>
                                                                                                handleArrivalCheckboxChange(
                                                                                                    day
                                                                                                )
                                                                                            }
                                                                                        />
                                                                                    </div>
                                                                                ))}
                                                                                <Dropdown.Divider />
                                                                                <div
                                                                                    className="dropdown-item"
                                                                                    onClick={handleDeselectAllArrivalDays}
                                                                                >
                                                                                    <strong>Deselect All</strong>
                                                                                    {/* Deselect All option */}
                                                                                </div>
                                                                            </Dropdown.Menu>
                                                                        </Dropdown>
                                                                    </Form.Group>
                                                                </div>
                                                                <div className="d-flex mt-2">
                                                                    <div className="mr-2">
                                                                        <label>
                                                                            <strong>Min. length of stay</strong>
                                                                        </label>
                                                                        <input type="number" className="form-control" />
                                                                    </div>
                                                                    <div>
                                                                        <label>
                                                                            <strong>Max. length of stay</strong>
                                                                        </label>
                                                                        <input type="number" className="form-control" />
                                                                    </div>
                                                                </div>
                                                                <div className="mt-2">
                                                                    <label>
                                                                        <strong>Gap between two bookings</strong>
                                                                    </label>
                                                                    <input type="number" className="form-control" />
                                                                </div>
                                                            </div>

                                                            <div className="ml-5">
                                                                <label>
                                                                    <strong>Book date from - to</strong>
                                                                </label>
                                                                <div className="d-flex align-items-center">
                                                                    <input type="date" className="form-control" />
                                                                    <i className="fa-solid fa-arrow-right mx-2" />
                                                                    <input type="date" className="form-control" />
                                                                </div>
                                                                <div className="mt-2">
                                                                    <Form.Group controlId="formDepartureDays">
                                                                        <Form.Label>
                                                                            <strong>Departure Days</strong>
                                                                        </Form.Label>
                                                                        <Dropdown>
                                                                            <Dropdown.Toggle
                                                                                variant="light"
                                                                                id="dropdown-departure"
                                                                                className="text-start"
                                                                            >
                                                                                {departureDisplayValue}
                                                                            </Dropdown.Toggle>

                                                                            <Dropdown.Menu>
                                                                                <div
                                                                                    className="dropdown-item"
                                                                                    onClick={handleSelectAllDepartureDays}
                                                                                >
                                                                                    <strong>Select All</strong>
                                                                                    {/* Select All option */}
                                                                                </div>
                                                                                <Dropdown.Divider />
                                                                                {days.map((day) => (
                                                                                    <div key={day} className="dropdown-item">
                                                                                        <Form.Check
                                                                                            type="checkbox"
                                                                                            label={day}
                                                                                            checked={selectedDepartureDays.includes(
                                                                                                day
                                                                                            )}
                                                                                            onChange={() =>
                                                                                                handleDepartureCheckboxChange(
                                                                                                    day
                                                                                                )
                                                                                            }
                                                                                        />
                                                                                    </div>
                                                                                ))}
                                                                                <Dropdown.Divider />
                                                                                <div
                                                                                    className="dropdown-item"
                                                                                    onClick={handleDeselectAllDepartureDays}
                                                                                >
                                                                                    <strong>Deselect All</strong>
                                                                                    {/* Deselect All option */}
                                                                                </div>
                                                                            </Dropdown.Menu>
                                                                        </Dropdown>
                                                                    </Form.Group>
                                                                </div>
                                                                <div className="d-flex mt-2">
                                                                    <div className="mr-2">
                                                                        <label>
                                                                            <strong>Min. deviation</strong>
                                                                        </label>
                                                                        <input type="number" className="form-control" />
                                                                    </div>
                                                                    <div>
                                                                        <label>
                                                                            <strong>Max. deviation</strong>
                                                                        </label>
                                                                        <input type="number" className="form-control" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Form>
                                                </div>
                                            </Row>
                                        </div>
                                    </div>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={handleClose}>
                                        Close
                                    </Button>
                                    <Button variant="primary" onClick={handleSave}>
                                        Save
                                    </Button>
                                </Modal.Footer>
                            </Modal>
                        </div>
                    </div>
                </div>

                {/* Basic Card-3*/}
                <div ref={cardCRef} className="card shadow mb-4">
                    <div className="card-header py-3">
                        <h6 className="m-0 font-weight-bold text-black">
                            <strong>Link Representation</strong>
                        </h6>
                    </div>
                    <div className="card-body">
                        <div className="location-dropdown col-4">
                            <Form.Group controlId="formLocations">
                                <Form.Label>
                                    <strong>Location</strong>
                                </Form.Label>
                                <Dropdown>
                                    <Dropdown.Toggle variant="light" id="dropdown-basic" className="text-start">
                                        {displaylocation}
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        <div
                                            className="dropdown-item"
                                            onClick={
                                                allLocationsSelected ? handleDeselectAllLocations : handleSelectAllLocations
                                            }
                                        >
                                            <strong>{allLocationsSelected ? 'Deselect All' : 'Select All'}</strong>
                                        </div>
                                        <Dropdown.Divider />
                                        <div className="rentability-set searchbar">
                                            {/* eslint-disable-next-line max-len*/}
                                            <form
                                                className="d-none d-lg-inline-block form-inline mr-auto ml-md-3 my-2 my-md-0 mw-100 navbar-search"
                                                style={{ width: '90%' }}
                                            >
                                                <div className="input-group">
                                                    <input
                                                        type="text"
                                                        className="form-control bg-light border-0 small"
                                                        placeholder="Search for..."
                                                        aria-label="Search"
                                                        aria-describedby="basic-addon2"
                                                    />
                                                    <div className="input-group-append">
                                                        <button className="btn btn-primary" type="button">
                                                            <i className="fas fa-search fa-sm" />
                                                        </button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                        {locations.map((location) => (
                                            <div key={location} className="dropdown-item">
                                                <Form.Check
                                                    type="checkbox"
                                                    label={location}
                                                    checked={selectedLocations.includes(location)}
                                                    onChange={() => handleLocationCheckboxChange(location)}
                                                />
                                            </div>
                                        ))}
                                        <Dropdown.Divider />
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Form.Group>
                        </div>
                        <hr />

                        <div className="d-flex pt-2">
                            <div>
                                <label>
                                    <strong>Stay date from - to and including</strong>
                                </label>
                                <div className="d-flex align-items-center">
                                    <input type="date" className="form-control" />
                                    <i className="fa-solid fa-arrow-right mx-2" />
                                    <input type="date" className="form-control" />
                                </div>
                            </div>
                            <div className="ml-5">
                                <label>
                                    <strong>Book date from - to</strong>
                                </label>
                                <div className="d-flex align-items-center">
                                    <input type="date" className="form-control" />
                                    <i className="fa-solid fa-arrow-right mx-2" />
                                    <input type="date" className="form-control" />
                                </div>
                            </div>
                        </div>
                        <hr />
                        <div className=" DualSearch-bar row">
                            <div className="col-6">
                                <form className="search-form" style={{ width: '120%' }}>
                                    <input
                                        type="text"
                                        placeholder="Search in: Distribution channel name, Code ..."
                                        className="search-input"
                                    />
                                    <button type="submit" className="search-button">
                                        <i className="fas fa-search" />
                                    </button>
                                </form>
                            </div>
                            <div className="col-6">
                                <form className="search-form" style={{ width: '120%' }}>
                                    <input
                                        type="text"
                                        placeholder="Search in: Representation name or Internal name"
                                        className="search-input"
                                    />
                                    <button type="submit" className="search-button">
                                        <i className="fas fa-search" />
                                    </button>
                                </form>
                            </div>
                        </div>

                        <div style={{ height: '250px', overflow: 'auto' }}>
                            <table className="employee-table mt-3">
                                <thead>
                                    <tr>
                                        <th>Representation Name</th>
                                        <th>Internal Name</th>
                                        <th>Path</th>
                                        <th>Stay date from</th>
                                        <th>Stay date to</th>
                                        <th>Book date from</th>
                                        <th>Book date to</th>
                                        <th />
                                    </tr>
                                </thead>

                                <tbody>
                                    <tr>
                                        <td>Cottage 4</td>
                                        <td>C41</td>
                                        <td>
                                            http://c21zsn.localhost:3000/client/rate-manager/accomodationtype/accomodationtype-detail
                                        </td>
                                        <td>Sun 3/11/2024</td>
                                        <td>Tue 28/12/2024</td>
                                        <td>Fri 04/10/2023</td>
                                        <td>Wed 20/4/2023</td>
                                        <td>
                                            <a href="#" className="text-danger">
                                                <strong>Unlink</strong>
                                            </a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Cottage 5</td>
                                        <td>C51</td>
                                        <td>
                                            http://c21zsn.localhost:3000/client/rate-manager/accomodationtype/accomodationtype-detail
                                        </td>
                                        <td>Mon 5/11/2023</td>
                                        <td>Tue 28/12/2023</td>
                                        <td>Sun 01/12/2023</td>
                                        <td>Mon 12/01/2024</td>
                                        <td>
                                            <a href="#" className="text-danger">
                                                <strong>Unlink</strong>
                                            </a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div className="text-end">
                            <Button variant="primary" onClick={handleLinkRepresentationShow}>
                                Link Representation
                            </Button>

                            {/* Link Representation Modal */}
                            <Modal
                                show={showLinkRepresentationModal}
                                onHide={handleLinkRepresentationClose}
                                size="xl"
                                backdrop="static"
                                keyboard={false}
                            >
                                <Modal.Header closeButton style={{ color: 'black', background: '#fff' }}>
                                    <Modal.Title>
                                        <strong>Link Representation</strong>
                                    </Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    {/* Step Indicators */}
                                    <div className="d-flex justify-content-center mb-4">
                                        {/* Step 1 */}
                                        <div
                                            className={`step-indicator ${
                                                step > 1 ? 'completed' : step === 1 ? 'current' : ''
                                            }`}
                                        >
                                            1
                                        </div>
                                        {/* Step 2 */}
                                        <div
                                            className={`step-indicator ${
                                                step > 2 ? 'completed' : step === 2 ? 'current' : ''
                                            }`}
                                        >
                                            2
                                        </div>
                                        {/* Step 3 */}
                                        <div
                                            className={`step-indicator ${
                                                step > 3 ? 'completed' : step === 3 ? 'current' : ''
                                            }`}
                                        >
                                            3
                                        </div>
                                        {/* Step 2 */}
                                        <div
                                            className={`step-indicator ${
                                                step > 4 ? 'completed' : step === 4 ? 'current' : ''
                                            }`}
                                        >
                                            4
                                        </div>
                                    </div>

                                    {step === 1 && (
                                        <div className="d-flex pt-3 ml-2 text-black">
                                            <div>
                                                <label>
                                                    <strong>Start Book Date</strong>
                                                </label>
                                                <div className="d-flex align-items-center">
                                                    <input type="date" className="form-control" />
                                                    <i className="fa-solid fa-arrow-right mx-2" />
                                                    <input type="date" className="form-control" />
                                                </div>
                                                <div className="d-flex align-items-center mt-3">
                                                    <Form.Group>
                                                        <Form.Label>
                                                            <strong>Location</strong>
                                                        </Form.Label>
                                                        <Dropdown
                                                            show={showDropdown}
                                                            onToggle={(isOpen) => setShowDropdown(isOpen)}
                                                            className="custom-dropdown"
                                                        >
                                                            <Dropdown.Toggle
                                                                variant="outline-secondary"
                                                                className="w-100 text-start custom-dropdown-toggle"
                                                            >
                                                                Select location
                                                            </Dropdown.Toggle>
                                                            <Dropdown.Menu className="w-100 p-3 custom-dropdown-menu">
                                                                {/* Search bar within dropdown */}
                                                                <Form.Control
                                                                    type="text"
                                                                    placeholder="Search location"
                                                                    className="mb-2 search-bar"
                                                                />
                                                                {/* Static dropdown items */}
                                                                {locations.map((location, index) => (
                                                                    <Dropdown.Item
                                                                        key={index}
                                                                        onClick={() => setShowDropdown(false)}
                                                                        className="custom-dropdown-item"
                                                                    >
                                                                        {location}
                                                                    </Dropdown.Item>
                                                                ))}
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    </Form.Group>
                                                </div>
                                            </div>
                                            <div className="ml-5" style={{ paddingLeft: '10rem' }}>
                                                <label>
                                                    <strong>End Book date</strong>
                                                </label>
                                                <div className="d-flex align-items-center">
                                                    <input type="date" className="form-control" />
                                                    <i className="fa-solid fa-arrow-right mx-2" />
                                                    <input type="date" className="form-control" />
                                                </div>
                                            </div>
                                        </div>
                                    )}

                                    {step === 2 && (
                                        <div>
                                            <h5 className="text-black ml-3 mt-3">
                                                <strong>Accomodation types</strong>
                                            </h5>
                                            <hr />
                                            <div className="row">
                                                <div className="col-10">
                                                    <form className="search-form" style={{ width: '100%', padding: '8px' }}>
                                                        <input
                                                            type="text"
                                                            placeholder="Search for accomodation types with name, name path..."
                                                            className="search-input"
                                                        />
                                                        <button type="submit" className="search-button">
                                                            <i className="fas fa-search" />
                                                        </button>
                                                    </form>
                                                </div>
                                                <div className="filter-component col-2">
                                                    <p>
                                                        <strong>
                                                            <i className="fa fa-filter" /> Advance Filters
                                                        </strong>
                                                    </p>
                                                </div>
                                            </div>
                                            <table className="employee-table">
                                                <thead>
                                                    <tr>
                                                        <th>Name</th>
                                                        <th>Name path</th>
                                                    </tr>
                                                </thead>
                                                <tbody className="text-black">
                                                    <tr>
                                                        <td>
                                                            <strong>
                                                                <Form.Check
                                                                    type="checkbox"
                                                                    label="Building A"
                                                                    checked={isCheckboxSelected}
                                                                    onChange={handleCheckboxChange}
                                                                />
                                                            </strong>
                                                        </td>
                                                        <td>
                                                            http://c21zsn.localhost:3000/client/inventory-manager/accomodationtype/accomodation-detail
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <strong>
                                                                <Form.Check type="checkbox" label="Building B" />
                                                            </strong>
                                                        </td>
                                                        <td>
                                                            http://c21zsn.localhost:3000/client/inventory-manager/accomodationtype/accomodation-detail
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <strong>
                                                                <Form.Check type="checkbox" label="Building C" />
                                                            </strong>
                                                        </td>
                                                        <td>
                                                            http://c21zsn.localhost:3000/client/inventory-manager/accomodationtype/accomodation-detail
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    )}

                                    {step === 3 && (
                                        <div>
                                            <h5 className="text-black ml-3 mt-3">
                                                <strong>Select representation</strong>
                                            </h5>
                                            <hr />
                                            <div className="row">
                                                <div className="col-10">
                                                    <form className="search-form" style={{ width: '100%', padding: '8px' }}>
                                                        <input
                                                            type="text"
                                                            placeholder="Search for representation with name, name path..."
                                                            className="search-input"
                                                        />
                                                        <button type="submit" className="search-button">
                                                            <i className="fas fa-search" />
                                                        </button>
                                                    </form>
                                                </div>
                                                <div className="filter-component col-2">
                                                    <p>
                                                        <strong>
                                                            <i className="fa fa-filter" /> Advance Filters
                                                        </strong>
                                                    </p>
                                                </div>
                                            </div>
                                            <table className="employee-table">
                                                <thead>
                                                    <tr>
                                                        <th>Name</th>
                                                        <th>Name path</th>
                                                    </tr>
                                                </thead>
                                                <tbody className="text-black">
                                                    <tr>
                                                        <td>
                                                            <strong>
                                                                <Form.Check
                                                                    type="checkbox"
                                                                    label="Cottage 4"
                                                                    checked={isCheckboxSelected}
                                                                    onChange={handleCheckboxChange}
                                                                />
                                                            </strong>
                                                        </td>
                                                        <td>
                                                            http://c21zsn.localhost:3000/client/inventory-manager/accomodationtype/accomodation-detail
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <strong>
                                                                <Form.Check type="checkbox" label="Cottage 5" />
                                                            </strong>
                                                        </td>
                                                        <td>
                                                            http://c21zsn.localhost:3000/client/inventory-manager/accomodationtype/accomodation-detail
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <strong>
                                                                <Form.Check type="checkbox" label="Cottage 6" />
                                                            </strong>
                                                        </td>
                                                        <td>
                                                            http://c21zsn.localhost:3000/client/inventory-manager/accomodationtype/accomodation-detail
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    )}

                                    {step === 4 && (
                                        <Container className="p-4 text-black">
                                            <h4>
                                                <strong>The update has the following changes:</strong>
                                            </h4>

                                            <Row className="mb-3">
                                                <Col>
                                                    <p>
                                                        <strong>Start Date</strong>
                                                    </p>
                                                    <Badge bg="primary">Monday, 8 January 2024</Badge>
                                                </Col>
                                                <Col>
                                                    <p>
                                                        <strong>End Date</strong>
                                                    </p>
                                                    <Badge bg="primary">Monday, 6 January 2025</Badge>
                                                </Col>
                                                <Col>
                                                    <p>
                                                        <strong>Start Book Date</strong>
                                                    </p>
                                                    <Badge bg="primary">Friday, 25 October 2024</Badge>
                                                </Col>
                                                <Col>
                                                    <p>
                                                        <strong>End Book Date</strong>
                                                    </p>
                                                    <Badge bg="primary">Monday, 6 January 2025</Badge>
                                                </Col>
                                            </Row>

                                            <p>
                                                <strong>Accomodation type:</strong> 1 Selected
                                            </p>
                                            <div className="mt-4">
                                                <h5>
                                                    <strong>Representation:</strong>
                                                </h5>

                                                <table className="employee-table">
                                                    <thead>
                                                        <tr>
                                                            <th>Representation name</th>
                                                            <th>Accommodation type name</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>Suite met 1 slaapkamer (Roompot Reizen)</td>
                                                            <td>1-bedroom suite</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </Container>
                                    )}
                                </Modal.Body>
                                <Modal.Footer>
                                    {step > 1 && (
                                        <Button variant="secondary" onClick={prevStep}>
                                            Previous
                                        </Button>
                                    )}
                                    {step < 4 && (
                                        <Button
                                            variant="primary"
                                            onClick={nextStep}
                                            disabled={step !== 1 && !isCheckboxSelected} // Disable if no checkbox is selected
                                        >
                                            {step === 1 ? 'Next' : 'Next'}
                                        </Button>
                                    )}
                                    {step === 4 && (
                                        <Button variant="primary" onClick={handleLinkRepresentationClose}>
                                            Finish
                                        </Button>
                                    )}
                                </Modal.Footer>
                            </Modal>
                        </div>
                    </div>
                </div>
                {/* Basic Card-4*/}
                <div ref={cardDRef} className="card shadow mb-4">
                    <div className="card-header py-3">
                        <h6 className="m-0 font-weight-bold text-black">
                            <strong>Card D</strong>
                        </h6>
                    </div>
                    <div className="card-body">
                        The styling for this basic card example is created by using default Bootstrap utility classes. By
                        using utility classes, the style of the card component can be easily modified with no need for any
                        custom CSS!
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RatetypeDetail;
