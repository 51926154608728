import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';

type SessionTimeoutWarningModalProp = {
    showModal: boolean;
    handleClose: () => void;
    handleExtendSession: () => void;
};

export const SessionTimeoutWarningModal = (prop: SessionTimeoutWarningModalProp) => {
    return (
        <Modal size="lg" show={prop.showModal} centered>
            <Modal.Header closeButton className="bg-warning">
                <Modal.Title className="text-dark fs-6">
                    <FontAwesomeIcon icon={icon({ name: 'triangle-exclamation' })} />
                    <span className="ms-2 text-uppercase fw-bold"> Session Timeout Warning</span>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="fs-5 text-dark text-center">
                <p>
                    <strong>Attention!</strong> your session is about to expire.
                </p>
                <p>
                    Please save any changes you have made. <br /> To avoid being logged out, please click on the{' '}
                    <strong>Extend Session</strong> button below.
                </p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={prop.handleClose}>
                    Close
                </Button>
                <Button variant="dark" onClick={prop.handleExtendSession}>
                    Extend Session
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
