import React from 'react';
import { PageHeaderType } from '../../../types/menu';
import PageHeader from '../../common/Header/PageHeader';

export const WebsiteBuilder = () => {
    const pageHeader: PageHeaderType = {
        title: 'Website Manager',
        prevNavLabel: 'Dashboard',
        prevNavLink: '/client/landing',
        iconClass: 'fas fa-laptop-code',
    };
    return (
        <div className="container-fluid">
            <PageHeader {...pageHeader} />
        </div>
    );
};
