import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { useAuth } from '../../../hooks/useAuth';
import { makeSelectFirstName, makeSelectLastName } from '../../../selectors/userSelector';
import { isClientFlowActive } from '../../../selectors/clientSelector';

type Props = {
    dropdownMenu?: string;
};
const UserInfoDropDown = (properties: Props) => {
    const isClientFlow = useSelector(isClientFlowActive);
    const { onLogout } = useAuth();
    const firstName = useSelector(makeSelectFirstName);
    const lastName = useSelector(makeSelectLastName);
    const profilepath = isClientFlow ? '/client/user-profile/' : '/admin/user-profile/';
    return (
        <>
            {/* Nav Item - Search Dropdown (Visible Only XS) */}
            <Dropdown className="nav-item dropdown no-arrow mx-1">
                <Dropdown.Toggle className="nav-link dropdown-toggle" id="alertsDropdown drop">
                    <i className="fas fa-user mr-2 text-black" />
                    <span className="mr-2 d-none d-lg-inline text-gray-900 small">
                        <strong>
                            {firstName} {lastName}
                        </strong>
                    </span>
                </Dropdown.Toggle>
                {/* Dropdown - Alerts */}
                <Dropdown.Menu align="end" className={`dropdown-menu ${properties?.dropdownMenu} shadow animated--grow-in`}>
                    <Dropdown.Item as={NavLink} to={profilepath} className="d-flex align-items-center">
                        <i className="fas fa-user fa-sm fa-fw mr-2 text-gray-600" />
                        Profile
                    </Dropdown.Item>
                    <Dropdown.Item className="d-flex align-items-center">
                        <i className="fas fa-cogs fa-sm fa-fw mr-2 text-gray-600" />
                        Settings
                    </Dropdown.Item>

                    <div className="dropdown-divider" />
                    <Dropdown.Item onClick={onLogout} className="d-flex align-items-center">
                        <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-600" />
                        Logout
                    </Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        </>
    );
};

export default UserInfoDropDown;
