import React from 'react';
import { BrowserRouter, Route, Routes as Switch } from 'react-router-dom';
import { UserLogin } from '../components/auth/Login';
import Authorizer from '../components/auth/Authorizer';
import GlobalErrorHandler from '../components/common/ErrorHandler/GlobalErrorHandler';
import NotificationContext from '../components/contexts/NotificationContext';
import { ForgotPassword } from '../components/auth/ForgotPassword';
import NotFound from '../components/common/ErrorPages/NotFound';
import { isClientFlowActive } from '../selectors/clientSelector';
import { useSelector } from '../store';
import AdminRoutes from './admin';
import ClientRoutes from './client';

const Routes = () => {
    const isClientFlow = useSelector(isClientFlowActive);
    return (
        <BrowserRouter>
            <NotificationContext>
                <GlobalErrorHandler>
                    <Authorizer>
                        <Switch>
                            {!isClientFlow ? AdminRoutes : ''}
                            {ClientRoutes}
                            <Route path="/user-login" element={<UserLogin />} />
                            <Route path="/forgot-password" element={<ForgotPassword />} />
                            <Route path="*" element={<NotFound />} />
                        </Switch>
                    </Authorizer>
                </GlobalErrorHandler>
            </NotificationContext>
        </BrowserRouter>
    );
};

export default Routes;
