import React from 'react';
import { Modal, Button, Form, Row, Col } from 'react-bootstrap';

type Props = {
    showModal: boolean;
    handleClose: () => void;
};
const CreateAccomodationModal = (props: Props) => {
    const { showModal, handleClose } = props;
    return (
        <>
            <Modal
                show={showModal}
                onHide={handleClose}
                size="xl"
                backdrop="static" // Disable clicking outside to close
                keyboard={false} // Disable pressing 'Esc' to close
            >
                <Modal.Header style={{ color: 'black', background: '#fff' }} closeButton>
                    <Modal.Title>
                        <strong>Create New Accomodation Type</strong>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div style={{ height: '400px', overflow: 'auto' }}>
                        <div className="container-xl px-4 mt-4">
                            <Row>
                                <Col md={6}>
                                    <div className="card mb-4">
                                        <div className="card-header">
                                            <strong>General Information</strong>
                                        </div>
                                        <div className="card-body">
                                            <Form>
                                                <Form.Group controlId="formEmployeeCategory">
                                                    <Form.Label>
                                                        <strong>Location</strong>
                                                    </Form.Label>
                                                    <div className="dropdown-with-caret">
                                                        <Form.Control as="select" defaultValue="">
                                                            <option>Choose location</option>
                                                            <option>Verina</option>
                                                            <option>Siena</option>
                                                            <option>Sorrento</option>
                                                            <option>Capri</option>
                                                            <option>Frankfrut</option>
                                                        </Form.Control>
                                                        <span className="caret" />
                                                    </div>
                                                </Form.Group>
                                                <br />
                                                <Form.Group controlId="formFirstName">
                                                    <Form.Label>
                                                        <strong>Accomodation Name</strong>
                                                    </Form.Label>
                                                    <Form.Control type="text" defaultValue=" " />
                                                </Form.Group>
                                                <br />
                                                <Form.Group controlId="formFirstName">
                                                    <Form.Label>
                                                        <strong>Accomodation Code</strong>
                                                    </Form.Label>
                                                    <Form.Control type="text" defaultValue="" />
                                                </Form.Group>
                                                <br />
                                                <Form.Group controlId="formFirstName">
                                                    <Form.Label>
                                                        <strong>Accomodation Description</strong>
                                                    </Form.Label>
                                                    <Form.Control type="text" defaultValue="" />
                                                </Form.Group>
                                            </Form>
                                        </div>
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className="card mb-4">
                                        <div className="card-header">
                                            <strong>Address Details</strong>
                                        </div>
                                        <div className="card-body">
                                            <Form>
                                                <Form.Group controlId="formStreet1">
                                                    <Form.Label>
                                                        <strong>Street 1</strong>
                                                    </Form.Label>
                                                    <Form.Control type="text" defaultValue="" />
                                                </Form.Group>

                                                <Form.Group controlId="formStreet2">
                                                    <Form.Label>
                                                        <strong>Street 2</strong>
                                                    </Form.Label>
                                                    <Form.Control type="text" defaultValue="" />
                                                </Form.Group>

                                                <Form.Group controlId="formStreet2">
                                                    <Form.Label>
                                                        <strong>Pincode</strong>
                                                    </Form.Label>
                                                    <Form.Control type="text" defaultValue="" />
                                                </Form.Group>

                                                <Form.Group controlId="formCity">
                                                    <Form.Label>
                                                        <strong>City</strong>
                                                    </Form.Label>
                                                    <Form.Control type="text" defaultValue="" />
                                                </Form.Group>

                                                <Form.Group controlId="formState">
                                                    <Form.Label>
                                                        <strong>State</strong>
                                                    </Form.Label>
                                                    <Form.Control type="text" defaultValue="" />
                                                </Form.Group>

                                                <Form.Group controlId="formCountry">
                                                    <Form.Label>
                                                        <strong>Country</strong>
                                                    </Form.Label>
                                                    <Form.Control type="text" defaultValue="" />
                                                </Form.Group>
                                            </Form>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleClose}>
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default CreateAccomodationModal;
