import React from 'react';
import cn from 'classnames';
import { useLocation } from 'react-router-dom';
import { NavItem, NavLink } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { BlockMenuType } from '../../../types/menu';
import './BlockMenu.css';

const BlockMenu = (props: BlockMenuType) => {
    const { menuLabel, menus } = props;

    const location = useLocation();
    const currentPath = location.pathname.split('/')[1].toLowerCase();
    const isActive = (label: string) => {
        return (!currentPath && label.toLowerCase() === 'dashboard') || currentPath === label.toLowerCase();
    };

    return (
        <>
            <div className="block-menu">
                <div className="menu-label-container">
                    <i className="fas fa-briefcase" />
                    <span className="menu-label">{menuLabel}</span>
                </div>
                {menus.map((menuItem, index) => (
                    <NavItem key={index} className={cn('nav-item', { active: isActive(menuItem.label) })}>
                        <NavLink as={Link} className="nav-link pl-5" to={menuItem.path}>
                            <i className={menuItem.faIconClass} />
                            <span>{menuItem.label}</span>
                        </NavLink>
                    </NavItem>
                ))}
            </div>
        </>
    );
};

export default BlockMenu;
