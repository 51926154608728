// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.profile-container {
    display: flex;
    align-items: center;
    padding: 10px;
    background-color: #f8f9fa;
    border-top: 1px solid #e3e6f0;
}

/* Sidebar.css */
.sidebar-logo {
    max-width: 115%;
    height: auto;
}

`, "",{"version":3,"sources":["webpack://./src/components/client/Sidebar/Sidebar.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,aAAa;IACb,yBAAyB;IACzB,6BAA6B;AACjC;;AAEA,gBAAgB;AAChB;IACI,eAAe;IACf,YAAY;AAChB","sourcesContent":[".profile-container {\r\n    display: flex;\r\n    align-items: center;\r\n    padding: 10px;\r\n    background-color: #f8f9fa;\r\n    border-top: 1px solid #e3e6f0;\r\n}\r\n\r\n/* Sidebar.css */\r\n.sidebar-logo {\r\n    max-width: 115%;\r\n    height: auto;\r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
