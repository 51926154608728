import React, { useEffect, useState } from 'react';
import moment from 'moment';
import cn from 'classnames';
import { NavLink, useParams } from 'react-router-dom';
import { ClientExtended } from '../../../types/client';
import { generateSignUpLink, getClientDetailsById } from '../../../services/client';
import { APP_DATE_FORMAT } from '../../../constants/app';
import { ClipboardCopy } from '../../common/Light/CliboardCopy';

export const ViewClientProfile = () => {
    const { id } = useParams();
    const [loadingData, setLoadingData] = useState(false);
    const [generatingLink, setGeneratingLink] = useState(false);
    const [signUpLink, setSignUpLink] = useState<string | null>('');
    const [client, setClient] = useState<ClientExtended | null>(null);

    const handleGenerateSignUpLink = async () => {
        if (client && !client.backOfficeUserExists) {
            setGeneratingLink(true);
            const response = await generateSignUpLink(client);
            if (response.data.signUpLink) {
                setSignUpLink(response.data.signUpLink);
            }
            setGeneratingLink(false);
        }
    };
    useEffect(() => {
        if (id) {
            const getClientData = async () => {
                setLoadingData(true);
                const response = await getClientDetailsById(id);
                if (response.data) {
                    setClient(response.data);
                }
                setLoadingData(false);
            };
            getClientData();
        }
    }, [id]);
    return (
        <div className="container-fluid">
            <div className="view-client-profile">
                <div className="container-xl px-4 mt-4">
                    <div className="col-12 p-2">
                        <div className="card mb-4">
                            <div className="card-body">
                                {loadingData && (
                                    <div className="row mt-2">
                                        <div className="col-sm-12 mt-2 mb-2 text-center p-2 valign-middle">
                                            Fetching client details, please wait
                                            <i className="fa-solid fa-circle-notch fa-spin ms-2" />
                                        </div>
                                    </div>
                                )}
                                {client && (
                                    <div className="row mt-2 data-container">
                                        <div className="col-12">
                                            <h4>{client.name}</h4>
                                            <hr />
                                        </div>
                                        <div className="col-12 col-md-6">
                                            <div className="col-12 p-2">
                                                <label>Client Code:</label> {client.code}
                                            </div>
                                            <div className="col-12 p-2">
                                                <label>Created Date:</label>{' '}
                                                {moment(client.createdAt).format(APP_DATE_FORMAT)}
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6">
                                            <div className="col-12 p-2">
                                                <label>Email:</label> {client.email} <ClipboardCopy text={client.email} />
                                            </div>
                                            <div className="col-12 p-2">
                                                <label>Phone:</label> {client.phone}
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6">
                                            <label className="fw-bold p-2 py-1">Address:</label>
                                            <div className="col-12 p-2 py-0">
                                                {client.addressStreet1}
                                                <br />
                                                {client.addressStreet2 ?? '-'}
                                                <br />
                                                {client.addressState ?? '-'}
                                                {', '}
                                                {client.addressCity ?? '-'}
                                                {', '}
                                                {client.addressPinCode ?? '-'}
                                                <br />
                                                {client.addressCountry ?? '-'}
                                            </div>
                                        </div>
                                        <div className="col-12 my-3">
                                            <div className="p-2 p-md-4 bg-light">
                                                <p className="fw-bold">
                                                    <i className="fa fa-xs fa-star text-custom-1" /> Generate sign up link
                                                </p>
                                                <p>
                                                    You can generate the signup link for the initial{' '}
                                                    <strong>Back Office </strong> user in case the client has not received
                                                    the signup link. <br />
                                                    <strong>Note:</strong> An email notification will be sent directly to the
                                                    client at the registered email address. You can generate the singup link
                                                    for the initial user in case the client has not received the sign up
                                                    link.
                                                </p>
                                                <button
                                                    className={cn(`btn btn-primary`, {
                                                        'btn-secondary': client.backOfficeUserExists,
                                                    })}
                                                    type="button"
                                                    disabled={client.backOfficeUserExists}
                                                    onClick={handleGenerateSignUpLink}
                                                >
                                                    Generate Now
                                                    {generatingLink && (
                                                        <i className="fa-solid fa-circle-notch fa-spin ms-2" />
                                                    )}
                                                </button>
                                                {client.backOfficeUserExists && (
                                                    <span className="small ms-3 text-custom-1">
                                                        Looks like the <strong>Back Office </strong> user for this client is
                                                        already created.
                                                    </span>
                                                )}
                                                {signUpLink && (
                                                    <div className="py-3">
                                                        <strong>Sign up Link:</strong> {signUpLink}{' '}
                                                        <ClipboardCopy text={signUpLink} />
                                                    </div>
                                                )}
                                            </div>
                                            <div className="row gx-3 my-3 text-end">
                                                <div className="col-12">
                                                    <NavLink
                                                        to={`/admin/client-profiles/view/list`}
                                                        className="btn btn-primary"
                                                    >
                                                        Back
                                                    </NavLink>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
