// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/** Card-name CSS **/
.cardname-container{
  position: fixed;
  top: 0px;
  width: 82%;
  height: 110px;
  z-index: 999;
  background-color:rgb(254, 254, 254);
  margin-bottom: 10px;
  border-radius: 2px;
  box-shadow: 1px 2px 3px 1px rgb(121, 152, 157);

}
.card-detail{
  padding-top: 100px;
}


.cardname-container div {
  margin: 0 10px; /* Adds space between each card name */
  margin-left: 1px;
  cursor: pointer; /* Changes cursor to pointer when hovering over card names */
  display: inline-block;
  color: #000;
 
}

.cardname-container div:hover {
  color: #007bff;
  cursor: pointer;
}

/** end **/

/** Role Card Checkbox **/
input[type="checkbox"] {
  border-color: #3b3a3a;
}

/** END **/
`, "",{"version":3,"sources":["webpack://./src/components/client/BackOffice/Role/Role.css"],"names":[],"mappings":"AAAA,oBAAoB;AACpB;EACE,eAAe;EACf,QAAQ;EACR,UAAU;EACV,aAAa;EACb,YAAY;EACZ,mCAAmC;EACnC,mBAAmB;EACnB,kBAAkB;EAClB,8CAA8C;;AAEhD;AACA;EACE,kBAAkB;AACpB;;;AAGA;EACE,cAAc,EAAE,sCAAsC;EACtD,gBAAgB;EAChB,eAAe,EAAE,4DAA4D;EAC7E,qBAAqB;EACrB,WAAW;;AAEb;;AAEA;EACE,cAAc;EACd,eAAe;AACjB;;AAEA,UAAU;;AAEV,yBAAyB;AACzB;EACE,qBAAqB;AACvB;;AAEA,UAAU","sourcesContent":["/** Card-name CSS **/\r\n.cardname-container{\r\n  position: fixed;\r\n  top: 0px;\r\n  width: 82%;\r\n  height: 110px;\r\n  z-index: 999;\r\n  background-color:rgb(254, 254, 254);\r\n  margin-bottom: 10px;\r\n  border-radius: 2px;\r\n  box-shadow: 1px 2px 3px 1px rgb(121, 152, 157);\r\n\r\n}\r\n.card-detail{\r\n  padding-top: 100px;\r\n}\r\n\r\n\r\n.cardname-container div {\r\n  margin: 0 10px; /* Adds space between each card name */\r\n  margin-left: 1px;\r\n  cursor: pointer; /* Changes cursor to pointer when hovering over card names */\r\n  display: inline-block;\r\n  color: #000;\r\n \r\n}\r\n\r\n.cardname-container div:hover {\r\n  color: #007bff;\r\n  cursor: pointer;\r\n}\r\n\r\n/** end **/\r\n\r\n/** Role Card Checkbox **/\r\ninput[type=\"checkbox\"] {\r\n  border-color: #3b3a3a;\r\n}\r\n\r\n/** END **/\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
