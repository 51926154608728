export enum ApplicationEnvironment {
    PRODUCTION = 'production',
    DEVELOPMENT = 'development',
    LOCAL = 'local',
}

export enum ApplicationUserLevel {
    CLIENT = 'CLIENT',
    PROVIDER = 'PROVIDER',
}

export enum RecordStatus {
    ACTIVE = 'ACTIVE',
    INACTIVE = 'INACTIVE',
}

export enum Gender {
    MALE = 'Male',
    FEMALE = 'Female',
    OTHER = 'Other',
}

export enum ActionStatus {
    REQUESTED = 'REQUESTED',
    REJECTED = 'REJECTED',
    APPROVED = 'APPROVED',
    PENDING = 'PENDING',
    SAVED = 'SAVED',
}

export enum OnlineStatus {
    ONLINE = 'ONLINE',
    OFFLINE = 'OFFLINE',
    AWAY = 'AWAY',
}

export enum Roles {
    BACKOFFICE_USER = 'BACKOFFICE_USER',
    APPLICATION_SUPER_ADMIN = 'APPLICATION_SUPER_ADMIN',
}
