/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { AppDispatch, IRootState } from '../../store';
import { setAccessToken } from '../../store/token';
import { loginUser, logoutUser } from '../../services/authentication';
import { setLoggedInUser } from '../../store/user';
import { setClientInfo } from '../../store/client';
import { ClientUserSignup, UserCreds } from '../../types/user';
import { getLoggedInUserDetails, getTemporaryUserDetails } from '../../services/user';
import { makeSelectOfficialEmail } from '../../selectors/userSelector';
import { SUPER_CLIENT_CODE } from '../../constants/app';
import { getClientDetailsByCode, preSigninClientUser } from '../../services/client';
import { getClientCode } from '../../selectors/applicationSelector';

type AuthorizerProps = {
    children: ReactElement;
};

type AuthContextType = {
    token: string | null;
    onLogin: (creds: UserCreds) => void;
    onLogout: () => void;
    onSignup: (creds: ClientUserSignup) => void;
};

export const AuthContext = React.createContext<
    Partial<AuthContextType> & Pick<AuthContextType, 'onLogin' | 'onLogout' | 'onSignup'>
>({
    onLogin: async () => undefined,
    onLogout: async () => undefined,
    onSignup: async () => undefined,
});
const loginPath = '/user-login';
const resetPasswordPath = '/reset-password';
const userSignupPath = '/login/user/back-office';
const forgotPasswordPath = '/forgot-password';

const allowedPaths = [loginPath, forgotPasswordPath, resetPasswordPath, userSignupPath];
const Authorizer = (props: AuthorizerProps) => {
    const navigate = useNavigate();
    const dispatch: AppDispatch = useDispatch();
    const location = useLocation();
    const accessToken = useSelector((state: IRootState) => state.token.accessToken);
    const officialEmail = useSelector(makeSelectOfficialEmail);
    const code = getClientCode();
    const setClientState = useCallback(async () => {
        const client = await getClientDetailsByCode(code);
        if (client.data) {
            const data = {
                name: client.data.name,
                code: client.data.code,
            };
            dispatch(setClientInfo(data));
        }
    }, [code, dispatch]);

    useEffect(() => {
        const initializeClient = async () => {
            try {
                dispatch(setClientInfo({ code }));
                const isResetPasswordPath = location.pathname.startsWith(resetPasswordPath);
                const isUserSignupPath = location.pathname.startsWith(userSignupPath);
                if (accessToken && !allowedPaths.includes(location.pathname)) {
                    navigate(location.pathname);
                } else if (
                    !accessToken &&
                    (allowedPaths.includes(location.pathname) || isResetPasswordPath || isUserSignupPath)
                ) {
                    navigate(location.pathname);
                } else {
                    navigate(loginPath);
                }

                if (code && accessToken && !officialEmail) {
                    dispatch(setAccessToken(accessToken));
                    const response = await getLoggedInUserDetails();
                    dispatch(setLoggedInUser(response.data));
                    if (code !== SUPER_CLIENT_CODE) {
                        await setClientState();
                    }
                }
            } catch (err: unknown) {
                dispatch(setAccessToken(null));
            }
        };
        initializeClient();
    }, [accessToken, dispatch, officialEmail, code, setClientState, location.pathname, navigate]);

    const handleLogin = async (credentials: UserCreds) => {
        try {
            const authToken = (await loginUser(credentials)) as { token: string };
            dispatch(setAccessToken(authToken.token));

            if (authToken.token) {
                const response = await getLoggedInUserDetails();
                dispatch(setLoggedInUser(response.data));
                if (code === SUPER_CLIENT_CODE) {
                    navigate('/');
                } else if (code) {
                    await setClientState();
                    navigate('/client/landing');
                }
            }
        } catch (_err: any) {
            dispatch(setAccessToken(null));
        }
    };

    // For initial signup of the client BO user,
    const handleSignup = async (credentials: ClientUserSignup) => {
        try {
            const authToken = (await preSigninClientUser(credentials)) as { token: string };
            if (authToken.token) {
                dispatch(setAccessToken(authToken.token));
                const response = await getTemporaryUserDetails();
                dispatch(setLoggedInUser(response.data));
                navigate('/client/manage-users/user/init/profile/update');
            }
        } catch (_err: any) {
            dispatch(setAccessToken(null));
        }
    };

    const handleLogout = async () => {
        await logoutUser();
        dispatch(setAccessToken(null));
    };

    const value = {
        accessToken,
        onLogin: handleLogin,
        onLogout: handleLogout,
        onSignup: handleSignup,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
    };

    return <AuthContext.Provider value={value}>{props.children}</AuthContext.Provider>;
};

export default Authorizer;
