import React from 'react';
import { Modal, Button, Form, Row, Col } from 'react-bootstrap';

type Props = {
    showModal: boolean;
    handleClose: () => void;
};
const CreateEmployeeModal = (props: Props) => {
    const { showModal, handleClose } = props;
    return (
        <>
            <Modal show={showModal} onHide={handleClose} size="xl">
                <Modal.Header closeButton>
                    <Modal.Title>Create New Employee</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div style={{ height: '400px', overflow: 'auto' }}>
                        <div className="container-xl px-4 mt-4">
                            <Row>
                                <Col md={6}>
                                    <div className="card mb-4">
                                        <div className="card-header">General Information</div>
                                        <div className="card-body">
                                            <Form>
                                                <Form.Group controlId="formFirstName">
                                                    <Form.Label>Fisrt Name</Form.Label>
                                                    <Form.Control type="text" defaultValue=" " />
                                                </Form.Group>

                                                <Form.Group controlId="formFirstName">
                                                    <Form.Label>Middle Name</Form.Label>
                                                    <Form.Control type="text" defaultValue="" />
                                                </Form.Group>

                                                <Form.Group controlId="formFirstName">
                                                    <Form.Label>Last Name</Form.Label>
                                                    <Form.Control type="text" defaultValue="" />
                                                </Form.Group>

                                                <Form.Group controlId="formLastName">
                                                    <Form.Label>Login Name</Form.Label>
                                                    <Form.Control type="text" defaultValue="" />
                                                </Form.Group>

                                                <Form.Group controlId="formDob">
                                                    <Form.Label>Date of Birth</Form.Label>
                                                    <Form.Control type="date" defaultValue="" />
                                                </Form.Group>

                                                <Form.Group controlId="formLastName">
                                                    <Form.Label>Contact No.</Form.Label>
                                                    <Form.Control type="text" defaultValue="" />
                                                </Form.Group>

                                                <Form.Group controlId="formEmail">
                                                    <Form.Label>Email ID</Form.Label>
                                                    <Form.Control type="email" defaultValue="" />
                                                </Form.Group>

                                                <Form.Group controlId="formGender">
                                                    <Form.Label>Gender</Form.Label>
                                                    <Form.Control as="select" defaultValue="">
                                                        <option>Male</option>
                                                        <option>Female</option>
                                                        <option>Other</option>
                                                    </Form.Control>
                                                </Form.Group>
                                            </Form>
                                        </div>
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className="card mb-4">
                                        <div className="card-header">Address Details</div>
                                        <div className="card-body">
                                            <Form>
                                                <Form.Group controlId="formStreet1">
                                                    <Form.Label>Street 1</Form.Label>
                                                    <Form.Control type="text" defaultValue="" />
                                                </Form.Group>

                                                <Form.Group controlId="formStreet2">
                                                    <Form.Label>Street 2</Form.Label>
                                                    <Form.Control type="text" defaultValue="" />
                                                </Form.Group>

                                                <Form.Group controlId="formStreet2">
                                                    <Form.Label>Pincode</Form.Label>
                                                    <Form.Control type="text" defaultValue="" />
                                                </Form.Group>

                                                <Form.Group controlId="formCity">
                                                    <Form.Label>City</Form.Label>
                                                    <Form.Control type="text" defaultValue="" />
                                                </Form.Group>

                                                <Form.Group controlId="formState">
                                                    <Form.Label>State</Form.Label>
                                                    <Form.Control type="text" defaultValue="" />
                                                </Form.Group>

                                                <Form.Group controlId="formCountry">
                                                    <Form.Label>Country</Form.Label>
                                                    <Form.Control type="text" defaultValue="" />
                                                </Form.Group>
                                            </Form>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleClose}>
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default CreateEmployeeModal;
