import React, { useRef } from 'react';

const EmployeeDetail: React.FC = () => {
    const cardARef = useRef<HTMLDivElement>(null);
    const cardBRef = useRef<HTMLDivElement>(null);
    const cardCRef = useRef<HTMLDivElement>(null);
    const cardDRef = useRef<HTMLDivElement>(null);

    const scrollToCard = (ref: React.RefObject<HTMLDivElement>) => {
        if (ref.current) {
            ref.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
        <div className="container">
            <div className="name-container">
                <h2>John Doe</h2>
            </div>
            <hr />
            <div className="cardname-container">
                <p onClick={() => scrollToCard(cardARef)}>Card A</p>
                <p onClick={() => scrollToCard(cardBRef)}>Card B</p>
                <p onClick={() => scrollToCard(cardCRef)}>Card C</p>
                <p onClick={() => scrollToCard(cardDRef)}>Card D</p>
            </div>
            <hr />
            <div className="details-container">
                <div className="col-12">
                    <div className="card mb-4">
                        <div className="card-body">
                            <h4>General Info</h4>
                            <hr />
                            <div className="info-row">
                                <p>
                                    <strong>Name:</strong> John
                                </p>
                                <p>
                                    <strong>Code:</strong> J1001
                                </p>
                                <p>
                                    <strong>Employee Group:</strong> Testing....
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <br />
            {/* Basic Card-1*/}
            <div ref={cardARef} className="card shadow mb-4">
                <div className="card-header py-3">
                    <h6 className="m-0 font-weight-bold text-primary">Card A</h6>
                </div>
                <div className="card-body">
                    The styling for this basic card example is created by using default Bootstrap utility classes. By using
                    utility classes, the style of the card component can be easily modified with no need for any custom CSS!
                </div>
            </div>
            {/* Basic Card-2*/}
            <div ref={cardBRef} className="card shadow mb-4">
                <div className="card-header py-3">
                    <h6 className="m-0 font-weight-bold text-primary">Card B</h6>
                </div>
                <div className="card-body">
                    The styling for this basic card example is created by using default Bootstrap utility classes. By using
                    utility classes, the style of the card component can be easily modified with no need for any custom CSS!
                </div>
            </div>
            {/* Basic Card-3*/}
            <div ref={cardCRef} className="card shadow mb-4">
                <div className="card-header py-3">
                    <h6 className="m-0 font-weight-bold text-primary">Card C</h6>
                </div>
                <div className="card-body">
                    The styling for this basic card example is created by using default Bootstrap utility classes. By using
                    utility classes, the style of the card component can be easily modified with no need for any custom CSS!
                </div>
            </div>
            {/* Basic Card-4*/}
            <div ref={cardDRef} className="card shadow mb-4">
                <div className="card-header py-3">
                    <h6 className="m-0 font-weight-bold text-primary">Card D</h6>
                </div>
                <div className="card-body">
                    The styling for this basic card example is created by using default Bootstrap utility classes. By using
                    utility classes, the style of the card component can be easily modified with no need for any custom CSS!
                </div>
            </div>
        </div>
    );
};

export default EmployeeDetail;
