import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap'; // Added Form for form controls
import { Link } from 'react-router-dom'; // Import Link for navigation
import { PageHeaderType } from '../../../../types/menu';
import PageHeader from '../../../common/Header/PageHeader';
import './Reservation.css';
import CreateReservationModal from './CreateReservationModal';

export const Reservation = () => {
    const [showModal, setShowModal] = useState(false);
    const [createReservationShowModal, setCreateReservationShowModal] = useState(false);

    // State for actual columns display
    const [columns, setColumns] = useState({
        Name: true,
        lastName: true,
        code: true,
        reservationID: true,
    });

    // Temporary state for modal
    const [tempColumns, setTempColumns] = useState(columns);

    const handleCloseCreateReservationShowModal = () => {
        setCreateReservationShowModal(false);
    };

    const handleShowCreateReservationShowModal = () => {
        setCreateReservationShowModal(true);
    };

    const pageHeader: PageHeaderType = {
        title: 'Reservation Category',
        prevNavLabel: 'Dashboard',
        prevNavLink: '/client/landing',
        iconClass: 'fa-solid fa-ticket',
        modal: {
            showModalHandler: handleShowCreateReservationShowModal,
        },
        createPageType: 'modal',
    };

    const handleConfigureColumnsClick = () => {
        setTempColumns(columns); // Sync tempColumns with current columns state
        setShowModal(true);
    };

    const handleClose = () => {
        setShowModal(false);
    };

    // Function to handle checkbox changes in modal
    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { id, checked } = event.target;
        setTempColumns((prevState) => ({
            ...prevState,
            [id]: checked,
        }));
    };

    // Function to save changes
    const handleSave = () => {
        setColumns(tempColumns);
        handleClose();
    };

    return (
        <div className="container-fluid">
            <PageHeader {...pageHeader} />
            <CreateReservationModal
                showModal={createReservationShowModal}
                handleClose={handleCloseCreateReservationShowModal}
            />
            <div className="main-container">
                <div className="configure-columns" onClick={handleConfigureColumnsClick}>
                    <i className="fas fa-cogs configure-icon" />
                    <span>Configure Columns</span>
                </div>
                <div className="employee-container">
                    <table className="employee-table">
                        <thead>
                            <tr>
                                {columns.Name && (
                                    <th>
                                        Name
                                        <i className="fas fa-sort ml-2" />
                                    </th>
                                )}
                                {columns.code && (
                                    <th>
                                        Code
                                        <i className="fas fa-sort ml-2" />
                                    </th>
                                )}
                                {columns.reservationID && (
                                    <th>
                                        Reservation Category ID
                                        <i className="fas fa-sort ml-2" />
                                    </th>
                                )}
                            </tr>
                        </thead>
                        <tbody>
                            {/* Your table rows */}
                            <tr>
                                {columns.Name && (
                                    <td>
                                        <Link to="/client/backoffice-manager/employee/employee-detail">Regular</Link>
                                    </td>
                                )}

                                {columns.code && (
                                    <td>
                                        <Link to="/client/backoffice-manager/employee/employee-detail">R123</Link>
                                    </td>
                                )}
                                {columns.reservationID && (
                                    <td>
                                        <Link to="/client/backoffice-manager/employee/employee-detail">669898</Link>
                                    </td>
                                )}
                            </tr>
                            <tr>
                                {columns.Name && (
                                    <td>
                                        <Link to="/client/backoffice-manager/employee/employee-detail">Pre Booking</Link>
                                    </td>
                                )}

                                {columns.code && (
                                    <td>
                                        <Link to="/client/backoffice-manager/employee/employee-detail">PB124</Link>
                                    </td>
                                )}
                                {columns.reservationID && (
                                    <td>
                                        <Link to="/client/backoffice-manager/employee/employee-detail">82272</Link>
                                    </td>
                                )}
                            </tr>
                            <tr>
                                {columns.Name && (
                                    <td>
                                        <Link to="/client/backoffice-manager/employee/employee-detail">Tool Operator</Link>
                                    </td>
                                )}

                                {columns.code && (
                                    <td>
                                        <Link to="/client/backoffice-manager/employee/employee-detail">TO125</Link>
                                    </td>
                                )}
                                {columns.reservationID && (
                                    <td>
                                        <Link to="/client/backoffice-manager/employee/employee-detail">98622</Link>
                                    </td>
                                )}
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <Modal show={showModal} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Configure Columns</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Check
                            type="checkbox"
                            id="Name"
                            label="Name"
                            checked={tempColumns.Name}
                            onChange={handleCheckboxChange}
                        />
                        <Form.Check
                            type="checkbox"
                            id="code"
                            label="Code"
                            checked={tempColumns.code}
                            onChange={handleCheckboxChange}
                        />
                        <Form.Check
                            type="checkbox"
                            id="reservationID"
                            label="Reservation ID"
                            checked={tempColumns.reservationID}
                            onChange={handleCheckboxChange}
                        />
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleSave}>
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default Reservation;
