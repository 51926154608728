import React, { useState } from 'react';
import cn from 'classnames';
import { useLocation } from 'react-router-dom';
import { Accordion, useAccordionButton } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { AccordionMenuType } from '../../../types/menu';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const CustomToggle = ({ children, eventKey }: any) => {
    const [isCollapsed, setIsCollapsed] = useState(true); // State to track collapsed state

    const decoratedOnClick = useAccordionButton(eventKey, () => {
        setIsCollapsed(!isCollapsed); // Toggle collapsed state when link is clicked
    });

    return (
        <div
            aria-expanded={!isCollapsed} // Update aria-expanded attribute based on collapsed state
            data-toggle="collapse"
            className={`cursor-pointer nav-link ${isCollapsed ? 'collapsed' : ''}`} // Apply 'collapsed' class conditionally
            onClick={decoratedOnClick}
        >
            {children}
        </div>
    );
};
const Accordionlabel = (props: AccordionMenuType) => {
    const { menuLabel, faIconClass, menuLinks, menuHeader, basePath } = props;
    const menuLabelTransformed = menuLabel.replaceAll(' ', '-');
    const location = useLocation();
    const locationPaths = location.pathname.split('/');

    const currentPath1 = locationPaths.length > 1 ? location.pathname.split('/')[1].toLowerCase() : null;
    const currentPath2 = locationPaths.length > 2 ? location.pathname.split('/')[2].toLowerCase() : null;
    const isActive =
        currentPath1 === menuLabelTransformed.toLowerCase() || currentPath2 === menuLabelTransformed.toLowerCase()
            ? true
            : false;
    const base = basePath ? basePath : '';
    return (
        <Accordion className="accordion-menu">
            <Accordion.Item
                className={cn('nav-item', {
                    active: isActive,
                })}
                eventKey={menuLabelTransformed}
            >
                <CustomToggle eventKey={menuLabelTransformed}>
                    <i className={`fas fa-fw ${faIconClass}`} /> <span>{menuLabel}</span>
                </CustomToggle>
                <Accordion.Collapse eventKey={menuLabelTransformed}>
                    <div aria-labelledby={menuLabel}>
                        <div className="bg-white py-2 collapse-inner rounded">
                            {menuHeader && <h6 className="collapse-header">{menuHeader}</h6>}
                            {menuLinks.map((menu) => (
                                <NavLink
                                    key={menu.label}
                                    className="collapse-item"
                                    to={`${base}/${menuLabelTransformed.toLowerCase()}${menu.path.toLowerCase()}`}
                                >
                                    <span>{menu.label}</span>
                                </NavLink>
                            ))}
                        </div>
                    </div>
                </Accordion.Collapse>
            </Accordion.Item>
        </Accordion>
    );
};

export default Accordionlabel;
