/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { IRootState } from '../../../store';
import { validateRegisterationLink } from '../../../services/authentication';
import { useAuth } from '../../../hooks/useAuth';

const validationSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email address').required('Email address is required'),
    password: Yup.string().required('Password is required'),
});

export const RegisterBackofficeUser = () => {
    const params = useParams();
    const { onSignup } = useAuth();
    const { key, token } = params;
    const globalError = useSelector((state: IRootState) => state.error);
    const [validating, setValidating] = useState(true);
    const [isValid, setIsValid] = useState<boolean | null>(null);
    const [submitting, setSubmitting] = useState(false);
    const [clientName, setClientName] = useState('');
    const formik = useFormik({
        initialValues: {
            email: '',
            phone: '',
            password: '',
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            setSubmitting(true);
            if (token && key) {
                const creds = {
                    username: values.email,
                    password: values.password,
                    token: token,
                    key: key,
                };
                onSignup(creds);
            }
            setSubmitting(false);
        },
    });

    useEffect(() => {
        const validateLink = async () => {
            setValidating(true);
            if (key && token) {
                const response = await validateRegisterationLink(key, token);
                if (response.isValid) {
                    setIsValid(response.isValid);
                    setClientName(response.name);
                } else {
                    setIsValid(false);
                }
                setValidating(false);
            }
            return false;
        };
        validateLink();
    }, [key, token]);

    return (
        <div className="container-fluid bg-gradient-primary vh-100">
            {/* <!-- Outer Row --> */}
            <div className="row justify-content-center">
                <div className="col-xl-10 col-lg-12 col-md-9">
                    <div className="card o-hidden border-0 shadow-lg my-5">
                        <div className="card-body p-0">
                            {/* <!-- Nested Row within Card Body --> */}
                            <div className="row login-form">
                                <div className="col-lg-6  d-lg-block ">
                                    <div className="p-3 p-md-5">
                                        <div className="text-left">
                                            <h1 className="h4 text-gray-900 mb-4">Property Management System</h1>{' '}
                                            <p>
                                                <strong>
                                                    Manage Rental Homes and Vacation Stays Effortlessly Our SaaS platform
                                                    simplifies property management by providing an end-to-end solution.
                                                </strong>
                                            </p>
                                            <ul>
                                                <li>Streamlined management of rental properties and vacation stays</li>
                                                <li>Full access to the system for seamless control over client profiles</li>
                                                <li>Ability to create and manage user accounts with ease</li>
                                            </ul>
                                            <p>
                                                Ready to get started? Fill out the form on the right to create your initial
                                                user account and begin managing your properties efficiently.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="p-3 p-md-5 mt-md-4">
                                        <div className="text-left">
                                            <h1 className="h4 text-gray-900 mb-4">
                                                <i className="fa fa-lock text-custom-1 me-2" />
                                                Login Now
                                            </h1>{' '}
                                        </div>
                                        {validating && (
                                            <div className="row mt-2">
                                                <div className="col-sm-12 mt-2 mb-2 text-center bg-light p-2 valign-middle">
                                                    Hold on while we validate your signup link
                                                    <i className="fa-solid fa-circle-notch fa-spin ms-2" />
                                                </div>
                                            </div>
                                        )}
                                        {isValid === false && (
                                            <div className="col-lg-12 alert alert-danger">
                                                The signup link is either used, expired or incorrect. <br /> Please reach out
                                                to support team for the correct signup link.
                                            </div>
                                        )}
                                        {isValid && !validating && (
                                            <>
                                                <form className="user" onSubmit={formik.handleSubmit}>
                                                    <p className="text-gray-700 px-2">{clientName}</p>
                                                    <div className="form-group">
                                                        <input
                                                            type="text"
                                                            name="email"
                                                            className="form-control form-control-user"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Email Address"
                                                            autoComplete="off"
                                                            value={formik.values.email}
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                        />
                                                        {formik.touched.email && formik.errors.email ? (
                                                            <div className="small text-danger ps-2">
                                                                {formik.errors.email}
                                                            </div>
                                                        ) : null}
                                                    </div>
                                                    <div className="form-group">
                                                        <input
                                                            type="password"
                                                            name="password"
                                                            className="form-control form-control-user"
                                                            placeholder="Password"
                                                            autoComplete="false"
                                                            value={formik.values.password}
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                        />
                                                        {formik.touched.password && formik.errors.password ? (
                                                            <div className="small text-danger ps-2">
                                                                {formik.errors.password}
                                                            </div>
                                                        ) : null}
                                                    </div>
                                                    <button
                                                        type="submit"
                                                        className="btn btn-primary btn-user btn-block"
                                                        disabled={submitting}
                                                    >
                                                        Sign In
                                                        {submitting && !globalError.isError && (
                                                            <i className="fa-solid fa-circle-notch fa-spin ms-2" />
                                                        )}
                                                    </button>
                                                </form>
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
