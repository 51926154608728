// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.row {
   display: flex;
   flex-wrap: wrap;
   justify-content: space-around;
   color: black;
}



.labels {
    font-weight: bold;
    margin-bottom: 0.5rem;
}

.form-control-Currentaddress {
    display: block;
    width: 100%;
    padding-top:0.375rem ;
    padding-right:0.75rem;
    padding-bottom:3.375rem;
    padding-left:0.75rem;
    border-radius: 5px;
    border: solid #dee2e6 ;
}

.form-control-Permanentaddress {
    display: block;
    width: 100%;
    padding-top:0.375rem ;
    padding-right:0.75rem;
    padding-bottom:3.375rem;
    padding-left:0.75rem;
    border-radius: 5px;
    border: solid #dee2e6 ;
}

.fa-id-card{
    font-size: 20px;
    padding-right: 300px;
    padding-bottom: 7px;
}

.fa-phone {
    font-size: 20px;
    padding-right: 930px;
    padding-bottom: 7px;
}

.fa-map-marker {
    font-size: 20px;
    padding-right: 960px;
    padding-bottom: 7px;
}
`, "",{"version":3,"sources":["webpack://./src/components/common/UserProfile/ViewUserProfile.css"],"names":[],"mappings":"AAAA;GACG,aAAa;GACb,eAAe;GACf,6BAA6B;GAC7B,YAAY;AACf;;;;AAIA;IACI,iBAAiB;IACjB,qBAAqB;AACzB;;AAEA;IACI,cAAc;IACd,WAAW;IACX,qBAAqB;IACrB,qBAAqB;IACrB,uBAAuB;IACvB,oBAAoB;IACpB,kBAAkB;IAClB,sBAAsB;AAC1B;;AAEA;IACI,cAAc;IACd,WAAW;IACX,qBAAqB;IACrB,qBAAqB;IACrB,uBAAuB;IACvB,oBAAoB;IACpB,kBAAkB;IAClB,sBAAsB;AAC1B;;AAEA;IACI,eAAe;IACf,oBAAoB;IACpB,mBAAmB;AACvB;;AAEA;IACI,eAAe;IACf,oBAAoB;IACpB,mBAAmB;AACvB;;AAEA;IACI,eAAe;IACf,oBAAoB;IACpB,mBAAmB;AACvB","sourcesContent":[".row {\r\n   display: flex;\r\n   flex-wrap: wrap;\r\n   justify-content: space-around;\r\n   color: black;\r\n}\r\n\r\n\r\n\r\n.labels {\r\n    font-weight: bold;\r\n    margin-bottom: 0.5rem;\r\n}\r\n\r\n.form-control-Currentaddress {\r\n    display: block;\r\n    width: 100%;\r\n    padding-top:0.375rem ;\r\n    padding-right:0.75rem;\r\n    padding-bottom:3.375rem;\r\n    padding-left:0.75rem;\r\n    border-radius: 5px;\r\n    border: solid #dee2e6 ;\r\n}\r\n\r\n.form-control-Permanentaddress {\r\n    display: block;\r\n    width: 100%;\r\n    padding-top:0.375rem ;\r\n    padding-right:0.75rem;\r\n    padding-bottom:3.375rem;\r\n    padding-left:0.75rem;\r\n    border-radius: 5px;\r\n    border: solid #dee2e6 ;\r\n}\r\n\r\n.fa-id-card{\r\n    font-size: 20px;\r\n    padding-right: 300px;\r\n    padding-bottom: 7px;\r\n}\r\n\r\n.fa-phone {\r\n    font-size: 20px;\r\n    padding-right: 930px;\r\n    padding-bottom: 7px;\r\n}\r\n\r\n.fa-map-marker {\r\n    font-size: 20px;\r\n    padding-right: 960px;\r\n    padding-bottom: 7px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
