import React from 'react';
import { NavLink } from 'react-router-dom';
import Footer from '../../common/Footer/Footer';
import './LandingPage.scss';

const ClientLandingPage = () => {
    const modules = [
        { icon: 'fas fa-boxes', label: 'Inventory Manager', path: '/client/inventory-manager/location' },
        { icon: 'fas fa-sitemap', label: 'Channel Manager', path: '/client/channel-manager/distribution-channel' },
        { icon: 'fas fa-briefcase', label: 'Backoffice Manager', path: '/client/backoffice-manager/employee' },
        { icon: 'fa-solid fa-percent', label: 'Rate Manager', path: '/client/rate-manager/ratetype' },
        {
            icon: 'fas fa-sliders-h',
            label: 'Embed Square Setup/Feature Controller',
            path: '/client/feature-controller/feature-flag',
        },
        { icon: 'fas fa-calendar-check', label: 'Reservation Manager', path: '/client/reservation-manager/dashboard' },
        { icon: 'fas fa-tasks', label: 'Operations Manager', path: '/client/operations-manager' },
        { icon: 'fas fa-dollar-sign', label: 'Finance Manager', path: '/client/finance-manager' },
        { icon: 'fas fa-concierge-bell', label: 'Front Desk Manager', path: '/client/frontdesk-manager' },
        { icon: 'fas fa-plug', label: 'Integration Manager', path: '/client/integration-manager' },
        { icon: 'fas fa-bell', label: 'Notification Manager', path: '/client/notification-manager' },
        { icon: 'fas fa-user-shield', label: 'Owner Manager', path: '/client/owner-manager/owner' },
        { icon: 'fas fa-users', label: 'Customer Manager', path: '/client/customer-manager/customer' },
        { icon: 'fas fa-laptop-code', label: 'Website Builder', path: '/client/website-manager' },
    ];

    const sortedModules = modules.sort((a, b) => a.label.localeCompare(b.label));

    return (
        <>
            <div className="container-fluid vh-85 d-flex justify-content-center align-items-center client-landing">
                <div className="row w-100 justify-content-center">
                    {sortedModules.map((module, index) => (
                        <div
                            key={index}
                            className="module-block p-2 m-2 col-6 col-md-2 text-white d-flex flex-column justify-content-center align-items-center"
                        >
                            <NavLink
                                to={module.path}
                                className="text-white text-center d-flex flex-column justify-content-center align-items-center w-100 h-100"
                            >
                                <i className={`${module.icon} fa-2x mb-2`} />
                                <span className="fs-6">{module.label}</span>
                            </NavLink>
                        </div>
                    ))}
                </div>
            </div>

            <Footer />
        </>
    );
};

export default ClientLandingPage;
