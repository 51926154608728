const Menu = {
    menuLabel: 'Reservation Manager',
    menus: [
        {
            label: 'DashBoard',
            path: '/client/reservation-manager/dashboard',
            faIconClass: 'fa-solid fa-chart-column',
        },
        {
            label: 'Search Reservation',
            path: '/client/reservation-manager/Search-reservation',
            faIconClass: 'fa-solid fa-magnifying-glass',
        },
    ],
};

export default Menu;
