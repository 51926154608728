import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';
import { IRootState } from '../../store';

export const UserLogin = () => {
    const { onLogin } = useAuth();
    const globalError = useSelector((state: IRootState) => state.error);
    const [error, setError] = useState<string>('');
    const [loading, setLoading] = useState(false);
    const [username, setUsername] = useState<string>('');
    const [password, setPassword] = useState<string>('');

    const handleChangeUsername = (event: React.ChangeEvent<HTMLInputElement>) => {
        setLoading(false);
        setUsername(event.currentTarget.value);
    };

    const handleChangePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
        setLoading(false);
        // 👇 Get input value from "event"
        setPassword(event.currentTarget.value);
    };

    const onClickLogin = () => {
        setLoading(true);
        if (username === '' || password === '') {
            setError(`Invalid username or password`);
        } else {
            setError('');
            onLogin({ username, password });
        }
        setLoading(false);
    };
    const handleKeyPress = (event: React.KeyboardEvent<Element>) => {
        if (event.key === 'Enter') {
            onClickLogin();
        }
    };

    return (
        <div className="container-fluid bg-gradient-primary vh-100">
            {/* <!-- Outer Row --> */}
            <div className="row justify-content-center">
                <div className="col-xl-10 col-lg-12 col-md-9">
                    <div className="card o-hidden border-0 shadow-lg my-5">
                        <div className="card-body p-0">
                            {/* <!-- Nested Row within Card Body --> */}
                            <div className="row login-form">
                                <div className="col-lg-6 d-none d-lg-block bg-login-image" />
                                <div className="col-lg-6">
                                    <div className="p-5">
                                        <div className="text-center">
                                            <h1 className="h4 text-gray-900 mb-4">Welcome Back!</h1>{' '}
                                            {error && <p className="error">{error}</p>}
                                            {globalError.isError && <p className="error">{globalError.message}</p>}
                                        </div>
                                        <form className="user">
                                            <div className="form-group">
                                                <input
                                                    type="email"
                                                    className="form-control form-control-user"
                                                    aria-describedby="emailHelp"
                                                    placeholder="Enter Email Address..."
                                                    value={username}
                                                    autoComplete="off"
                                                    onChange={handleChangeUsername}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <input
                                                    type="password"
                                                    className="form-control form-control-user"
                                                    placeholder="Password"
                                                    value={password}
                                                    onChange={handleChangePassword}
                                                    autoComplete="false"
                                                    onKeyDown={handleKeyPress}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <div className="custom-control custom-checkbox small">
                                                    <input
                                                        type="checkbox"
                                                        className="custom-control-input"
                                                        id="customCheck"
                                                    />
                                                    <label className="custom-control-label" htmlFor="customCheck">
                                                        Remember Me
                                                    </label>
                                                </div>
                                            </div>
                                            <button
                                                type="button"
                                                className="btn btn-primary btn-user btn-block"
                                                onClick={onClickLogin}
                                            >
                                                Sign in
                                                {loading && !globalError.isError && (
                                                    <div
                                                        className="spinner-border spinner-border-sm me-2 mx-2"
                                                        role="status"
                                                    >
                                                        <span className="visually-hidden">Loading...</span>
                                                    </div>
                                                )}
                                            </button>
                                        </form>
                                        <hr />
                                        <div className="text-center">
                                            <NavLink className="small" to="/forgot-password">
                                                Forgot Password?
                                            </NavLink>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
