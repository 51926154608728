import React, { useRef, useState } from 'react';
import { Button, Dropdown, Form, Modal, Row } from 'react-bootstrap';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import './RateAccomodationDetail.css';

const RateAccomodationDetail: React.FC = () => {
    const cardARef = useRef<HTMLDivElement>(null);
    const cardBRef = useRef<HTMLDivElement>(null);
    const cardCRef = useRef<HTMLDivElement>(null);
    const cardDRef = useRef<HTMLDivElement>(null);

    const scrollToCard = (ref: React.RefObject<HTMLDivElement>) => {
        if (ref.current) {
            const topOffset = ref.current.getBoundingClientRect().top + window.pageYOffset - 140; // Adjust '100' as needed for header height or padding
            window.scrollTo({
                top: topOffset,
                behavior: 'smooth',
            });
        }
    };

    const [key, setKey] = useState<string>('rentability');

    // Main state for reflecting saved selection
    const [isBookable, setIsBookable] = useState(false);
    const [isVisible, setIsVisible] = useState(false);

    // Temporary selection state before saving
    const [tempBookable, setTempBookable] = useState(false);
    const [tempVisible, setTempVisible] = useState(false);

    // Modal function to edit Renetability Rule//
    const [showRowModal, setShowRowModal] = useState(false); // new state for row detail modal
    const [showAddRuleModal, setShowAddRuleModal] = useState(false); // new state for Add rentability rule modal
    const [showLinkSetModal, setShowLinkSetModal] = useState(false); // New state for Link rentability set modal

    const handleRowShow = () => {
        setShowRowModal(true);
    };

    const handleRowClose = () => {
        setShowRowModal(false);
    };

    const handleAddRuleShow = () => {
        setShowRowModal(false);
        setShowAddRuleModal(true);
    };

    const handleAddRuleClose = () => {
        setShowAddRuleModal(false);
        setShowRowModal(true); // Reopen the edit modal after closing the Add Rule modal
    };

    const handleSaveAddRule = () => {
        handleAddRuleClose(); // After saving, close Add Rule modal and reopen the edit modal
    };

    const handleLinkSetShow = () => {
        setShowRowModal(false);
        setShowLinkSetModal(true); // Open the Link Rentability Set modal
    };

    const handleLinkSetClose = () => {
        setShowLinkSetModal(false);
        setShowRowModal(true); // Reopen the edit modal after closing the Link Set modal
    };

    const handleSaveLinkSet = () => {
        handleLinkSetClose(); // After saving, close Link Set modal and reopen the edit modal
    };

    //Modal function to edit rentability rule//
    const [showEditModal, setShowEditModal] = useState(false);

    const handleEditClose = () => {
        setShowRowModal(true);
        setShowEditModal(false);
    };

    const handleEditShow = () => {
        setShowRowModal(false);
        setShowEditModal(true);
    };

    const handleEditSave = () => {
        handleEditClose();
    };

    // Modal function to link distribution channel
    const [showLinkChannelModal, setShowLinkChannelModal] = useState(false);
    const [isCheckboxSelected, setIsCheckboxSelected] = useState(false);
    const [step, setStep] = useState(1); // Track the current step

    // Toggle checkbox selection
    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsCheckboxSelected(event.target.checked);
    };

    // Move to the next step
    const nextStep = () => {
        setStep(step + 1);
    };

    // Go to the previous step
    const prevStep = () => {
        if (step > 1) {
            setStep(step - 1);
        }
    };
    const handleLinkChannelShow = () => {
        // Close edit representation card row modal and open Link Distribution Channel modal
        setShowRowModal(false);
        setShowLinkChannelModal(true);
    };

    const handleLinkChannelClose = () => {
        // Close the Link Distribution Channel modal and re-open edit representation card row modal
        setShowLinkChannelModal(false);
        setShowRowModal(true);
    };

    const handleLinkChannelSave = () => {
        handleLinkChannelClose(); // After saving, close Link Set modal and reopen the edit modal
    };

    // Modal function to All linked distribution channel
    const [showAllLinkChannelModal, setShowAllLinkChannelModal] = useState(false);

    const handleAllLinkChannelShow = () => {
        // Close edit representation card row modal and open Link Distribution Channel modal
        setShowRowModal(false);
        setShowAllLinkChannelModal(true);
    };

    const handleAllLinkChannelClose = () => {
        // Close the Link Distribution Channel modal and re-open edit representation card row modal
        setShowAllLinkChannelModal(false);
        setShowRowModal(true);
    };

    const handleAllLinkChannelSave = () => {
        handleAllLinkChannelClose(); // After saving, close Link Set modal and reopen the edit modal
    };

    // Modal function to create Renatability rule//
    const [showModal, setShowModal] = useState(false);

    const handleClose = () => {
        setShowModal(false);
    };

    const handleShow = () => {
        setShowModal(true);
    };

    const handleSave = () => {
        handleClose();
        // Save selected statuses to main state
        setIsBookable(tempBookable);
        setIsVisible(tempVisible);
    };

    /* function of Arival days and departure days*/
    const [selectedArrivalDays, setSelectedArrivalDays] = useState<string[]>([]);
    const [selectedDepartureDays, setSelectedDepartureDays] = useState<string[]>([]);

    const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

    const handleArrivalCheckboxChange = (day: string) => {
        setSelectedArrivalDays(
            (prevSelected) =>
                prevSelected.includes(day)
                    ? prevSelected.filter((d) => d !== day) // Remove if already selected
                    : [...prevSelected, day] // Add if not selected
        );
    };

    const handleDepartureCheckboxChange = (day: string) => {
        setSelectedDepartureDays(
            (prevSelected) =>
                prevSelected.includes(day)
                    ? prevSelected.filter((d) => d !== day) // Remove if already selected
                    : [...prevSelected, day] // Add if not selected
        );
    };

    // Function to handle "Select All" for arrival days
    const handleSelectAllArrivalDays = () => {
        setSelectedArrivalDays(days); // Clear all selected arrival days
    };

    // Function to handle "Select All" for departure days
    const handleSelectAllDepartureDays = () => {
        setSelectedDepartureDays(days); // Clear all selected departure days
    };

    // Function to handle "Deselect All" for arrival days
    const handleDeselectAllArrivalDays = () => {
        setSelectedArrivalDays([]); // Clear all selected arrival days
    };

    // Function to handle "Deselect All" for departure days
    const handleDeselectAllDepartureDays = () => {
        setSelectedDepartureDays([]); // Clear all selected departure days
    };

    // Create a display value for the dropdown based on selected days
    const displayValue = selectedArrivalDays.length > 0 ? selectedArrivalDays.join(', ') : 'Select Arrival Days';
    const departureDisplayValue =
        selectedDepartureDays.length > 0 ? selectedDepartureDays.join(', ') : 'Select Departure Days';

    return (
        <div className="container">
            {/* Sticky Card Name Container */}
            <div className="cardname-container">
                <div className="name-container row">
                    <h4>
                        <strong>Building A</strong>
                    </h4>
                </div>
                <div className="col-12 pl-4 pt-3 card-container">
                    <div onClick={() => scrollToCard(cardARef)}>
                        <strong>General Info</strong>
                    </div>
                    <div onClick={() => scrollToCard(cardBRef)}>
                        <strong>Representations</strong>
                    </div>
                    <div onClick={() => scrollToCard(cardCRef)}>
                        <strong>Card C</strong>
                    </div>
                    <div onClick={() => scrollToCard(cardDRef)}>
                        <strong>Card D</strong>
                    </div>
                </div>
            </div>

            <div className="card-detail">
                {/* Basic Card-1*/}
                <div ref={cardARef} className="p-4">
                    {''}
                </div>
                <div className="card shadow mb-4">
                    <div className="card-header py-4">
                        <h6 className="m-0 font-weight-bold text-black">
                            <strong>General Info</strong>
                        </h6>
                    </div>
                    <div className="details-container">
                        <div className="col-12">
                            <div className="card-body">
                                <div className="info-row">
                                    <p>
                                        <strong>Accomodation Name:</strong> Building A
                                    </p>
                                    <p>
                                        <strong>Accomodation Code: </strong> A001
                                    </p>
                                    <p>
                                        <strong>Accomodation ID: </strong> 35368
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Basic Card-2*/}
                <div ref={cardBRef} className="card shadow mb-4">
                    <div className="card-header py-3 d-flex justify-content-between align-items-center">
                        <h6 className="m-0 font-weight-bold text-black">
                            <strong>Representations</strong>
                        </h6>
                    </div>
                    <div className="card-body">
                        <div className="row">
                            <div className="col-6">
                                <Form.Group controlId="formEmployeeCategory">
                                    <Form.Label>
                                        <strong>Distribution Channel</strong>
                                    </Form.Label>
                                    <div className="dropdown-with-caret">
                                        <Form.Control as="select">
                                            <option>All</option>
                                            <option>Booking.com</option>
                                            <option>Ticket.com</option>
                                            <option>Trip.com</option>
                                            <option>Hotelbeds.com</option>
                                            <option>Traveloka.com</option>
                                        </Form.Control>
                                        <span className="caret" />
                                    </div>
                                </Form.Group>
                            </div>
                            <div className="col-6 mt-4 text-end">
                                <i className="fa fa-history" aria-hidden="true" />
                                <button className="btn btn-sm mb-1">History</button>
                                <input type="checkbox" className="ml-2" id="showArchived" /> Show archived
                            </div>
                        </div>
                        <hr />
                        <div className="table-responsive">
                            <table className="employee-table">
                                <thead>
                                    <tr>
                                        <th>
                                            Name
                                            <i className="fas fa-sort ml-2" />
                                        </th>
                                        <th>
                                            Internal name
                                            <i className="fas fa-sort ml-2" />
                                        </th>
                                        <th>
                                            Linked rentability / rentability sets
                                            <i className="fas fa-sort ml-2" />
                                        </th>
                                        <th>
                                            DC/DC group linking
                                            <i className="fas fa-sort ml-2" />
                                        </th>
                                        <th>
                                            Status
                                            <i className="fas fa-sort ml-2" />
                                        </th>
                                        <th />
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr onClick={handleRowShow}>
                                        <td>Cottage 4</td>
                                        <td>-</td>
                                        <td>Rentability set(s) linked</td>
                                        <td>Individual DC(s) linked</td>
                                        <td>
                                            <span
                                                className={`badge ${isBookable ? 'badge-success' : ''}`}
                                                style={{ marginRight: '10px' }}
                                            >
                                                {isBookable ? 'Bookable' : ''}
                                            </span>
                                            <span className={`badge ${isVisible ? 'badge-success' : ''}`}>
                                                {isVisible ? 'Visible' : ''}
                                            </span>
                                        </td>

                                        <td>
                                            <a href="#" className="text-danger">
                                                <strong>Archive</strong>
                                            </a>
                                        </td>
                                    </tr>
                                    {/* Modal to edit representation card row*/}
                                    <Modal
                                        show={showRowModal}
                                        onHide={handleRowClose}
                                        size="xl"
                                        backdrop="static" // Disable clicking outside to close
                                        keyboard={false} // Disable pressing 'Esc' to close
                                    >
                                        <Modal.Header style={{ color: 'black', background: '#fff' }} closeButton>
                                            <Modal.Title>
                                                <strong>Cottage 4</strong>
                                            </Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <Tabs
                                                activeKey={key}
                                                onSelect={(k: string | null) => k && setKey(k)}
                                                id="rentability"
                                                className="mb-3"
                                            >
                                                <Tab eventKey="rentability" title="Rentability">
                                                    <div style={{ height: '500px', overflow: 'auto' }}>
                                                        <div className="d-flex pt-3 text-black">
                                                            <div>
                                                                <label>
                                                                    <strong>Stay date from - to and including</strong>
                                                                </label>
                                                                <div className="d-flex align-items-center">
                                                                    <input type="date" className="form-control" />
                                                                    <i className="fa-solid fa-arrow-right mx-2" />
                                                                    <input type="date" className="form-control" />
                                                                </div>
                                                            </div>
                                                            <div className="ml-5">
                                                                <label>
                                                                    <strong>Book date from - to</strong>
                                                                </label>
                                                                <div className="d-flex align-items-center">
                                                                    <input type="date" className="form-control" />
                                                                    <i className="fa-solid fa-arrow-right mx-2" />
                                                                    <input type="date" className="form-control" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {/* Basic Card-1*/}
                                                        <div className="card mt-3">
                                                            <div className="card-header py-3">
                                                                <h6 className="m-0 font-weight-bold text-black">
                                                                    <strong>Rentability Sets</strong>
                                                                </h6>
                                                            </div>
                                                            <div className="card-body">
                                                                <div className="employee-container">
                                                                    <table className="employee-table mt-4">
                                                                        <thead>
                                                                            <tr>
                                                                                <th>Name</th>
                                                                                <th>Code</th>
                                                                                <th>Stay date from</th>
                                                                                <th>Stay date to (including)</th>
                                                                                <th>Book date from</th>
                                                                                <th>Book date to</th>
                                                                                <th />
                                                                            </tr>
                                                                        </thead>

                                                                        <tbody>
                                                                            <tr>
                                                                                <td>
                                                                                    <a
                                                                                        // eslint-disable-next-line max-len
                                                                                        href="https://bijbnx.pms-dev.embeds2tech.com/client/rate-manager/rentabilityset/rentabilityset-detail"
                                                                                        target="_blank"
                                                                                        rel="noopener noreferrer"
                                                                                        style={{
                                                                                            color: 'blue',
                                                                                        }}
                                                                                    >
                                                                                        Royal Retreat
                                                                                    </a>
                                                                                </td>

                                                                                <td>RR31</td>
                                                                                <td>Fri 01/01.2021</td>
                                                                                <td>Sun 03/11/2024</td>
                                                                                <td>Tue 28/12/2021</td>
                                                                                <td>Sun 03/11/2024</td>

                                                                                <td>
                                                                                    <a href="#" className="text-danger">
                                                                                        <strong>Remove</strong>
                                                                                    </a>
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                                <br />
                                                            </div>
                                                        </div>
                                                        {/* Basic Card-2*/}
                                                        <div className="card mt-4">
                                                            <div className="card-header py-3">
                                                                <h6 className="m-0 font-weight-bold text-black">
                                                                    <strong>Rentability rules</strong>
                                                                </h6>
                                                            </div>
                                                            <div className="card-body">
                                                                <div className="employee-container">
                                                                    <table className="employee-table mt-4">
                                                                        <thead>
                                                                            <tr>
                                                                                <th>Stay date from</th>
                                                                                <th>Stay date to</th>
                                                                                <th>Length of stay</th>
                                                                                <th>Deviation</th>
                                                                                <th>Gap between two bookings</th>
                                                                                <th>Book dates (UTC +05:30)</th>
                                                                                <th>Days of arrival/departure</th>
                                                                                <th>Actions</th>
                                                                            </tr>
                                                                        </thead>

                                                                        <tbody>
                                                                            <tr>
                                                                                <td>Fri 24/05/2024</td>
                                                                                <td>Mon 06/01/2025</td>
                                                                                <td>Min: 3</td>
                                                                                <td>-</td>
                                                                                <td>-</td>
                                                                                <td>
                                                                                    Sun 01/01/2023,
                                                                                    <br />
                                                                                    04:30
                                                                                </td>
                                                                                <td>
                                                                                    Arr. days: Mon, Fri
                                                                                    <br />
                                                                                    Dep. days: Mon, Fri
                                                                                </td>
                                                                                <td>
                                                                                    <i
                                                                                        className="fa-solid fa-pencil pl-2"
                                                                                        onClick={handleEditShow}
                                                                                        style={{
                                                                                            cursor: 'pointer',
                                                                                            color: 'primary',
                                                                                        }}
                                                                                    />
                                                                                    <i
                                                                                        className="fa-solid fa-ban pl-2"
                                                                                        style={{
                                                                                            cursor: 'pointer',
                                                                                            color: 'primary',
                                                                                        }}
                                                                                    />
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                                <br />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <Modal.Footer>
                                                        <Button variant="secondary" onClick={handleAddRuleShow}>
                                                            Add rentability rule
                                                        </Button>
                                                        <Button variant="primary" onClick={handleLinkSetShow}>
                                                            Link rentability
                                                        </Button>
                                                    </Modal.Footer>
                                                </Tab>
                                                {/* Tab 2 COntent*/}
                                                <Tab
                                                    eventKey="linkeddistributionchannel"
                                                    title="Linked Distribution Channel"
                                                >
                                                    <div className="showlinkedchannel-btn text-end">
                                                        <Button variant="primary" onClick={handleAllLinkChannelShow}>
                                                            Show all linked channel
                                                        </Button>
                                                    </div>

                                                    <div className="title-name text-black mt-4 ml-2">
                                                        <h5>
                                                            <strong>Individual linked distribution channels</strong>
                                                        </h5>
                                                    </div>

                                                    <div className="row">
                                                        <div className="col-10">
                                                            <form className="search-form" style={{ width: '100%' }}>
                                                                <input
                                                                    type="text"
                                                                    placeholder="Search for distribution channels with name, code..."
                                                                    className="search-input"
                                                                />
                                                                <button type="submit" className="search-button">
                                                                    <i className="fas fa-search" />
                                                                </button>
                                                            </form>
                                                        </div>
                                                        <div className="filter-component col-2">
                                                            <p>
                                                                <strong>
                                                                    <i className="fa fa-filter" /> Advance Filters
                                                                </strong>
                                                            </p>
                                                        </div>

                                                        <div className="distribution-title text-black ml-2 mt-5">
                                                            <h6>Distribution Channel linked to a representation</h6>
                                                        </div>

                                                        <div className="employee-container ml-2">
                                                            <table className="employee-table">
                                                                <thead>
                                                                    <tr>
                                                                        <th>Name</th>
                                                                        <th>Code</th>
                                                                        <th>Path</th>
                                                                        <th>Start book date </th>
                                                                        <th>End book date </th>
                                                                    </tr>
                                                                </thead>

                                                                <tbody>
                                                                    <tr>
                                                                        <td>Booking.com</td>
                                                                        <td>B111</td>
                                                                        <td>
                                                                            http://c21zsn.localhost:3000/client/channel-manager/distribution-channel/distribution-detail
                                                                        </td>
                                                                        <td>Sun 3/11/2024</td>
                                                                        <td>Tue 28/12/2021</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Hotelbeds.com</td>
                                                                        <td>H111</td>
                                                                        <td>
                                                                            http://c21zsn.localhost:3000/client/channel-manager/distribution-channel/distribution-detail
                                                                        </td>
                                                                        <td>Mon 5/11/2024</td>
                                                                        <td>Tue 28/12/2021</td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                    <div className="text-end mt-5">
                                                        <Button variant="primary" onClick={handleLinkChannelShow}>
                                                            Link distribution channel
                                                        </Button>
                                                    </div>
                                                </Tab>
                                            </Tabs>
                                        </Modal.Body>
                                    </Modal>
                                    {/* Modal for Edit roles */}
                                    <Modal
                                        show={showEditModal}
                                        onHide={handleEditClose}
                                        size="xl"
                                        backdrop="static"
                                        keyboard={false}
                                    >
                                        <Modal.Header style={{ color: 'black', background: '#fff' }} closeButton>
                                            <Modal.Title>
                                                <strong>Edit Rentability Rule</strong>
                                            </Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <div style={{ height: '330px', overflow: 'auto' }}>
                                                <div className="container-lg px-4 mt-4">
                                                    <Row>
                                                        <div className="card-body">
                                                            <Form>
                                                                <div className="d-flex pt-2">
                                                                    <div className="mr-5">
                                                                        <label>
                                                                            <strong>
                                                                                Stay date from - to and including
                                                                            </strong>
                                                                        </label>
                                                                        <div className="d-flex align-items-center">
                                                                            <input type="date" className="form-control" />
                                                                            <i className="fa-solid fa-arrow-right mx-2" />
                                                                            <input type="date" className="form-control" />
                                                                        </div>
                                                                        <div className="mt-2">
                                                                            <Form.Group controlId="formProduct">
                                                                                <Form.Label>
                                                                                    <strong>Arrival Days</strong>
                                                                                </Form.Label>
                                                                                <Dropdown>
                                                                                    <Dropdown.Toggle
                                                                                        variant="light"
                                                                                        id="dropdown-basic"
                                                                                        className="text-start"
                                                                                    >
                                                                                        {displayValue}
                                                                                    </Dropdown.Toggle>

                                                                                    <Dropdown.Menu>
                                                                                        <div
                                                                                            className="dropdown-item"
                                                                                            onClick={
                                                                                                handleSelectAllArrivalDays
                                                                                            }
                                                                                        >
                                                                                            <strong>Select All</strong>
                                                                                            {/* Deselect All option */}
                                                                                        </div>
                                                                                        <Dropdown.Divider />
                                                                                        {days.map((day) => (
                                                                                            <div
                                                                                                key={day}
                                                                                                className="dropdown-item"
                                                                                            >
                                                                                                <Form.Check
                                                                                                    type="checkbox"
                                                                                                    label={day}
                                                                                                    checked={selectedArrivalDays.includes(
                                                                                                        day
                                                                                                    )}
                                                                                                    onChange={() =>
                                                                                                        handleArrivalCheckboxChange(
                                                                                                            day
                                                                                                        )
                                                                                                    }
                                                                                                />
                                                                                            </div>
                                                                                        ))}
                                                                                        <Dropdown.Divider />
                                                                                        <div
                                                                                            className="dropdown-item"
                                                                                            onClick={
                                                                                                handleDeselectAllArrivalDays
                                                                                            }
                                                                                        >
                                                                                            <strong>Deselect All</strong>
                                                                                            {/* Deselect All option */}
                                                                                        </div>
                                                                                    </Dropdown.Menu>
                                                                                </Dropdown>
                                                                            </Form.Group>
                                                                        </div>
                                                                        <div className="d-flex mt-2">
                                                                            <div className="mr-2">
                                                                                <label>
                                                                                    <strong>Min. length of stay</strong>
                                                                                </label>
                                                                                <input
                                                                                    type="number"
                                                                                    className="form-control"
                                                                                />
                                                                            </div>
                                                                            <div>
                                                                                <label>
                                                                                    <strong>Max. length of stay</strong>
                                                                                </label>
                                                                                <input
                                                                                    type="number"
                                                                                    className="form-control"
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className="mt-2">
                                                                            <label>
                                                                                <strong>Gap between two bookings</strong>
                                                                            </label>
                                                                            <input type="number" className="form-control" />
                                                                        </div>
                                                                    </div>

                                                                    <div className="ml-5">
                                                                        <label>
                                                                            <strong>Book date from - to</strong>
                                                                        </label>
                                                                        <div className="d-flex align-items-center">
                                                                            <input type="date" className="form-control" />
                                                                            <i className="fa-solid fa-arrow-right mx-2" />
                                                                            <input type="date" className="form-control" />
                                                                        </div>
                                                                        <div className="mt-2">
                                                                            <Form.Group controlId="formDepartureDays">
                                                                                <Form.Label>
                                                                                    <strong>Departure Days</strong>
                                                                                </Form.Label>
                                                                                <Dropdown>
                                                                                    <Dropdown.Toggle
                                                                                        variant="light"
                                                                                        id="dropdown-departure"
                                                                                        className="text-start"
                                                                                    >
                                                                                        {departureDisplayValue}
                                                                                    </Dropdown.Toggle>

                                                                                    <Dropdown.Menu>
                                                                                        <div
                                                                                            className="dropdown-item"
                                                                                            onClick={
                                                                                                handleSelectAllDepartureDays
                                                                                            }
                                                                                        >
                                                                                            <strong>Select All</strong>
                                                                                            {/* Select All option */}
                                                                                        </div>
                                                                                        <Dropdown.Divider />
                                                                                        {days.map((day) => (
                                                                                            <div
                                                                                                key={day}
                                                                                                className="dropdown-item"
                                                                                            >
                                                                                                <Form.Check
                                                                                                    type="checkbox"
                                                                                                    label={day}
                                                                                                    checked={selectedDepartureDays.includes(
                                                                                                        day
                                                                                                    )}
                                                                                                    onChange={() =>
                                                                                                        handleDepartureCheckboxChange(
                                                                                                            day
                                                                                                        )
                                                                                                    }
                                                                                                />
                                                                                            </div>
                                                                                        ))}
                                                                                        <Dropdown.Divider />
                                                                                        <div
                                                                                            className="dropdown-item"
                                                                                            onClick={
                                                                                                handleDeselectAllDepartureDays
                                                                                            }
                                                                                        >
                                                                                            <strong>Deselect All</strong>
                                                                                            {/* Deselect All option */}
                                                                                        </div>
                                                                                    </Dropdown.Menu>
                                                                                </Dropdown>
                                                                            </Form.Group>
                                                                        </div>
                                                                        <div className="d-flex mt-2">
                                                                            <div className="mr-2">
                                                                                <label>
                                                                                    <strong>Min. deviation</strong>
                                                                                </label>
                                                                                <input
                                                                                    type="number"
                                                                                    className="form-control"
                                                                                />
                                                                            </div>
                                                                            <div>
                                                                                <label>
                                                                                    <strong>Max. deviation</strong>
                                                                                </label>
                                                                                <input
                                                                                    type="number"
                                                                                    className="form-control"
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Form>
                                                        </div>
                                                    </Row>
                                                </div>
                                            </div>
                                        </Modal.Body>
                                        <Modal.Footer>
                                            <Button variant="secondary" onClick={handleEditClose}>
                                                Close
                                            </Button>
                                            <Button variant="primary" onClick={handleEditSave}>
                                                Save
                                            </Button>
                                        </Modal.Footer>
                                    </Modal>
                                    {/* Modal to link distribution channel */}
                                    <Modal
                                        show={showAllLinkChannelModal}
                                        onHide={handleAllLinkChannelClose}
                                        size="xl"
                                        backdrop="static"
                                        keyboard={false}
                                    >
                                        <Modal.Header closeButton style={{ color: 'black', background: '#fff' }}>
                                            <Modal.Title>
                                                <strong>Linked Distribution Channel</strong>
                                            </Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <div style={{ height: '400px', overflow: 'auto' }}>
                                                <div className="row mt-5">
                                                    <div className="col-10">
                                                        <form className="search-form" style={{ width: '100%' }}>
                                                            <input
                                                                type="text"
                                                                placeholder="Search for distribution channels with name, code..."
                                                                className="search-input"
                                                            />
                                                            <button type="submit" className="search-button">
                                                                <i className="fas fa-search" />
                                                            </button>
                                                        </form>
                                                    </div>
                                                    <div className="filter-component col-2">
                                                        <p>
                                                            <strong>
                                                                <i className="fa fa-filter" /> Advance Filters
                                                            </strong>
                                                        </p>
                                                    </div>

                                                    <div className="employee-container ml-1 mt-3">
                                                        <table className="employee-table">
                                                            <thead>
                                                                <tr>
                                                                    <th>Name</th>
                                                                    <th>Code</th>
                                                                    <th>Path</th>
                                                                    <th>Distribution channel group</th>
                                                                    <th>Start book date </th>
                                                                    <th>End book date </th>
                                                                </tr>
                                                            </thead>

                                                            <tbody>
                                                                <tr>
                                                                    <td>Booking.com</td>
                                                                    <td>B111</td>
                                                                    <td>
                                                                        http://c21zsn.localhost:3000/client/channel-manager/distribution-channel/distribution-detail
                                                                    </td>
                                                                    <td />
                                                                    <td />
                                                                    <td />
                                                                </tr>
                                                                <tr>
                                                                    <td>Hotelbeds.com</td>
                                                                    <td>H111</td>
                                                                    <td>
                                                                        http://c21zsn.localhost:3000/client/channel-manager/distribution-channel/distribution-detail
                                                                    </td>
                                                                    <td />
                                                                    <td />
                                                                    <td />
                                                                </tr>
                                                                <tr>
                                                                    <td>Trip.com</td>
                                                                    <td>T112</td>
                                                                    <td>
                                                                        http://c21zsn.localhost:3000/client/channel-manager/distribution-channel/distribution-detail
                                                                    </td>
                                                                    <td />
                                                                    <td />
                                                                    <td />
                                                                </tr>
                                                                <tr>
                                                                    <td>Traveloka.com</td>
                                                                    <td>TL111</td>
                                                                    <td>
                                                                        http://c21zsn.localhost:3000/client/channel-manager/distribution-channel/distribution-detail
                                                                    </td>
                                                                    <td />
                                                                    <td />
                                                                    <td />
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </Modal.Body>
                                        <Modal.Footer>
                                            <Button variant="secondary" onClick={handleAllLinkChannelClose}>
                                                Cancel
                                            </Button>

                                            <Button variant="primary" onClick={handleAllLinkChannelSave}>
                                                Link Channels
                                            </Button>
                                        </Modal.Footer>
                                    </Modal>

                                    {/* Modal to link distribution channel */}
                                    <Modal
                                        show={showLinkChannelModal}
                                        onHide={handleLinkChannelClose}
                                        size="xl"
                                        backdrop="static"
                                        keyboard={false}
                                    >
                                        <Modal.Header closeButton style={{ color: 'black', background: '#fff' }}>
                                            <Modal.Title>
                                                <strong>Link Distribution Channel</strong>
                                            </Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                            {/* Step Indicators */}
                                            <div className="d-flex justify-content-center mb-4">
                                                {/* Step 1 */}
                                                <div
                                                    className={`step-indicator ${
                                                        step > 1 ? 'completed' : step === 1 ? 'current' : ''
                                                    }`}
                                                >
                                                    1
                                                </div>
                                                {/* Step 2 */}
                                                <div
                                                    className={`step-indicator ${
                                                        step > 2 ? 'completed' : step === 2 ? 'current' : ''
                                                    }`}
                                                >
                                                    2
                                                </div>
                                            </div>

                                            {step === 1 && (
                                                <div>
                                                    <h5 className="ml-3 mt-3 text-black">
                                                        <strong>Select the distribution channel you want to link</strong>
                                                    </h5>
                                                    {/* Search and Filter */}
                                                    <div className="row">
                                                        <div className="col-10">
                                                            <form
                                                                className="search-form"
                                                                style={{ width: '100%', padding: '8px' }}
                                                            >
                                                                <input
                                                                    type="text"
                                                                    placeholder="Search for distribution channels with name, code..."
                                                                    className="search-input"
                                                                />
                                                                <button type="submit" className="search-button">
                                                                    <i className="fas fa-search" />
                                                                </button>
                                                            </form>
                                                        </div>
                                                        <div className="filter-component col-2">
                                                            <p>
                                                                <strong>
                                                                    <i className="fa fa-filter" /> Advance Filters
                                                                </strong>
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <hr />
                                                    {/* Distribution Channels List */}
                                                    <div className="link-DistributionChannel">
                                                        {/* Repeat this block for each distribution channel */}
                                                        <div className="row align-items-start">
                                                            <div className="col-auto">
                                                                <div className="d-flex align-items-center ml-3">
                                                                    <Form.Check
                                                                        type="checkbox"
                                                                        label=""
                                                                        onChange={handleCheckboxChange}
                                                                    />
                                                                    <i className="fa-solid fa-building-columns ml-2" />
                                                                </div>
                                                            </div>
                                                            <div className="col">
                                                                <strong>Booking.com</strong>
                                                                <div className="mt-1">
                                                                    <small>
                                                                        Code: B111 - Path:
                                                                        http://c21zsn.localhost:3000/client/channel-manager/distribution-channel/distribution-detail
                                                                    </small>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <hr />
                                                        {/* Repeat similar blocks for other distribution channels */}
                                                    </div>
                                                </div>
                                            )}

                                            {step === 2 && (
                                                <div>
                                                    <h5 className="mb-4 text-black">
                                                        <strong>
                                                            Select a start and end book date for the distribution channel
                                                            link
                                                        </strong>
                                                    </h5>
                                                    <hr />
                                                    <small className="text-black">
                                                        Select a start book date or end book date for this connection, you
                                                        can always change the start book date and end book date at a later
                                                        moment, but you cannot remove this link anymore. If you leave the
                                                        start book date and end book date empty, the link will always be
                                                        active.
                                                    </small>
                                                    {/* Date Pickers */}
                                                    <div
                                                        className="d-flex pt-3 ml-5 text-black"
                                                        style={{ paddingLeft: '18rem' }}
                                                    >
                                                        <div>
                                                            <label>
                                                                <strong>Start Book Date</strong>
                                                            </label>
                                                            <div className="d-flex align-items-center">
                                                                <input type="date" className="form-control" />
                                                            </div>
                                                        </div>
                                                        <div className="ml-5">
                                                            <label>
                                                                <strong>End Book date</strong>
                                                            </label>
                                                            <div className="d-flex align-items-center">
                                                                <input type="date" className="form-control" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </Modal.Body>
                                        <Modal.Footer>
                                            <Button variant="secondary" onClick={handleLinkChannelClose}>
                                                Cancel
                                            </Button>
                                            {step > 1 && (
                                                <Button variant="primary" onClick={prevStep}>
                                                    Previous Step
                                                </Button>
                                            )}
                                            {step < 2 ? (
                                                <Button
                                                    variant="primary"
                                                    onClick={nextStep}
                                                    disabled={step === 1 && !isCheckboxSelected} // Disable if no checkbox selected on step 1
                                                >
                                                    Next
                                                </Button>
                                            ) : (
                                                <Button variant="primary" onClick={handleLinkChannelSave}>
                                                    Link Channels
                                                </Button>
                                            )}
                                        </Modal.Footer>
                                    </Modal>

                                    {/* Modal to add rentability rule*/}
                                    <Modal
                                        show={showAddRuleModal}
                                        onHide={handleAddRuleClose}
                                        size="xl"
                                        backdrop="static"
                                        keyboard={false}
                                    >
                                        <Modal.Header style={{ color: 'black', background: '#fff' }} closeButton>
                                            <Modal.Title>
                                                <strong>Add Rentability Rule</strong>
                                            </Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <div style={{ height: '330px', overflow: 'auto' }}>
                                                <div className="container-lg px-4 mt-4">
                                                    <Row>
                                                        <div className="card-body">
                                                            <Form>
                                                                <div className="d-flex pt-2">
                                                                    <div className="mr-5">
                                                                        <label>
                                                                            <strong>
                                                                                Stay date from - to and including
                                                                            </strong>
                                                                        </label>
                                                                        <div className="d-flex align-items-center">
                                                                            <input type="date" className="form-control" />
                                                                            <i className="fa-solid fa-arrow-right mx-2" />
                                                                            <input type="date" className="form-control" />
                                                                        </div>
                                                                        <div className="mt-2">
                                                                            <Form.Group controlId="formProduct">
                                                                                <Form.Label>
                                                                                    <strong>Arrival Days</strong>
                                                                                </Form.Label>
                                                                                <Dropdown>
                                                                                    <Dropdown.Toggle
                                                                                        variant="light"
                                                                                        id="dropdown-basic"
                                                                                        className="text-start"
                                                                                    >
                                                                                        {displayValue}
                                                                                    </Dropdown.Toggle>

                                                                                    <Dropdown.Menu>
                                                                                        <div
                                                                                            className="dropdown-item"
                                                                                            onClick={
                                                                                                handleSelectAllArrivalDays
                                                                                            }
                                                                                        >
                                                                                            <strong>Select All</strong>
                                                                                            {/* Deselect All option */}
                                                                                        </div>
                                                                                        <Dropdown.Divider />
                                                                                        {days.map((day) => (
                                                                                            <div
                                                                                                key={day}
                                                                                                className="dropdown-item"
                                                                                            >
                                                                                                <Form.Check
                                                                                                    type="checkbox"
                                                                                                    label={day}
                                                                                                    checked={selectedArrivalDays.includes(
                                                                                                        day
                                                                                                    )}
                                                                                                    onChange={() =>
                                                                                                        handleArrivalCheckboxChange(
                                                                                                            day
                                                                                                        )
                                                                                                    }
                                                                                                />
                                                                                            </div>
                                                                                        ))}
                                                                                        <Dropdown.Divider />
                                                                                        <div
                                                                                            className="dropdown-item"
                                                                                            onClick={
                                                                                                handleDeselectAllArrivalDays
                                                                                            }
                                                                                        >
                                                                                            <strong>Deselect All</strong>
                                                                                            {/* Deselect All option */}
                                                                                        </div>
                                                                                    </Dropdown.Menu>
                                                                                </Dropdown>
                                                                            </Form.Group>
                                                                        </div>
                                                                        <div className="d-flex mt-2">
                                                                            <div className="mr-2">
                                                                                <label>
                                                                                    <strong>Min. length of stay</strong>
                                                                                </label>
                                                                                <input
                                                                                    type="number"
                                                                                    className="form-control"
                                                                                />
                                                                            </div>
                                                                            <div>
                                                                                <label>
                                                                                    <strong>Max. length of stay</strong>
                                                                                </label>
                                                                                <input
                                                                                    type="number"
                                                                                    className="form-control"
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className="mt-2">
                                                                            <label>
                                                                                <strong>Gap between two bookings</strong>
                                                                            </label>
                                                                            <input type="number" className="form-control" />
                                                                        </div>
                                                                    </div>

                                                                    <div className="ml-5">
                                                                        <label>
                                                                            <strong>Book date from - to</strong>
                                                                        </label>
                                                                        <div className="d-flex align-items-center">
                                                                            <input type="date" className="form-control" />
                                                                            <i className="fa-solid fa-arrow-right mx-2" />
                                                                            <input type="date" className="form-control" />
                                                                        </div>
                                                                        <div className="mt-2">
                                                                            <Form.Group controlId="formDepartureDays">
                                                                                <Form.Label>
                                                                                    <strong>Departure Days</strong>
                                                                                </Form.Label>
                                                                                <Dropdown>
                                                                                    <Dropdown.Toggle
                                                                                        variant="light"
                                                                                        id="dropdown-departure"
                                                                                        className="text-start"
                                                                                    >
                                                                                        {departureDisplayValue}
                                                                                    </Dropdown.Toggle>

                                                                                    <Dropdown.Menu>
                                                                                        <div
                                                                                            className="dropdown-item"
                                                                                            onClick={
                                                                                                handleSelectAllDepartureDays
                                                                                            }
                                                                                        >
                                                                                            <strong>Select All</strong>
                                                                                            {/* Select All option */}
                                                                                        </div>
                                                                                        <Dropdown.Divider />
                                                                                        {days.map((day) => (
                                                                                            <div
                                                                                                key={day}
                                                                                                className="dropdown-item"
                                                                                            >
                                                                                                <Form.Check
                                                                                                    type="checkbox"
                                                                                                    label={day}
                                                                                                    checked={selectedDepartureDays.includes(
                                                                                                        day
                                                                                                    )}
                                                                                                    onChange={() =>
                                                                                                        handleDepartureCheckboxChange(
                                                                                                            day
                                                                                                        )
                                                                                                    }
                                                                                                />
                                                                                            </div>
                                                                                        ))}
                                                                                        <Dropdown.Divider />
                                                                                        <div
                                                                                            className="dropdown-item"
                                                                                            onClick={
                                                                                                handleDeselectAllDepartureDays
                                                                                            }
                                                                                        >
                                                                                            <strong>Deselect All</strong>
                                                                                            {/* Deselect All option */}
                                                                                        </div>
                                                                                    </Dropdown.Menu>
                                                                                </Dropdown>
                                                                            </Form.Group>
                                                                        </div>
                                                                        <div className="d-flex mt-2">
                                                                            <div className="mr-2">
                                                                                <label>
                                                                                    <strong>Min. deviation</strong>
                                                                                </label>
                                                                                <input
                                                                                    type="number"
                                                                                    className="form-control"
                                                                                />
                                                                            </div>
                                                                            <div>
                                                                                <label>
                                                                                    <strong>Max. deviation</strong>
                                                                                </label>
                                                                                <input
                                                                                    type="number"
                                                                                    className="form-control"
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Form>
                                                        </div>
                                                    </Row>
                                                </div>
                                            </div>
                                        </Modal.Body>
                                        <Modal.Footer>
                                            <Button variant="secondary" onClick={handleAddRuleClose}>
                                                Close
                                            </Button>
                                            <Button variant="primary" onClick={handleSaveAddRule}>
                                                Save
                                            </Button>
                                        </Modal.Footer>
                                    </Modal>

                                    {/* Modal for Link Rentability Set */}
                                    <Modal
                                        show={showLinkSetModal}
                                        onHide={handleLinkSetClose}
                                        size="xl"
                                        backdrop="static" // Disable clicking outside to close
                                        keyboard={false} // Disable pressing 'Esc' to close
                                    >
                                        <Modal.Header style={{ color: 'black', background: '#fff' }} closeButton>
                                            <Modal.Title>
                                                <strong>Link Rentability Set</strong>
                                            </Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                            {/* Add content for Link Rentability Set */}
                                            <div className="d-flex pt-3 text-black">
                                                <div>
                                                    <label>
                                                        <strong>Stay date from - to and including</strong>
                                                    </label>
                                                    <div className="d-flex align-items-center">
                                                        <input type="date" className="form-control" />
                                                        <i className="fa-solid fa-arrow-right mx-2" />
                                                        <input type="date" className="form-control" />
                                                    </div>
                                                </div>
                                                <div className="ml-5">
                                                    <label>
                                                        <strong>Book date from - to</strong>
                                                    </label>
                                                    <div className="d-flex align-items-center">
                                                        <input type="date" className="form-control" />
                                                        <i className="fa-solid fa-arrow-right mx-2" />
                                                        <input type="date" className="form-control" />
                                                    </div>
                                                </div>
                                            </div>
                                            {/* Basic Card-1*/}
                                            <div className="card mt-3">
                                                <div className="card-header py-3">
                                                    <h6 className="m-0 font-weight-bold text-black">
                                                        <strong>Link Rentability Set</strong>
                                                    </h6>
                                                </div>
                                                <div className="card-body">
                                                    <div className="d-flex pt-3 text-black">
                                                        <div>
                                                            <label>
                                                                <strong>Stay date from - to and including</strong>
                                                            </label>
                                                            <div className="d-flex align-items-center">
                                                                <input type="date" className="form-control" />
                                                                <i className="fa-solid fa-arrow-right mx-2" />
                                                                <input type="date" className="form-control" />
                                                            </div>
                                                        </div>
                                                        <div className="book-date" style={{ marginLeft: '10rem' }}>
                                                            <label>
                                                                <strong>Book date from - to</strong>
                                                            </label>
                                                            <div className="d-flex align-items-center">
                                                                <input type="date" className="form-control" />
                                                                <i className="fa-solid fa-arrow-right mx-2" />
                                                                <input type="date" className="form-control" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <br />
                                                    <div className="rentabilityset-dropdown">
                                                        <Form.Group>
                                                            <Form.Label>
                                                                <strong>Rentability Set</strong>
                                                            </Form.Label>
                                                            <Form.Group>
                                                                <div
                                                                    className="dropdown-with-caret"
                                                                    style={{ width: '20rem' }}
                                                                >
                                                                    <Form.Control as="select" defaultValue="">
                                                                        <option>Select rentability set</option>
                                                                        <option>Royal Retreat (1101)</option>
                                                                        <option>Luxe Lagoon (1201)</option>
                                                                        <option>Gided Garden (1301)</option>
                                                                        <option>Majestic Manor (1401)</option>
                                                                        <option>Splendid Sanctuary (1501)</option>
                                                                    </Form.Control>
                                                                    <span className="caret" />
                                                                </div>
                                                            </Form.Group>
                                                        </Form.Group>
                                                    </div>
                                                    <br />
                                                </div>
                                            </div>
                                        </Modal.Body>
                                        <Modal.Footer>
                                            <Button variant="secondary" onClick={handleLinkSetClose}>
                                                Close
                                            </Button>
                                            <Button variant="primary" onClick={handleSaveLinkSet}>
                                                Save
                                            </Button>
                                        </Modal.Footer>
                                    </Modal>
                                </tbody>
                            </table>
                        </div>
                        <div className="text-end pt-4">
                            <Button variant="primary mr-4" onClick={handleShow}>
                                Create Representation
                            </Button>
                        </div>
                        {/* Create Representation Modal*/}
                        <Modal
                            show={showModal}
                            onHide={handleClose}
                            size="lg"
                            backdrop="static" // Disable clicking outside to close
                            keyboard={false} // Disable pressing 'Esc' to close
                        >
                            <Modal.Header style={{ color: 'black', background: '#fff' }} closeButton>
                                <Modal.Title>
                                    <strong>Create Representation</strong>
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div style={{ height: '400px', overflow: 'auto' }}>
                                    <div className="container-xl px-4 mt-4">
                                        <div className="card mb-4">
                                            <div className="card-header">
                                                <strong>General Information</strong>
                                            </div>
                                            <div className="card-body">
                                                <Form>
                                                    <Form.Group controlId="formFirstName">
                                                        <Form.Label>
                                                            <strong>Name</strong>
                                                        </Form.Label>
                                                        <Form.Control type="text" defaultValue=" " />
                                                    </Form.Group>
                                                    <br />
                                                    <Form.Group controlId="formFirstName">
                                                        <Form.Label>
                                                            <strong>Internal Name</strong>
                                                        </Form.Label>
                                                        <Form.Control type="text" defaultValue="" />
                                                    </Form.Group>
                                                    <br />
                                                    <div className="form-group">
                                                        <label htmlFor="exampleFormControlTextarea1">
                                                            <strong>Description</strong>
                                                        </label>
                                                        <textarea
                                                            className="form-control"
                                                            id="exampleFormControlTextarea1"
                                                        />
                                                    </div>
                                                    <br />
                                                    <div className="form-group">
                                                        <label htmlFor="exampleFormControlTextarea1">
                                                            <strong>Short description</strong>
                                                        </label>
                                                        <textarea
                                                            className="form-control"
                                                            id="exampleFormControlTextarea1"
                                                        />
                                                    </div>

                                                    <div className="status">
                                                        <strong>Status</strong>
                                                    </div>

                                                    {/* Bookable Badge */}
                                                    <Button
                                                        className={`badge ${tempBookable ? 'badge-success' : 'badge-dark'}`}
                                                        onClick={() => setTempBookable(!tempBookable)}
                                                        style={{ marginRight: '10px', border: 'none' }}
                                                    >
                                                        Bookable
                                                    </Button>

                                                    {/* Visible Badge */}
                                                    <Button
                                                        className={`badge ${tempVisible ? 'badge-success' : 'badge-dark'}`}
                                                        onClick={() => setTempVisible(!tempVisible)}
                                                        style={{ border: 'none' }}
                                                    >
                                                        Visible
                                                    </Button>

                                                    <p style={{ color: 'blue', cursor: 'pointer' }} onClick={handleSave}>
                                                        <strong>Know More</strong>
                                                    </p>
                                                </Form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Modal.Body>

                            <Modal.Footer>
                                <Button variant="secondary" onClick={handleClose}>
                                    Close
                                </Button>
                                <Button variant="primary" onClick={handleSave}>
                                    Save
                                </Button>
                            </Modal.Footer>
                        </Modal>
                    </div>
                </div>

                {/* Basic Card-3*/}
                <div ref={cardCRef} className="card shadow mb-4">
                    <div className="card-header py-3">
                        <h6 className="m-0 font-weight-bold text-black">
                            <strong>Card C</strong>
                        </h6>
                    </div>
                    <div className="card-body">
                        The styling for this basic card example is created by using default Bootstrap utility classes. By
                        using utility classes, the style of the card component can be easily modified with no need for any
                        custom CSS!
                    </div>
                </div>
                {/* Basic Card-4*/}
                <div ref={cardDRef} className="card shadow mb-4">
                    <div className="card-header py-3">
                        <h6 className="m-0 font-weight-bold text-black">
                            <strong>Card D</strong>
                        </h6>
                    </div>
                    <div className="card-body">
                        The styling for this basic card example is created by using default Bootstrap utility classes. By
                        using utility classes, the style of the card component can be easily modified with no need for any
                        custom CSS!
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RateAccomodationDetail;
