// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* BlockMenu styles */
.block-menu {
    font-weight: bold;
    background-color: white;
    border-radius: 12px;
    color: black;
    border-bottom: 1px solid #ddd;
    padding-top: 10px;
    text-align: center; /* Center the menu label text */
}


.block-menu .nav-item.active {
    background-color: #e9ecef;
    border-left: 4px solid #007bff;
}

.block-menu .nav-link {
    color: black;
    text-decoration: none;
    padding: 10px 15px;
    display: block;
    transition: background-color 0.3s, color 0.3s;
}

.block-menu .nav-link:hover {
    background-color: #dee2e6;
    color: white;
}


/* Container for the menu label and icon */
.menu-label-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

/* Menu label style */
.menu-label {
    margin-left: 8px;
}
`, "",{"version":3,"sources":["webpack://./src/components/common/Menu/BlockMenu.css"],"names":[],"mappings":"AAAA,qBAAqB;AACrB;IACI,iBAAiB;IACjB,uBAAuB;IACvB,mBAAmB;IACnB,YAAY;IACZ,6BAA6B;IAC7B,iBAAiB;IACjB,kBAAkB,EAAE,+BAA+B;AACvD;;;AAGA;IACI,yBAAyB;IACzB,8BAA8B;AAClC;;AAEA;IACI,YAAY;IACZ,qBAAqB;IACrB,kBAAkB;IAClB,cAAc;IACd,6CAA6C;AACjD;;AAEA;IACI,yBAAyB;IACzB,YAAY;AAChB;;;AAGA,0CAA0C;AAC1C;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;AAC3B;;AAEA,qBAAqB;AACrB;IACI,gBAAgB;AACpB","sourcesContent":["/* BlockMenu styles */\r\n.block-menu {\r\n    font-weight: bold;\r\n    background-color: white;\r\n    border-radius: 12px;\r\n    color: black;\r\n    border-bottom: 1px solid #ddd;\r\n    padding-top: 10px;\r\n    text-align: center; /* Center the menu label text */\r\n}\r\n\r\n\r\n.block-menu .nav-item.active {\r\n    background-color: #e9ecef;\r\n    border-left: 4px solid #007bff;\r\n}\r\n\r\n.block-menu .nav-link {\r\n    color: black;\r\n    text-decoration: none;\r\n    padding: 10px 15px;\r\n    display: block;\r\n    transition: background-color 0.3s, color 0.3s;\r\n}\r\n\r\n.block-menu .nav-link:hover {\r\n    background-color: #dee2e6;\r\n    color: white;\r\n}\r\n\r\n\r\n/* Container for the menu label and icon */\r\n.menu-label-container {\r\n    display: flex;\r\n    align-items: center;\r\n    justify-content: center;\r\n}\r\n\r\n/* Menu label style */\r\n.menu-label {\r\n    margin-left: 8px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
