import React from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
//import { PageHeaderType } from '../../../types/menu';
//import PageHeader from '../../common/Header/PageHeader';
import { AlphaNumericAndCharactersRegex, AlphaNumericRegex, PhoneRegex } from '../../../helpers/form/validations';
import { RequiredField } from '../../common/Light/MarkUps';
import { createClient } from '../../../services/client';
import { useNotificationContext } from '../../../hooks/useNotificationContext';

// Todo: make seperate import.
const validationSchema = Yup.object().shape({
    name: Yup.string()
        .matches(AlphaNumericAndCharactersRegex, 'Only alphabet, space and number allowed.')
        .required('Client name is required'),
    email: Yup.string().email('Invalid email address').required('Email address is required'),
    phone: Yup.string().matches(PhoneRegex, 'Invalid phone number format').required('Phone number is required'),
    addressStreet1: Yup.string()
        .matches(AlphaNumericAndCharactersRegex, 'Only alphabet, space and number allowed.')
        .required('Street 1 is required'),
    addressStreet2: Yup.string(),
    addressCity: Yup.string().matches(AlphaNumericRegex, 'Only alphabet allowed.').required('City is required'),
    addressPinCode: Yup.string().required('Pin code is required'),
    addressState: Yup.string().required('State is required'),
    addressCountry: Yup.string().required('Country is required'),
});

export const CreateClient = () => {
    const { triggerSuccess } = useNotificationContext();

    const formik = useFormik({
        initialValues: {
            name: '',
            email: '',
            phone: '',
            addressStreet1: '',
            addressStreet2: '',
            addressCity: '',
            addressPinCode: '',
            addressState: '',
            addressCountry: '',
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            const res = await createClient(values);
            if (res.message) {
                triggerSuccess(res.message);
            }
        },
    });

    return (
        <div className="container-fluid">
            <div className="create-client-form">
                <div className="container-xl px-4 mt-4">
                    <div className="col-12">
                        <div className="card mb-4">
                            <div className="card-header">
                                Fields marked with <i className="fa fa-xs fa-asterisk text-danger" /> are required.
                            </div>
                            <div className="card-body">
                                <form onSubmit={formik.handleSubmit}>
                                    <div className="mb-3">
                                        <label className="small mb-2 ps-1 text-gray-700" htmlFor="inputname">
                                            Client Name <RequiredField />
                                        </label>
                                        <input
                                            className="form-control"
                                            id="inputname"
                                            type="text"
                                            name="name"
                                            placeholder="Enter client name"
                                            value={formik.values.name}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                        />
                                        {formik.touched.name && formik.errors.name ? (
                                            <div className="text-danger">{formik.errors.name}</div>
                                        ) : null}
                                    </div>
                                    <div className="row gx-3 mb-3">
                                        <div className="col-md-6">
                                            <label className="small mb-2 ps-1 text-gray-700" htmlFor="inputemail">
                                                Email address <RequiredField />
                                            </label>
                                            <input
                                                className="form-control"
                                                id="inputemail"
                                                type="email"
                                                name="email"
                                                placeholder="Enter client email address"
                                                value={formik.values.email}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                            {formik.touched.email && formik.errors.email ? (
                                                <div className="text-danger">{formik.errors.email}</div>
                                            ) : null}
                                        </div>
                                        <div className="col-md-6">
                                            <label className="small mb-2 ps-1 text-gray-700" htmlFor="inputPhone">
                                                Phone <RequiredField />
                                            </label>
                                            <input
                                                className="form-control"
                                                id="inputPhone"
                                                type="text"
                                                name="phone"
                                                placeholder="Enter client phone (e.g. +91 9991919191)"
                                                value={formik.values.phone}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                            {formik.touched.phone && formik.errors.phone ? (
                                                <div className="text-danger">{formik.errors.phone}</div>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div className="row gx-3 mb-3">
                                        <div className="col-md-6">
                                            <label className="small mb-2 ps-1 text-gray-700" htmlFor="inputaddressStreet1">
                                                Street 1 <RequiredField />
                                            </label>
                                            <input
                                                className="form-control"
                                                id="inputaddressStreet1"
                                                type="text"
                                                name="addressStreet1"
                                                placeholder="Enter street 1"
                                                value={formik.values.addressStreet1}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                            {formik.touched.addressStreet1 && formik.errors.addressStreet1 ? (
                                                <div className="text-danger">{formik.errors.addressStreet1}</div>
                                            ) : null}
                                        </div>
                                        <div className="col-md-6">
                                            <label className="small mb-2 ps-1 text-gray-700" htmlFor="inputaddressStreet2">
                                                Street 2
                                            </label>
                                            <input
                                                className="form-control"
                                                id="inputaddressStreet2"
                                                type="text"
                                                name="addressStreet2"
                                                placeholder="Enter street 2"
                                                value={formik.values.addressStreet2}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                            {formik.touched.addressStreet2 && formik.errors.addressStreet2 ? (
                                                <div className="text-danger">{formik.errors.addressStreet2}</div>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div className="row gx-3 mb-3">
                                        <div className="col-md-6">
                                            <label className="small mb-2 ps-1 text-gray-700" htmlFor="inputaddressCity">
                                                City <RequiredField />
                                            </label>
                                            <input
                                                className="form-control"
                                                id="inputaddressCity"
                                                type="text"
                                                name="addressCity"
                                                placeholder="Enter addressCity name"
                                                value={formik.values.addressCity}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                            {formik.touched.addressCity && formik.errors.addressCity ? (
                                                <div className="text-danger">{formik.errors.addressCity}</div>
                                            ) : null}
                                        </div>
                                        <div className="col-md-6">
                                            <label className="small mb-2 ps-1 text-gray-700" htmlFor="inputaddressPinCode">
                                                Pin Code <RequiredField />
                                            </label>
                                            <input
                                                className="form-control"
                                                id="inputaddressPinCode"
                                                type="text"
                                                name="addressPinCode"
                                                placeholder="Enter addressPinCode code"
                                                value={formik.values.addressPinCode}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                            {formik.touched.addressPinCode && formik.errors.addressPinCode ? (
                                                <div className="text-danger">{formik.errors.addressPinCode}</div>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div className="row gx-3 mb-3">
                                        <div className="col-md-6">
                                            <label className="small mb-2 ps-1 text-gray-700" htmlFor="inputaddressState">
                                                State <RequiredField />
                                            </label>
                                            <input
                                                className="form-control"
                                                id="inputaddressState"
                                                type="text"
                                                name="addressState"
                                                placeholder="Enter addressState name"
                                                value={formik.values.addressState}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                            {formik.touched.addressState && formik.errors.addressState ? (
                                                <div className="text-danger">{formik.errors.addressState}</div>
                                            ) : null}
                                        </div>
                                        <div className="col-md-6">
                                            <label className="small mb-2 ps-1 text-gray-700">
                                                Country <RequiredField />
                                            </label>
                                            <select
                                                className="form-select"
                                                aria-label="Select addressCountry Dropdown"
                                                name="addressCountry"
                                                value={formik.values.addressCountry}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            >
                                                <option value="">Select addressCountry:</option>
                                                <option value="India">India</option>
                                                <option value="Japan">Japan</option>
                                            </select>
                                            {formik.touched.addressCountry && formik.errors.addressCountry ? (
                                                <div className="text-danger">{formik.errors.addressCountry}</div>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div className="row gx-3 mb-3 text-end">
                                        <div className="col-12">
                                            <button
                                                className="btn btn-primary"
                                                type="submit"
                                                disabled={formik.isSubmitting || formik.isValidating}
                                            >
                                                Create Client Profile
                                                {(formik.isSubmitting || formik.isValidating) && (
                                                    <i className="fa-solid fa-circle-notch fa-spin ms-2" />
                                                )}
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
