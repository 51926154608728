const Menu = {
    menuLabel: 'Channel Manager',
    menus: [
        {
            label: 'Distribution Channel',
            path: '/client/channel-manager/distribution-channel',
            faIconClass: 'fa-solid fa-truck-plane',
        },
        {
            label: 'Reservation Category',
            path: '/client/channel-manager/reservation-categories',
            faIconClass: 'fa-solid fa-ticket',
        },
    ],
};

export default Menu;
