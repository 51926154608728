import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap'; // Added Form for form controls
import { Link } from 'react-router-dom'; // Import Link for navigation
import { PageHeaderType } from '../../../../types/menu';
import PageHeader from '../../../common/Header/PageHeader';
import './Addons.css';
import CreateAddonsModal from './CreateAddonsModal';

export const Addons = () => {
    const [showModal, setShowModal] = useState(false);
    const [createAddonsShowModal, setCreateAddonsShowModal] = useState(false);

    // State for actual columns display
    const [columns, setColumns] = useState({
        addonsName: true,
        addonsCode: true,
        addonsId: true,
    });

    // Temporary state for modal
    const [tempColumns, setTempColumns] = useState(columns);

    const handleCloseCreateAddonsShowModal = () => {
        setCreateAddonsShowModal(false);
    };

    const handleShowCreateAddonsShowModal = () => {
        setCreateAddonsShowModal(true);
    };

    const pageHeader: PageHeaderType = {
        title: 'Add-ons',
        prevNavLabel: 'Dashboard',
        prevNavLink: '/client/landing',
        iconClass: 'fa-solid fa-puzzle-piece',
        modal: {
            showModalHandler: handleShowCreateAddonsShowModal,
        },
        createPageType: 'modal',
    };

    const handleConfigureColumnsClick = () => {
        setTempColumns(columns); // Sync tempColumns with current columns state
        setShowModal(true);
    };

    const handleClose = () => {
        setShowModal(false);
    };

    // Function to handle checkbox changes in modal
    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { id, checked } = event.target;
        setTempColumns((prevState) => ({
            ...prevState,
            [id]: checked,
        }));
    };

    // Function to save changes
    const handleSave = () => {
        setColumns(tempColumns);
        handleClose();
    };

    return (
        <div className="container-fluid">
            <PageHeader {...pageHeader} />
            <CreateAddonsModal showModal={createAddonsShowModal} handleClose={handleCloseCreateAddonsShowModal} />
            <div className="main-container">
                <div className="configure-columns" onClick={handleConfigureColumnsClick}>
                    <i className="fas fa-cogs configure-icon" />
                    <span>Configure Columns</span>
                </div>
                <div className="employee-container">
                    <table className="employee-table">
                        <thead>
                            <tr>
                                {columns.addonsName && (
                                    <th>
                                        Add-ons Name
                                        <i className="fas fa-sort ml-2" />
                                    </th>
                                )}
                                {columns.addonsCode && (
                                    <th>
                                        Add-ons Code
                                        <i className="fas fa-sort ml-2" />
                                    </th>
                                )}
                                {columns.addonsId && (
                                    <th>
                                        Add-ons ID
                                        <i className="fas fa-sort ml-2" />
                                    </th>
                                )}
                            </tr>
                        </thead>
                        <tbody>
                            {/* Your table rows */}
                            <tr>
                                {columns.addonsName && (
                                    <td>
                                        <Link to="/client/inventory-manager/addons/addons-detail">BreakFast</Link>
                                    </td>
                                )}

                                {columns.addonsCode && (
                                    <td>
                                        <Link to="/client/inventory-manager/addons/addons-detail">BF1001</Link>
                                    </td>
                                )}
                                {columns.addonsId && (
                                    <td>
                                        <Link to="/client/inventory-manager/addons/addons-detail">36321</Link>
                                    </td>
                                )}
                            </tr>
                            <tr>
                                {columns.addonsName && (
                                    <td>
                                        <Link to="/client/inventory-manager/addons/addons-detail">Lunch</Link>
                                    </td>
                                )}

                                {columns.addonsCode && (
                                    <td>
                                        <Link to="/client/inventory-manager/addons/addons-detail">LU1002</Link>
                                    </td>
                                )}
                                {columns.addonsId && (
                                    <td>
                                        <Link to="/client/inventory-manager/addons/addons-detail">63839</Link>
                                    </td>
                                )}
                            </tr>
                            <tr>
                                {columns.addonsName && (
                                    <td>
                                        <Link to="/client/inventory-manager/addons/addons-detail">Dinner</Link>
                                    </td>
                                )}

                                {columns.addonsCode && (
                                    <td>
                                        <Link to="/client/inventory-manager/addons/addons-detail">DN1003</Link>
                                    </td>
                                )}
                                {columns.addonsId && (
                                    <td>
                                        <Link to="/client/inventory-manager/addons/addons-detail">83636</Link>
                                    </td>
                                )}
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <Modal show={showModal} onHide={handleClose}>
                <Modal.Header style={{ color: 'black', background: '#89c8ff' }} closeButton>
                    <Modal.Title>Configure Columns</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Check
                            type="checkbox"
                            id="addonsName"
                            label="Add-ons Name"
                            checked={tempColumns.addonsName}
                            onChange={handleCheckboxChange}
                        />
                        <Form.Check
                            type="checkbox"
                            id="addonsCode"
                            label="Add-ons Code"
                            checked={tempColumns.addonsCode}
                            onChange={handleCheckboxChange}
                        />
                        <Form.Check
                            type="checkbox"
                            id="addonsId"
                            label="Add-ons ID"
                            checked={tempColumns.addonsId}
                            onChange={handleCheckboxChange}
                        />
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleSave}>
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default Addons;
