import React from 'react';
import { Route } from 'react-router-dom';
import AdminSidebarLayout from '../../components/admin/Sidebar/AdminSidebarLayout';
import { ViewUserProfile } from '../../components/common/UserProfile/ViewUserProfile';
import { CreateClient } from '../../components/admin/Client/CreateClient';
import { ViewClientProfile } from '../../components/admin/Client/ViewClientProfile';
import Dashboard from '../../components/Dashboard';
import { ViewClientList } from '../../components/admin/Client/ViewClientList';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default [
    <Route
        key="admin-index"
        index
        element={
            <AdminSidebarLayout>
                <Dashboard />
            </AdminSidebarLayout>
        }
    />,
    <Route
        key="client-landing"
        path="/admin/user-profile/"
        element={
            <AdminSidebarLayout>
                <ViewUserProfile />
            </AdminSidebarLayout>
        }
    />,
    <Route
        key="client-profiles-create"
        path="/admin/client-profiles/create"
        element={
            <AdminSidebarLayout>
                <CreateClient />
            </AdminSidebarLayout>
        }
    />,
    <Route
        key="client-profiles-view-single"
        path="/admin/client-profiles/view/single/:id"
        element={
            <AdminSidebarLayout>
                <ViewClientProfile />
            </AdminSidebarLayout>
        }
    />,
    <Route
        key="client-view-list"
        path="/admin/client-profiles/view/list"
        element={
            <AdminSidebarLayout>
                <ViewClientList />
            </AdminSidebarLayout>
        }
    />,
];
