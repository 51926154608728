/* eslint-disable no-console */
/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice } from '@reduxjs/toolkit';
import { ErrorState } from '../types/error';
// Slice
const slice = createSlice({
    name: 'error',
    initialState: {
        message: '',
        isError: false,
        showError: false,
    },
    reducers: {
        setError: (state, action) => {
            state.isError = action.payload.isError;
            state.showError = action.payload.showError ?? true;
            state.message = action.payload.message;
        },
        resetError: (state) => {
            state.isError = false;
            state.showError = false;
            state.message = '';
        },
    },
});
export default slice.reducer;

// Actions
const { setError, resetError } = slice.actions;
export const handleGlobalError =
    ({ message, isError, showError }: ErrorState) =>
    async (dispatch: any) => {
        try {
            return dispatch(setError({ message, isError, showError }));
        } catch (e: any) {
            return console.error(e.message);
        }
    };

export const resetGlobalError = () => async (dispatch: any) => {
    try {
        return dispatch(resetError());
    } catch (e: any) {
        return console.error(e.message);
    }
};
