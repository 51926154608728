import React from 'react';
import { PageHeaderType } from '../../../../types/menu';
import PageHeader from '../../../common/Header/PageHeader';
import './FeatureFlag.css';
// eslint-disable-next-line import/order
import { Form } from 'react-bootstrap';

export const FeatureFlag = () => {
    const pageHeader: PageHeaderType = {
        title: 'Feature Flag',
        prevNavLabel: 'Dashboard',
        prevNavLink: '/client/landing',
        iconClass: 'fa-solid fa-font-awesome',
    };

    const languages = ['Russian (RL)', 'Dutch (NL)', 'Spanish (SP)', 'German (GR)', 'French (FR)'];

    // Sort languages alphabetically
    const sortedLanguages = languages.sort();
    return (
        <div className="container-fluid">
            <PageHeader {...pageHeader} />
            <div className="main-container">
                <div className="feature-flag-container">
                    <div className="backoffice-info">
                        <div className="row align-items-center">
                            <div className="col-10 d-flex align-items-center">
                                <h5>
                                    <strong>BackOffice Manager</strong>
                                </h5>
                                <button type="button" className="free-button ml-2">
                                    <strong>Free</strong>
                                </button>
                            </div>
                            <div className="col-2 text-right">
                                <label className="switch">
                                    <input type="checkbox" />
                                    <span className="slider round" />
                                </label>
                            </div>
                        </div>
                        <div className="col-10">
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                            labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
                            laboris.
                        </div>
                    </div>
                    <br />
                    <div className="channel-info">
                        <div className="row align-items-center">
                            <div className="col-10 d-flex align-items-center">
                                <h5>
                                    <strong>Channel Manager</strong>
                                </h5>
                                <button type="button" className="free-button ml-2">
                                    <strong>Free</strong>
                                </button>
                            </div>
                            <div className="col-2 text-right">
                                <label className="switch">
                                    <input type="checkbox" />
                                    <span className="slider round" />
                                </label>
                            </div>
                        </div>
                        <div className="col-10">
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                            labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
                            laboris.
                        </div>
                    </div>
                    <br />
                    <div className="inventory-info">
                        <div className="row align-items-center">
                            <div className="col-10 d-flex align-items-center">
                                <h5>
                                    <strong>Inventory Manager</strong>
                                </h5>
                                <button type="button" className="free-button ml-2">
                                    <strong>Free</strong>
                                </button>
                            </div>
                            <div className="col-2 text-right">
                                <label className="switch">
                                    <input type="checkbox" />
                                    <span className="slider round" />
                                </label>
                            </div>
                        </div>
                        <div className="col-10">
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                            labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
                            laboris.
                        </div>
                    </div>
                    <br />
                    <div className="notification-info">
                        <div className="row align-items-center">
                            <div className="col-10 d-flex align-items-center">
                                <h5>
                                    <strong>Notification Manager</strong>
                                </h5>
                                <button type="button" className="free-button ml-2">
                                    <strong>Free</strong>
                                </button>
                            </div>
                            <div className="col-2 text-right">
                                <label className="switch">
                                    <input type="checkbox" />
                                    <span className="slider round" />
                                </label>
                            </div>
                        </div>
                        <div className="col-10">
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                            labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
                            laboris.
                        </div>
                    </div>
                    <br />
                    <div className="opertions-info">
                        <div className="row align-items-center">
                            <div className="col-10 d-flex align-items-center">
                                <h5>
                                    <strong>Operations Manager</strong>
                                </h5>
                                <button type="button" className="free-button ml-2">
                                    <strong>Free</strong>
                                </button>
                            </div>
                            <div className="col-2 text-right">
                                <label className="switch">
                                    <input type="checkbox" />
                                    <span className="slider round" />
                                </label>
                            </div>
                        </div>
                        <div className="col-10">
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                            labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
                            laboris.
                        </div>
                    </div>
                    <br />
                    <div className="owner-info">
                        <div className="row align-items-center">
                            <div className="col-10 d-flex align-items-center">
                                <h5>
                                    <strong>Owner Manager</strong>
                                </h5>
                                <button type="button" className="free-button ml-2">
                                    <strong>Free</strong>
                                </button>
                            </div>
                            <div className="col-2 text-right">
                                <label className="switch">
                                    <input type="checkbox" />
                                    <span className="slider round" />
                                </label>
                            </div>
                        </div>
                        <div className="col-10">
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                            labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
                            laboris.
                        </div>
                    </div>
                    <br />
                    <div className="rate-info">
                        <div className="row align-items-center">
                            <div className="col-10 d-flex align-items-center">
                                <h5>
                                    <strong>Rate Manager</strong>
                                </h5>
                                <button type="button" className="free-button ml-2">
                                    <strong>Free</strong>
                                </button>
                            </div>
                            <div className="col-2 text-right">
                                <label className="switch">
                                    <input type="checkbox" />
                                    <span className="slider round" />
                                </label>
                            </div>
                        </div>
                        <div className="col-10">
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                            labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
                            laboris.
                        </div>
                    </div>
                    <br />
                    <div className="reservation-info">
                        <div className="row align-items-center">
                            <div className="col-10 d-flex align-items-center">
                                <h5>
                                    <strong>Reservation Manager</strong>
                                </h5>
                                <button type="button" className="free-button ml-2">
                                    <strong>Free</strong>
                                </button>
                            </div>
                            <div className="col-2 text-right">
                                <label className="switch">
                                    <input type="checkbox" />
                                    <span className="slider round" />
                                </label>
                            </div>
                        </div>
                        <div className="col-10">
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                            labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
                            laboris.
                        </div>
                    </div>
                    <br />
                    <div className="websitebuilder-info">
                        <div className="row align-items-center">
                            <div className="col-10 d-flex align-items-center">
                                <h5>
                                    <strong>Website Builder</strong>
                                </h5>
                                <button type="button" className="free-button ml-2">
                                    <strong>Free</strong>
                                </button>
                            </div>
                            <div className="col-2 text-right">
                                <label className="switch">
                                    <input type="checkbox" />
                                    <span className="slider round" />
                                </label>
                            </div>
                        </div>
                        <div className="col-10">
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                            labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
                            laboris.
                        </div>
                    </div>
                    <br />
                    <div className="customer-info">
                        <div className="row align-items-center">
                            <div className="col-10 d-flex align-items-center">
                                <h5>
                                    <strong>Customer Manager</strong>
                                </h5>
                                <button type="button" className="free-button ml-2">
                                    <strong>Free</strong>
                                </button>
                            </div>
                            <div className="col-2 text-right">
                                <label className="switch">
                                    <input type="checkbox" />
                                    <span className="slider round" />
                                </label>
                            </div>
                        </div>
                        <div className="col-10">
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                            labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
                            laboris.
                        </div>
                    </div>
                    <br />
                    <div className="featurecontroller-info">
                        <div className="row align-items-center">
                            <div className="col-10 d-flex align-items-center">
                                <h5>
                                    <strong>Feature Controller</strong>
                                </h5>
                                <button type="button" className="free-button ml-2">
                                    <strong>Free</strong>
                                </button>
                            </div>
                            <div className="col-2 text-right">
                                <label className="switch">
                                    <input type="checkbox" />
                                    <span className="slider round" />
                                </label>
                            </div>
                        </div>
                        <div className="col-10">
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                            labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
                            laboris.
                        </div>
                    </div>
                    <br />
                    <div className="finance-info">
                        <div className="row align-items-center">
                            <div className="col-10 d-flex align-items-center">
                                <h5>
                                    <strong>Finance Manager</strong>
                                </h5>
                                <button type="button" className="free-button ml-2">
                                    <strong>Free</strong>
                                </button>
                            </div>
                            <div className="col-2 text-right">
                                <label className="switch">
                                    <input type="checkbox" />
                                    <span className="slider round" />
                                </label>
                            </div>
                        </div>
                        <div className="col-10">
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                            labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
                            laboris.
                        </div>
                    </div>
                    <br />
                    <div className="frontdesk-info">
                        <div className="row align-items-center">
                            <div className="col-10 d-flex align-items-center">
                                <h5>
                                    <strong>FrontDesk Manager</strong>
                                </h5>
                                <button type="button" className="free-button ml-2">
                                    <strong>Free</strong>
                                </button>
                            </div>
                            <div className="col-2 text-right">
                                <label className="switch">
                                    <input type="checkbox" />
                                    <span className="slider round" />
                                </label>
                            </div>
                        </div>
                        <div className="col-10">
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                            labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
                            laboris.
                        </div>
                    </div>
                    <br />
                    <div className="integration-info">
                        <div className="row align-items-center">
                            <div className="col-10 d-flex align-items-center">
                                <h5>
                                    <strong>Integration Manager</strong>
                                </h5>
                                <button type="button" className="free-button ml-2">
                                    <strong>Free</strong>
                                </button>
                            </div>
                            <div className="col-2 text-right">
                                <label className="switch">
                                    <input type="checkbox" />
                                    <span className="slider round" />
                                </label>
                            </div>
                        </div>
                        <div className="col-10">
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                            labore et dolore magna aliqua.
                        </div>
                    </div>
                    <br />
                    <div className="Language">
                        <div className="row align-items-center">
                            <div className="col-10 d-flex align-items-center">
                                <h5>
                                    <strong>Default Language</strong>
                                </h5>
                                <button type="button" className="free-button ml-2">
                                    <strong>Free</strong>
                                </button>
                            </div>
                            <div className="col-2 text-right">
                                <label className="switch">
                                    <input type="checkbox" />
                                    <span className="slider round" />
                                </label>
                            </div>
                        </div>
                        <div className="col-10">
                            This is the default language, which will be used for your understanding.
                        </div>
                    </div>
                    <div className="col-10">
                        <Form>
                            <Form.Group controlId="formLanguage">
                                <div className="dropdown-with-caret">
                                    <Form.Control as="select" defaultValue="">
                                        <option>English (UK)</option>
                                        {sortedLanguages.map((language, index) => (
                                            <option key={index}>{language}</option>
                                        ))}
                                    </Form.Control>
                                    <span className="caret" />
                                </div>
                            </Form.Group>
                        </Form>
                    </div>
                    <br />
                    <div className="Timezone">
                        <div className="row align-items-center">
                            <div className="col-10 d-flex align-items-center">
                                <h5>
                                    <strong>Concern Timezone</strong>
                                </h5>
                                <button type="button" className="free-button ml-2">
                                    <strong>Free</strong>
                                </button>
                            </div>
                            <div className="col-2 text-right">
                                <label className="switch">
                                    <input type="checkbox" />
                                    <span className="slider round" />
                                </label>
                            </div>
                        </div>
                        <div className="col-10">
                            This is the main timezone of concern. Please use one that corresponds to your timezone
                        </div>
                    </div>
                    <div className="col-10">
                        <Form>
                            <Form.Group controlId="formGender">
                                <div className="dropdown-with-caret">
                                    <Form.Control as="select" defaultValue="">
                                        <option>(GMT-4)Time zone in Washington, DC, USA</option>
                                        <option>UTC +5:30 IST India Standard Time New Delhi</option>
                                        <option>(GMT+2)Time zone in Brussels, Belgium</option>
                                        <option>(GMT+1)Time zone in United Kingdom</option>
                                        <option>(GMT+9)Time zone in Seoul, South Korea </option>
                                    </Form.Control>
                                    <span className="caret" />
                                </div>
                            </Form.Group>
                        </Form>
                    </div>
                    <br />
                    <div className="Currency">
                        <div className="row align-items-center">
                            <div className="col-10 d-flex align-items-center">
                                <h5>
                                    <strong>Default Currency</strong>
                                </h5>
                                <button type="button" className="free-button ml-2">
                                    <strong>Free</strong>
                                </button>
                            </div>
                            <div className="col-2 text-right">
                                <label className="switch">
                                    <input type="checkbox" />
                                    <span className="slider round" />
                                </label>
                            </div>
                        </div>
                        <div className="col-10">
                            This is the default Currency of the concern. This will be used in formatting the bills for
                            example.
                        </div>
                    </div>
                    <div className="col-10">
                        <Form>
                            <Form.Group controlId="formGender">
                                <div className="dropdown-with-caret">
                                    <Form.Control as="select" defaultValue="">
                                        <option>Dollar (USA) </option>
                                        <option>India (INR)</option>
                                        <option>Europe (EUR)</option>
                                        <option>UK (GBP)</option>
                                        <option>Singapore (SGD)</option>
                                    </Form.Control>
                                    <span className="caret" />
                                </div>
                            </Form.Group>
                        </Form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FeatureFlag;
