import React, { ReactElement } from 'react';
import { Notyf } from 'notyf';
import 'notyf/notyf.min.css';
import './notyf.scss';

const notyf = new Notyf();

type NotificationContextProps = {
    children: ReactElement;
};

type NotificationContextType = {
    triggerSuccess: (message: string) => void;
    triggerError: (message: string) => void;
};

export const NotificationContext = React.createContext<NotificationContextType>({
    triggerSuccess: async () => undefined,
    triggerError: async () => undefined,
});

const handleTriggerSuccess = (message: string, duration = 10000) => {
    notyf.success({
        message,
        dismissible: true,
        duration: duration,
        position: {
            x: 'center',
            y: 'top',
        },
        className: 'notification-alert',
    });
};

const handleTriggerError = (message: string, duration = 10000) => {
    notyf.error({
        message,
        dismissible: true,
        duration: duration,
        position: {
            x: 'center',
            y: 'top',
        },
        className: 'notification-alert',
    });
};

export default (props: NotificationContextProps) => {
    const value = {
        triggerSuccess: handleTriggerSuccess,
        triggerError: handleTriggerError,
    };

    return <NotificationContext.Provider value={value}>{props.children}</NotificationContext.Provider>;
};
