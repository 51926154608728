import React from 'react';
import { Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';

type LogoutWarningModalProp = {
    showModal: boolean;
};
export const LogoutWarningModal = (prop: LogoutWarningModalProp) => {
    return (
        <Modal show={prop.showModal} centered={true}>
            <Modal.Header closeButton className="bg-warning">
                <Modal.Title className="text-dark fs-6">
                    <FontAwesomeIcon icon={icon({ name: 'triangle-exclamation' })} size="lg" />
                    <span className="ms-2"> Session Timed Out</span>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="fs-5 text-dark text-center">
                <p>You session is expired</p>
                <p>
                    Logging out now
                    <div className="spinner-border spinner-border-sm ms-2" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </p>
            </Modal.Body>
        </Modal>
    );
};
