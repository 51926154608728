import React, { useRef } from 'react';
import './Role.css';

const EmployeeDetail: React.FC = () => {
    const cardARef = useRef<HTMLDivElement>(null);
    const cardBRef = useRef<HTMLDivElement>(null);
    const cardCRef = useRef<HTMLDivElement>(null);
    const cardDRef = useRef<HTMLDivElement>(null);

    const scrollToCard = (ref: React.RefObject<HTMLDivElement>) => {
        if (ref.current) {
            const topOffset = ref.current.getBoundingClientRect().top + window.pageYOffset - 140; // Adjust '100' as needed for header height or padding
            window.scrollTo({
                top: topOffset,
                behavior: 'smooth',
            });
        }
    };

    return (
        <div className="container">
            <div className="cardname-container">
                <div className="name-container row">
                    <h4>
                        <strong>Reception</strong>
                    </h4>
                </div>
                <div className="col-12 pl-3 pt-3 card-container">
                    <div onClick={() => scrollToCard(cardARef)}>
                        <strong>General Info</strong>
                    </div>
                    <div onClick={() => scrollToCard(cardBRef)}>
                        <strong>Card B</strong>
                    </div>
                    <div onClick={() => scrollToCard(cardCRef)}>
                        <strong>Card C</strong>
                    </div>
                    <div onClick={() => scrollToCard(cardDRef)}>
                        <strong>Card D</strong>
                    </div>
                </div>
            </div>

            <div className="card-detail">
                {/* Basic Card-1 */}
                <div ref={cardARef} className="p-4">
                    {''}
                </div>
                <div className="card shadow mb-4">
                    <div className="card-header py-4">
                        <h6 className="m-0 font-weight-bold text-black">
                            <strong>General Info</strong>
                        </h6>
                    </div>
                    <div className="details-container">
                        <div className="col-12">
                            <div className="card-body">
                                <div className="info-row">
                                    <p>
                                        <strong>Role Title:</strong> Reception
                                    </p>
                                    <p>
                                        <strong>Role Description: </strong>Handling of reception activities
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Basic Card-2 */}
                <div ref={cardBRef} className="card shadow mb-4">
                    <div className="card-header py-3">
                        <h6 className="m-0 font-weight-bold text-black">
                            <strong>Card B</strong>
                        </h6>
                    </div>
                    <div className="card-body">
                        The styling for this basic card example is created by using default Bootstrap utility classes. By
                        using utility classes, the style of the card component can be easily modified with no need for any
                        custom CSS!
                    </div>
                </div>
                {/* Basic Card-3 */}
                <div ref={cardCRef} className="card shadow mb-4">
                    <div className="card-header py-3">
                        <h6 className="m-0 font-weight-bold text-black">
                            <strong>Card C</strong>
                        </h6>
                    </div>
                    <div className="card-body">
                        The styling for this basic card example is created by using default Bootstrap utility classes. By
                        using utility classes, the style of the card component can be easily modified with no need for any
                        custom CSS!
                    </div>
                </div>
                {/* Basic Card-4 */}
                <div ref={cardDRef} className="card shadow mb-4">
                    <div className="card-header py-3">
                        <h6 className="m-0 font-weight-bold text-black">
                            <strong>Card D</strong>
                        </h6>
                    </div>
                    <div className="card-body">
                        The styling for this basic card example is created by using default Bootstrap utility classes. By
                        using utility classes, the style of the card component can be easily modified with no need for any
                        custom CSS!
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EmployeeDetail;
