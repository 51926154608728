import { createSelector } from '@reduxjs/toolkit';
import { IRootState } from '../store';
import { SUPER_CLIENT_CODE } from '../constants/app';
import { ApplicationUserLevel } from '../types/app';

// Define selector functions to extract various user properties from the state
const selectClient = (state: IRootState) => state.client;
const selectState = (state: IRootState) => state;

// Create memoized selectors using createSelector
export const makeSelectClientCode = createSelector(selectClient, (client) => client.code);
export const makeSelectClientName = createSelector(selectClient, (client) => client.name);

export const isClientFlowActive = createSelector(selectState, (state) => {
    if (state.client.code !== SUPER_CLIENT_CODE && state.user.userLevel === ApplicationUserLevel.CLIENT) {
        return true;
    }
    return false;
});
