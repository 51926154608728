import React, { useRef, useState } from 'react';
import { Button, Form, Modal, Row } from 'react-bootstrap';
import PageHeader from '../../../common/Header/PageHeader';
import './Employee.css';

interface Role {
    name: string;
    description: string;
    isSelected: boolean;
}

const EmployeeDetail: React.FC = () => {
    const cardARef = useRef<HTMLDivElement>(null);
    const cardBRef = useRef<HTMLDivElement>(null);
    const cardCRef = useRef<HTMLDivElement>(null);
    const cardDRef = useRef<HTMLDivElement>(null);

    const scrollToCard = (ref: React.RefObject<HTMLDivElement>) => {
        if (ref.current) {
            const topOffset = ref.current.getBoundingClientRect().top + window.pageYOffset - 140; // Adjust '100' as needed for header height or padding
            window.scrollTo({
                top: topOffset,
                behavior: 'smooth',
            });
        }
    };

    const [showModal, setShowModal] = useState(false);
    const [roles, setRoles] = useState<Role[]>([
        { name: 'Reception', description: 'Handling of reception activities', isSelected: true },
        { name: 'Call Center', description: 'Handling of call center activities', isSelected: true },
        { name: 'Finance Manager', description: 'Oversees financial operations', isSelected: true },
        { name: 'Inventory Manager', description: 'Manages inventory and stock', isSelected: true },
    ]);

    // Temporary state for storing changes while modal is open
    const [tempRoles, setTempRoles] = useState<Role[]>([]);

    const handleClose = () => {
        setShowModal(false);
    };

    const handleShow = () => {
        setTempRoles([...roles]); // Initialize tempRoles with current roles when modal is shown
        setShowModal(true);
    };

    const toggleRoleInTemp = (roleName: string) => {
        setTempRoles((prevRoles) =>
            prevRoles.map((role) => (role.name === roleName ? { ...role, isSelected: !role.isSelected } : role))
        );
    };

    const handleSave = () => {
        setRoles([...tempRoles]); // Update main roles state only when Save is clicked
        handleClose();
    };

    return (
        <div className="container">
            <div className="cardname-container">
                <div className="name-container row">
                    <h4>
                        <strong>John Coffey</strong>
                    </h4>
                </div>
                <div className="col-12 pl-3 pt-3 card-container">
                    <div onClick={() => scrollToCard(cardARef)}>
                        <strong>General Info</strong>
                    </div>
                    <div onClick={() => scrollToCard(cardBRef)}>
                        <strong>Roles</strong>
                    </div>
                    <div onClick={() => scrollToCard(cardCRef)}>
                        <strong>Card C</strong>
                    </div>
                    <div onClick={() => scrollToCard(cardDRef)}>
                        <strong>Card D</strong>
                    </div>
                </div>
            </div>

            <div className="card-detail">
                {/* Basic Card-1*/}
                <div ref={cardARef} className="p-4">
                    {''}
                </div>
                <div className="card shadow mb-4">
                    <div className="card-header py-4">
                        <h6 className="m-0 font-weight-bold text-black">
                            <strong>General Info</strong>
                        </h6>
                    </div>
                    <div className="details-container">
                        <div className="col-12">
                            <div className="card-body">
                                <div className="info-row">
                                    <p>
                                        <strong>First Name:</strong> John
                                    </p>
                                    <p>
                                        <strong>Last Name:</strong> Coffey
                                    </p>
                                    <p>
                                        <strong>Code:</strong> JD123
                                    </p>
                                    <p>
                                        <strong>ID:</strong> 63382
                                    </p>
                                    <p>
                                        <strong>Employee Category:</strong> Accounting
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Basic Card-2*/}
                <div className="card shadow mb-4">
                    <div ref={cardBRef} className="card-body">
                        <div className="card-header py-3">
                            <h6 className="m-0 font-weight-bold text-black">
                                <strong>Roles</strong>
                            </h6>
                        </div>
                        <PageHeader title={''} />
                        {/* Table with Name and Description */}
                        <div className="employee-container">
                            <table className="employee-table">
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Description</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {roles
                                        .filter((role) => role.isSelected)
                                        .map((role, index) => (
                                            <tr key={index}>
                                                <td>{role.name}</td>
                                                <td>{role.description}</td>
                                            </tr>
                                        ))}
                                </tbody>
                            </table>
                        </div>
                        {/* Configure Columns Button */}
                        <div className="text-end pt-3">
                            <Button variant="primary" onClick={handleShow}>
                                Edit Roles
                            </Button>
                        </div>

                        {/* Modal for edit roles */}
                        <Modal show={showModal} onHide={handleClose} size="lg" backdrop="static" keyboard={false}>
                            <Modal.Header style={{ color: 'black', background: '#fff' }} closeButton>
                                <Modal.Title>
                                    <strong>Edit Roles</strong>
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div style={{ height: '300px', overflow: 'auto' }}>
                                    <div className="container-xl px-4 mt-4">
                                        <Row>
                                            <div className="card-body">
                                                <Form>
                                                    <table className="employee-table">
                                                        <thead>
                                                            <tr>
                                                                <th>Role</th>
                                                                <th>Description</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {tempRoles.map((role, index) => (
                                                                <tr key={index}>
                                                                    <td>
                                                                        <strong>
                                                                            <Form.Check
                                                                                type="checkbox"
                                                                                label={
                                                                                    <span style={{ fontWeight: 'bold' }}>
                                                                                        {role.name}
                                                                                    </span>
                                                                                }
                                                                                checked={role.isSelected}
                                                                                onChange={() => toggleRoleInTemp(role.name)}
                                                                            />
                                                                        </strong>
                                                                    </td>
                                                                    <td>{role.description}</td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </Form>
                                            </div>
                                        </Row>
                                    </div>
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={handleClose}>
                                    Close
                                </Button>
                                <Button variant="primary" onClick={handleSave}>
                                    Save
                                </Button>
                            </Modal.Footer>
                        </Modal>
                    </div>
                </div>

                {/* Basic Card-3*/}
                <div ref={cardCRef} className="card shadow mb-4">
                    <div className="card-header py-3">
                        <h6 className="m-0 font-weight-bold text-black">
                            <strong>Card C</strong>
                        </h6>
                    </div>
                    <div className="card-body">
                        The styling for this basic card example is created by using default Bootstrap utility classes. By
                        using utility classes, the style of the card component can be easily modified with no need for any
                        custom CSS!
                    </div>
                </div>

                {/* Basic Card-4*/}
                <div ref={cardDRef} className="card shadow mb-4">
                    <div className="card-header py-3">
                        <h6 className="m-0 font-weight-bold text-black">
                            <strong>Card D</strong>
                        </h6>
                    </div>
                    <div className="card-body">
                        The styling for this basic card example is created by using default Bootstrap utility classes. By
                        using utility classes, the style of the card component can be easily modified with no need for any
                        custom CSS!
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EmployeeDetail;
