import React from 'react';
import { PageHeaderType } from '../../../types/menu';
import PageHeader from '../../common/Header/PageHeader';

export const EmbedsquareSetup = () => {
    const pageHeader: PageHeaderType = {
        title: 'feature-controller',
        prevNavLabel: 'Dashboard',
        prevNavLink: '/client/landing',
        iconClass: 'fa-cogs',
    };
    return (
        <div className="container-fluid">
            <PageHeader {...pageHeader} />
        </div>
    );
};
