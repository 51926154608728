import React, { ReactElement } from 'react';
import Header from '../../common/Header/Header';
import Sidebar from './Sidebar';

type Prop = {
    children: ReactElement;
};
const AdminSidebarLayout = ({ children }: Prop) => {
    return (
        <div id="wrapper">
            <Sidebar />
            <div id="content-wrapper" className="d-flex flex-column">
                <Header />
                <>{children}</>
            </div>
        </div>
    );
};

export default AdminSidebarLayout;
