import React, { useRef, useState } from 'react';
import { Form, Button, Modal, Row, Col } from 'react-bootstrap';
import PageHeader from '../../../common/Header/PageHeader';
import './Location.css';

const LocationDetail: React.FC = () => {
    const cardARef = useRef<HTMLDivElement>(null);
    const cardBRef = useRef<HTMLDivElement>(null);
    const cardCRef = useRef<HTMLDivElement>(null);
    const cardDRef = useRef<HTMLDivElement>(null);

    const scrollToCard = (ref: React.RefObject<HTMLDivElement>) => {
        if (ref.current) {
            const topOffset = ref.current.getBoundingClientRect().top + window.pageYOffset - 140; // Adjust '100' as needed for header height or padding
            window.scrollTo({
                top: topOffset,
                behavior: 'smooth',
            });
        }
    };

    // State for actual columns display
    const [columns, setColumns] = useState({
        SubjectOrder: true,
        Name: true,
        ShortDescription: true,
        MaximumAge: true,
        SubjectSearchCategory: true,
        ReportGroup: true,
        EndBookDate: true,
    });

    // Temporary state for modal
    const [tempColumns, setTempColumns] = useState(columns);

    const [showModal, setShowModal] = useState(false);
    const [showConfigModal, setShowConfigModal] = useState(false);

    const handleModalShow = () => setShowModal(true);
    const handleModalClose = () => setShowModal(false);

    const handleConfigModalShow = () => {
        setTempColumns(columns); // Sync tempColumns with current columns state
        setShowConfigModal(true);
    };

    const handleConfigModalClose = () => setShowConfigModal(false);

    const handleConfigModalSave = () => {
        setColumns(tempColumns); // Save the temporary columns state to actual columns
        setShowConfigModal(false);
    };

    return (
        <div className="container">
            {/* Sticky Card Name Container */}
            <div className="cardname-container">
                <div className="name-container row">
                    <h4>
                        <strong>Verina</strong>
                    </h4>
                </div>
                <div className="col-12 pl-4 pt-3 card-container">
                    <div onClick={() => scrollToCard(cardARef)}>
                        <strong>General Info</strong>
                    </div>
                    <div onClick={() => scrollToCard(cardBRef)}>
                        <strong>Subjects</strong>
                    </div>
                    <div onClick={() => scrollToCard(cardCRef)}>
                        <strong>Card C</strong>
                    </div>
                    <div onClick={() => scrollToCard(cardDRef)}>
                        <strong>Card D</strong>
                    </div>
                </div>
            </div>

            <div className="card-detail">
                {/* Basic Card-1*/}
                <div ref={cardARef} className="p-4">
                    {''}
                </div>
                <div className="card shadow mb-4">
                    <div className="card-header py-4">
                        <h6 className="m-0 font-weight-bold text-black">
                            <strong>General Info</strong>
                        </h6>
                    </div>
                    <div className="details-container">
                        <div className="col-12">
                            <div className="card-body">
                                <div className="info-row">
                                    <p>
                                        <strong>Location Name:</strong> Verina
                                    </p>
                                    <p>
                                        <strong>Location Code: </strong>1101
                                    </p>
                                    <p>
                                        <strong>Location ID: </strong>VER01
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Basic Card-2*/}
                <div ref={cardBRef} className="card shadow mb-4">
                    <div className="card-body">
                        <div className="card-header py-3">
                            <h6 className="m-0 font-weight-bold text-black">
                                <strong>Subjects</strong>
                            </h6>
                        </div>
                        <PageHeader title={''} />
                        {/* Table with Subject Order, Name, Short Description */}
                        <div className="employee-container">
                            <table className="employee-table">
                                <thead>
                                    <tr>
                                        {columns.SubjectOrder && <th>Subject order</th>}
                                        {columns.Name && <th>Name</th>}
                                        {columns.ShortDescription && <th>Short description</th>}
                                        {columns.MaximumAge && <th>Maximum Age</th>}
                                        {columns.SubjectSearchCategory && <th>Subject Search Category</th>}
                                        {columns.ReportGroup && <th>Report Group</th>}
                                        {columns.EndBookDate && <th>End Book Date</th>}
                                    </tr>
                                </thead>

                                <tbody>
                                    <tr>
                                        {columns.SubjectOrder && <td>2</td>}
                                        {columns.Name && <td>Adult</td>}
                                        {columns.ShortDescription && <td>18 - 54 years</td>}
                                        {columns.MaximumAge && <td>54</td>}
                                        {columns.SubjectSearchCategory && <td>Adults</td>}
                                        {columns.ReportGroup && <td>adult/grown-up</td>}
                                        {columns.EndBookDate && <td />}
                                    </tr>
                                    <tr>
                                        {columns.SubjectOrder && <td>3</td>}
                                        {columns.Name && <td>Youngster</td>}
                                        {columns.ShortDescription && <td>13 - 17 years</td>}
                                        {columns.MaximumAge && <td>17</td>}
                                        {columns.SubjectSearchCategory && <td>Adults</td>}
                                        {columns.ReportGroup && <td>teenager/adolescent</td>}
                                        {columns.EndBookDate && <td />}
                                    </tr>
                                    <tr>
                                        {columns.SubjectOrder && <td>4</td>}
                                        {columns.Name && <td>Child</td>}
                                        {columns.ShortDescription && <td>5 - 12 years</td>}
                                        {columns.MaximumAge && <td>12</td>}
                                        {columns.SubjectSearchCategory && <td>child</td>}
                                        {columns.ReportGroup && <td>child/youngster</td>}
                                        {columns.EndBookDate && <td />}
                                    </tr>
                                    <tr>
                                        {columns.SubjectOrder && <td>6</td>}
                                        {columns.Name && <td>Baby</td>}
                                        {columns.ShortDescription && <td>under 3 years</td>}
                                        {columns.MaximumAge && <td>2</td>}
                                        {columns.SubjectSearchCategory && <td>Infant</td>}
                                        {columns.ReportGroup && <td>Baby</td>}
                                        {columns.EndBookDate && <td />}
                                    </tr>
                                    <tr>
                                        {columns.SubjectOrder && <td>7</td>}
                                        {columns.Name && <td>55+</td>}
                                        {columns.ShortDescription && <td />}
                                        {columns.MaximumAge && <td>12</td>}
                                        {columns.SubjectSearchCategory && <td>Senior</td>}
                                        {columns.ReportGroup && <td>senior</td>}
                                        {columns.EndBookDate && <td />}
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        {/* Configure Column Modal */}
                        <Modal
                            show={showConfigModal}
                            onHide={handleConfigModalClose}
                            size="lg"
                            backdrop="static"
                            keyboard={false}
                        >
                            <Modal.Header style={{ color: 'black', background: '#fff' }} closeButton>
                                <Modal.Title>Configure Columns</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Form>
                                    <Form.Group controlId="configureColumns">
                                        <div className="card-header">
                                            <Form.Label>Select Columns to Display</Form.Label>
                                        </div>
                                        <Form.Check
                                            type="checkbox"
                                            label="Subject order"
                                            checked={tempColumns.SubjectOrder}
                                            onChange={() =>
                                                setTempColumns({
                                                    ...tempColumns,
                                                    SubjectOrder: !tempColumns.SubjectOrder,
                                                })
                                            }
                                        />
                                        <Form.Check
                                            type="checkbox"
                                            label="Name"
                                            checked={tempColumns.Name}
                                            onChange={() =>
                                                setTempColumns({
                                                    ...tempColumns,
                                                    Name: !tempColumns.Name,
                                                })
                                            }
                                        />
                                        <Form.Check
                                            type="checkbox"
                                            label="Short description"
                                            checked={tempColumns.ShortDescription}
                                            onChange={() =>
                                                setTempColumns({
                                                    ...tempColumns,
                                                    ShortDescription: !tempColumns.ShortDescription,
                                                })
                                            }
                                        />
                                        <Form.Check
                                            type="checkbox"
                                            label="Maximum Age"
                                            checked={tempColumns.MaximumAge}
                                            onChange={() =>
                                                setTempColumns({
                                                    ...tempColumns,
                                                    MaximumAge: !tempColumns.MaximumAge,
                                                })
                                            }
                                        />
                                        <Form.Check
                                            type="checkbox"
                                            label="Subject Search Category"
                                            checked={tempColumns.SubjectSearchCategory}
                                            onChange={() =>
                                                setTempColumns({
                                                    ...tempColumns,
                                                    SubjectSearchCategory: !tempColumns.SubjectSearchCategory,
                                                })
                                            }
                                        />
                                        <Form.Check
                                            type="checkbox"
                                            label="Report Group"
                                            checked={tempColumns.ReportGroup}
                                            onChange={() =>
                                                setTempColumns({
                                                    ...tempColumns,
                                                    ReportGroup: !tempColumns.ReportGroup,
                                                })
                                            }
                                        />
                                        <Form.Check
                                            type="checkbox"
                                            label="End Book Date"
                                            checked={tempColumns.EndBookDate}
                                            onChange={() =>
                                                setTempColumns({
                                                    ...tempColumns,
                                                    EndBookDate: !tempColumns.EndBookDate,
                                                })
                                            }
                                        />
                                    </Form.Group>
                                </Form>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={handleConfigModalClose}>
                                    Close
                                </Button>
                                <Button variant="primary" onClick={handleConfigModalSave}>
                                    Save
                                </Button>
                            </Modal.Footer>
                        </Modal>
                        <div className="text-end pt-3">
                            {/* Search Bar with Configure Column Button */}
                            <Button variant="primary mr-4" onClick={handleConfigModalShow}>
                                <i className="fas fa-cogs configure-icon" />
                                Configure Columns
                            </Button>
                            {/* Edit Subject Button */}
                            <Button variant="primary" onClick={handleModalShow}>
                                Create Subjects
                            </Button>
                        </div>

                        <Modal
                            show={showModal}
                            onHide={handleModalClose}
                            size="lg"
                            backdrop="static" // Disable clicking outside to close
                            keyboard={false} // Disable pressing 'Esc' to close
                        >
                            <Modal.Header style={{ color: 'black', background: '#fff' }} closeButton>
                                <Modal.Title>
                                    <strong>Edit Subject</strong>
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div style={{ height: '350px', overflow: 'auto' }}>
                                    <div className="container-xl px-4 mt-4">
                                        <Row>
                                            <Col md={12}>
                                                <div className="card mb-4">
                                                    <div className="card-header">
                                                        <strong>General Details</strong>
                                                    </div>
                                                    <div className="card-body">
                                                        <Form>
                                                            <Form.Group controlId="subjectOrder">
                                                                <Form.Label>
                                                                    <strong>Subject Name</strong>
                                                                </Form.Label>
                                                                <Form.Control type="text" placeholder="" />
                                                            </Form.Group>
                                                            <br />
                                                            <Form.Group controlId="subjectName">
                                                                <Form.Label>
                                                                    <strong>Short Description</strong>
                                                                </Form.Label>
                                                                <Form.Control type="text" placeholder="" />
                                                            </Form.Group>
                                                            <br />
                                                            <Form.Group controlId="shortDescription">
                                                                <Form.Label>
                                                                    <strong>Maximum Age(yrs)</strong>
                                                                </Form.Label>
                                                                <Form.Control type="text" placeholder="" />
                                                            </Form.Group>
                                                            <br />
                                                            <Form.Group controlId="subjectsearchcategory">
                                                                <Form.Label>
                                                                    <strong>Subject Search Category</strong>
                                                                </Form.Label>
                                                                <div className="dropdown-with-caret">
                                                                    <Form.Control as="select" defaultValue="">
                                                                        <option value="">Choose your subject</option>
                                                                        <option>Senior</option>
                                                                        <option>Adults</option>
                                                                        <option>Child</option>
                                                                        <option>Infant</option>
                                                                    </Form.Control>
                                                                    <span className="caret" />
                                                                </div>
                                                            </Form.Group>
                                                            <br />
                                                            <Form.Group controlId="reportgroup">
                                                                <Form.Label>
                                                                    <strong>Report Group</strong>
                                                                </Form.Label>
                                                                <div className="dropdown-with-caret">
                                                                    <Form.Control as="select" defaultValue="">
                                                                        <option value="">Choose your report group</option>
                                                                        <option>adult/grown-up</option>
                                                                        <option>teenager/adolescent</option>
                                                                        <option>child/youngster</option>
                                                                        <option>Baby</option>
                                                                        <option>senior</option>
                                                                    </Form.Control>
                                                                    <span className="caret" />
                                                                </div>
                                                            </Form.Group>
                                                            <br />
                                                            <Form.Group controlId="formDob">
                                                                <Form.Label>
                                                                    <strong>End Book Date</strong>
                                                                </Form.Label>
                                                                <Form.Control type="date" defaultValue="" />
                                                            </Form.Group>
                                                        </Form>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={handleModalClose}>
                                    Close
                                </Button>
                                <Button variant="primary" onClick={handleModalClose}>
                                    Save
                                </Button>
                            </Modal.Footer>
                        </Modal>
                    </div>
                </div>
                {/* Basic Card-3*/}
                <div ref={cardCRef} className="card shadow mb-4">
                    <div className="card-header py-3">
                        <h6 className="m-0 font-weight-bold text-black">
                            <strong>Card C</strong>
                        </h6>
                    </div>
                    <div className="card-body">
                        The styling for this basic card example is created by using default Bootstrap utility classes. By
                        using utility classes, the style of the card component can be easily modified with no need for any
                        custom CSS!
                    </div>
                </div>
                {/* Basic Card-4*/}
                <div ref={cardDRef} className="card shadow mb-4">
                    <div className="card-header py-3">
                        <h6 className="m-0 font-weight-bold text-black">
                            <strong>Card D</strong>
                        </h6>
                    </div>
                    <div className="card-body">
                        The styling for this basic card example is created by using default Bootstrap utility classes. By
                        using utility classes, the style of the card component can be easily modified with no need for any
                        custom CSS!
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LocationDetail;
