import React from 'react';
import cn from 'classnames';
import { useLocation } from 'react-router-dom';
import { NavItem, NavLink } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { MenuItemType } from '../../../types/menu';

const MenuItem = (props: MenuItemType) => {
    const { path, label, faIconClass } = props;
    const location = useLocation();
    const currentPath = location.pathname.split('/')[1].toLowerCase();
    const isActive =
        (!currentPath && label.toLowerCase() === 'dashboard') || currentPath === label.toLowerCase() ? true : false;
    return (
        <NavItem
            className={cn('nav-item', {
                active: isActive,
            })}
        >
            <NavLink as={Link} className="nav-link" to={path}>
                <i className={`fas fa-fw ${faIconClass}`} /> {/* Adjust the icon name as needed */}
                <span>{label}</span>
            </NavLink>
        </NavItem>
    );
};

export default MenuItem;
