import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap'; // Added Form for form controls
import { Link } from 'react-router-dom'; // Import Link for navigation
import { PageHeaderType } from '../../../../types/menu';
import PageHeader from '../../../common/Header/PageHeader';

export const RateAccomodationType = () => {
    const [showModal, setShowModal] = useState(false);

    // State for actual columns display
    const [columns, setColumns] = useState({
        accomodationName: true,
        lastName: true,
        accomodationCode: true,
        accomodationID: true,
    });

    // Temporary state for modal
    const [tempColumns, setTempColumns] = useState(columns);

    const pageHeader: PageHeaderType = {
        title: 'Accomodation Type',
        prevNavLabel: 'Dashboard',
        prevNavLink: '/client/landing',
        iconClass: 'fa-hotel',
    };

    const handleConfigureColumnsClick = () => {
        setTempColumns(columns); // Sync tempColumns with current columns state
        setShowModal(true);
    };

    const handleClose = () => {
        setShowModal(false);
    };

    // Function to handle checkbox changes in modal
    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { id, checked } = event.target;
        setTempColumns((prevState) => ({
            ...prevState,
            [id]: checked,
        }));
    };

    // Function to save changes
    const handleSave = () => {
        setColumns(tempColumns);
        handleClose();
    };

    return (
        <div className="container-fluid">
            <PageHeader {...pageHeader} />
            <div className="main-container">
                <div className="configure-columns" onClick={handleConfigureColumnsClick}>
                    <i className="fas fa-cogs configure-icon" />
                    <span>Configure Columns</span>
                </div>
                <div className="employee-container">
                    <table className="employee-table">
                        <thead>
                            <tr>
                                {columns.accomodationName && (
                                    <th>
                                        Accomodation Name
                                        <i className="fas fa-sort ml-2" />
                                    </th>
                                )}
                                {columns.accomodationCode && (
                                    <th>
                                        Accomodation Code
                                        <i className="fas fa-sort ml-2" />
                                    </th>
                                )}
                                {columns.accomodationID && (
                                    <th>
                                        Accomodation ID
                                        <i className="fas fa-sort ml-2" />
                                    </th>
                                )}
                            </tr>
                        </thead>
                        <tbody>
                            {/* Your table rows */}
                            <tr>
                                {columns.accomodationName && (
                                    <td>
                                        <Link to="/client/rate-manager/accomodationtype/accomodationtype-detail">
                                            Building A
                                        </Link>
                                    </td>
                                )}

                                {columns.accomodationCode && (
                                    <td>
                                        <Link to="/client/rate-manager/accomodationtype/accomodationtype-detail">A001</Link>
                                    </td>
                                )}
                                {columns.accomodationID && (
                                    <td>
                                        <Link to="/client/rate-manager/accomodationtype/accomodationtype-detail">35368</Link>
                                    </td>
                                )}
                            </tr>
                            <tr>
                                {columns.accomodationName && (
                                    <td>
                                        <Link to="/client/rate-manager/accomodationtype/accomodationtype-detail">
                                            Building B
                                        </Link>
                                    </td>
                                )}

                                {columns.accomodationCode && (
                                    <td>
                                        <Link to="/client/rate-manager/accomodationtype/accomodationtype-detail">B001</Link>
                                    </td>
                                )}
                                {columns.accomodationID && (
                                    <td>
                                        <Link to="/client/rate-manager/accomodationtype/accomodationtype-detail">85543</Link>
                                    </td>
                                )}
                            </tr>
                            <tr>
                                {columns.accomodationName && (
                                    <td>
                                        <Link to="/client/rate-manager/accomodationtype/accomodationtype-detail">
                                            Building C
                                        </Link>
                                    </td>
                                )}

                                {columns.accomodationCode && (
                                    <td>
                                        <Link to="/client/rate-manager/accomodationtype/accomodationtype-detail">C001</Link>
                                    </td>
                                )}
                                {columns.accomodationID && (
                                    <td>
                                        <Link to="/client/rate-manager/accomodationtype/accomodationtype-detail">82623</Link>
                                    </td>
                                )}
                            </tr>
                            <tr>
                                {columns.accomodationName && (
                                    <td>
                                        <Link to="/client/rate-manager/accomodationtype/accomodationtype-detail">
                                            Building D
                                        </Link>
                                    </td>
                                )}

                                {columns.accomodationCode && (
                                    <td>
                                        <Link to="/client/rate-manager/accomodationtype/accomodationtype-detail">D001</Link>
                                    </td>
                                )}
                                {columns.accomodationID && (
                                    <td>
                                        <Link to="/client/rate-manager/accomodationtype/accomodationtype-detail">42125</Link>
                                    </td>
                                )}
                            </tr>
                            <tr>
                                {columns.accomodationName && (
                                    <td>
                                        <Link to="/client/rate-manager/accomodationtype/accomodationtype-detail">
                                            Building E
                                        </Link>
                                    </td>
                                )}

                                {columns.accomodationCode && (
                                    <td>
                                        <Link to="/client/rate-manager/accomodationtype/accomodationtype-detail">E001</Link>
                                    </td>
                                )}
                                {columns.accomodationID && (
                                    <td>
                                        <Link to="/client/rate-manager/accomodationtype/accomodationtype-detail">53663</Link>
                                    </td>
                                )}
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <Modal show={showModal} onHide={handleClose}>
                <Modal.Header style={{ color: 'black', background: '#89c8ff' }} closeButton>
                    <Modal.Title>Configure Columns</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Check
                            type="checkbox"
                            id="accomodationName"
                            label="Accomodation Name"
                            checked={tempColumns.accomodationName}
                            onChange={handleCheckboxChange}
                        />
                        <Form.Check
                            type="checkbox"
                            id="accomodationCode"
                            label="Accomodation Code"
                            checked={tempColumns.accomodationCode}
                            onChange={handleCheckboxChange}
                        />
                        <Form.Check
                            type="checkbox"
                            id="accomodationID"
                            label="Accodomation ID"
                            checked={tempColumns.accomodationID}
                            onChange={handleCheckboxChange}
                        />
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleSave}>
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default RateAccomodationType;
