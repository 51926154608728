import React, { useState } from 'react';
import cn from 'classnames';
import { Navbar, NavbarBrand } from 'react-bootstrap';
import AccordionMenuItem from '../../common/Menu/AccordianMenuItem';
import MenuItem from '../../common/Menu/MenuItem';
import { AccordionMenuType, MenuItemType } from '../../../types/menu';
import Menu from './Menu';

const Sidebar: React.FC = () => {
    const [toggleSidebar, setToggleSideBar] = useState(false);

    return (
        <Navbar
            className={cn('navbar-nav bg-primary sidebar sidebar-dark accordion', {
                toggled: toggleSidebar,
            })}
            id="accordionSidebar"
        >
            <NavbarBrand className="sidebar-brand d-flex align-items-center justify-content-center" href="index.html">
                <div className="sidebar-brand-text mx-3">PMS Frontend</div>
            </NavbarBrand>
            <hr className="sidebar-divider my-0" />

            {Object.entries(Menu).map(([key, menuItem]) => {
                if ('menuLinks' in menuItem) {
                    return <AccordionMenuItem key={key} {...(menuItem as AccordionMenuType)} />;
                } else {
                    return <MenuItem key={key} {...(menuItem as MenuItemType)} />;
                }
            })}

            <hr className="sidebar-divider d-none d-md-block" />

            <div className="text-center d-none d-md-inline">
                <button
                    className="rounded-circle border-0"
                    id="sidebarToggle"
                    onClick={() => setToggleSideBar(!toggleSidebar)}
                />
            </div>
        </Navbar>
    );
};

export default Sidebar;
