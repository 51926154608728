import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap'; // Added Form for form controls
import { Link } from 'react-router-dom'; // Import Link for navigation
import { PageHeaderType } from '../../../../types/menu';
import PageHeader from '../../../common/Header/PageHeader';
import './Units.css';
import CreateUnitsModal from './CreateUnitsModal';

export const Units = () => {
    const [showModal, setShowModal] = useState(false);
    const [createUnitsShowModal, setCreateUnitsShowModal] = useState(false);

    // State for actual columns display
    const [columns, setColumns] = useState({
        unitsName: true,
        lastName: true,
        unitsCode: true,
        unitsId: true,
    });

    // Temporary state for modal
    const [tempColumns, setTempColumns] = useState(columns);

    const handleCloseCreateUnitsShowModal = () => {
        setCreateUnitsShowModal(false);
    };

    const handleShowCreateUnitsShowModal = () => {
        setCreateUnitsShowModal(true);
    };

    const pageHeader: PageHeaderType = {
        title: 'Units',
        prevNavLabel: 'Dashboard',
        prevNavLink: '/client/landing',
        iconClass: 'fa-solid fa-building-un',
        modal: {
            showModalHandler: handleShowCreateUnitsShowModal,
        },
        createPageType: 'modal',
    };

    const handleConfigureColumnsClick = () => {
        setTempColumns(columns); // Sync tempColumns with current columns state
        setShowModal(true);
    };

    const handleClose = () => {
        setShowModal(false);
    };

    // Function to handle checkbox changes in modal
    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { id, checked } = event.target;
        setTempColumns((prevState) => ({
            ...prevState,
            [id]: checked,
        }));
    };

    // Function to save changes
    const handleSave = () => {
        setColumns(tempColumns);
        handleClose();
    };

    return (
        <div className="container-fluid">
            <PageHeader {...pageHeader} />
            <CreateUnitsModal showModal={createUnitsShowModal} handleClose={handleCloseCreateUnitsShowModal} />
            <div className="main-container">
                <div className="configure-columns" onClick={handleConfigureColumnsClick}>
                    <i className="fas fa-cogs configure-icon" />
                    <span>Configure Columns</span>
                </div>
                <div className="employee-container">
                    <table className="employee-table">
                        <thead>
                            <tr>
                                {columns.unitsName && (
                                    <th>
                                        Units Name
                                        <i className="fas fa-sort ml-2" />
                                    </th>
                                )}
                                {columns.unitsCode && (
                                    <th>
                                        Units Code
                                        <i className="fas fa-sort ml-2" />
                                    </th>
                                )}
                                {columns.unitsId && (
                                    <th>
                                        Units ID
                                        <i className="fas fa-sort ml-2" />
                                    </th>
                                )}
                            </tr>
                        </thead>
                        <tbody>
                            {/* Your table rows */}
                            <tr>
                                {columns.unitsName && (
                                    <td>
                                        <Link to="/client/inventory-manager/units/units-detail">Room A1</Link>
                                    </td>
                                )}

                                {columns.unitsCode && (
                                    <td>
                                        <Link to="/client/inventory-manager/units/units-detail">A123</Link>
                                    </td>
                                )}
                                {columns.unitsId && (
                                    <td>
                                        <Link to="/client/inventory-manager/units/units-detail">26788</Link>
                                    </td>
                                )}
                            </tr>
                            <tr>
                                {columns.unitsName && (
                                    <td>
                                        <Link to="/client/inventory-manager/units/units-detail">Room B2</Link>
                                    </td>
                                )}

                                {columns.unitsCode && (
                                    <td>
                                        <Link to="/client/inventory-manager/units/units-detail">B124</Link>
                                    </td>
                                )}
                                {columns.unitsId && (
                                    <td>
                                        <Link to="/client/inventory-manager/units/units-detail">82363</Link>
                                    </td>
                                )}
                            </tr>
                            <tr>
                                {columns.unitsName && (
                                    <td>
                                        <Link to="/client/inventory-manager/units/units-detail">Room C3</Link>
                                    </td>
                                )}

                                {columns.unitsCode && (
                                    <td>
                                        <Link to="/client/inventory-manager/units/units-detail">C125</Link>
                                    </td>
                                )}
                                {columns.unitsId && (
                                    <td>
                                        <Link to="/client/inventory-manager/units/units-detail">26262</Link>
                                    </td>
                                )}
                            </tr>
                            <tr>
                                {columns.unitsName && (
                                    <td>
                                        <Link to="/client/inventory-manager/units/units-detail">Room D4</Link>
                                    </td>
                                )}

                                {columns.unitsCode && (
                                    <td>
                                        <Link to="/client/inventory-manager/units/units-detail">D126</Link>
                                    </td>
                                )}
                                {columns.unitsId && (
                                    <td>
                                        <Link to="/client/inventory-manager/units/units-detail">82632</Link>
                                    </td>
                                )}
                            </tr>
                            <tr>
                                {columns.unitsName && (
                                    <td>
                                        <Link to="/client/inventory-manager/units/units-detail">Room E5</Link>
                                    </td>
                                )}

                                {columns.unitsCode && (
                                    <td>
                                        <Link to="/client/inventory-manager/units/units-detail">E127</Link>
                                    </td>
                                )}
                                {columns.unitsId && (
                                    <td>
                                        <Link to="/client/inventory-manager/units/units-detail">62527</Link>
                                    </td>
                                )}
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <Modal show={showModal} onHide={handleClose}>
                <Modal.Header style={{ color: 'black', background: '#89c8ff' }} closeButton>
                    <Modal.Title>Configure Columns</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Check
                            type="checkbox"
                            id="unitsName"
                            label="Units Name"
                            checked={tempColumns.unitsName}
                            onChange={handleCheckboxChange}
                        />
                        <Form.Check
                            type="checkbox"
                            id="unitsCode"
                            label="Units Code"
                            checked={tempColumns.unitsCode}
                            onChange={handleCheckboxChange}
                        />
                        <Form.Check
                            type="checkbox"
                            id="unitsId"
                            label="Units ID"
                            checked={tempColumns.unitsId}
                            onChange={handleCheckboxChange}
                        />
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleSave}>
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default Units;
