/* eslint-disable no-console */
import React, { useState, ReactElement, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, IRootState } from '../../../store';
import { resetGlobalError } from '../../../store/errorHandler';
import { setAccessToken } from '../../../store/token';
import { useNotificationContext } from '../../../hooks/useNotificationContext';

// Create an instance of Notyf
const duration = 5000;
type Props = {
    children: ReactElement;
};
const GlobalErrorHandler = ({ children }: Props) => {
    const { triggerError } = useNotificationContext();
    const [isDisplayed, setIsDisplayed] = useState(false);
    const dispatch: AppDispatch = useDispatch();
    const { isError, message, showError } = useSelector((state: IRootState) => state.error);

    useEffect(() => {
        if (isError && showError) {
            // If token expires, set to null.
            if (message.includes(`is expired`)) {
                dispatch(setAccessToken(null));
            } else if (!isDisplayed) {
                setIsDisplayed(true);
                // Display an error notification
                triggerError(message);
                setTimeout(() => {
                    setIsDisplayed(false);
                    dispatch(resetGlobalError());
                }, duration);
            }
        }
    }, [isError, message, isDisplayed, dispatch, triggerError, showError]);

    return <>{children}</>;
};
export default GlobalErrorHandler;
