import React, { useState } from 'react';
import cn from 'classnames';
import { Navbar, NavbarBrand } from 'react-bootstrap';
import UserInfoDropDown from '../../common/Header/UserInfoDropDown';
import './Sidebar.css';
import Embedlogo from '../../common/Images/Embedlogo.png';
import BlockMenu from '../../common/Menu/BlockMenu';
import { BlockMenuType } from '../../../types/menu';

type MenuProps = {
    menus: BlockMenuType;
};
const Sidebar = (props: MenuProps) => {
    const [toggleSidebar, setToggleSideBar] = useState(false);

    return (
        <Navbar
            className={cn('navbar-nav sidebar bg-light sidebar-light', {
                toggled: toggleSidebar,
            })}
            id="accordionSidebar"
        >
            <NavbarBrand className="sidebar-brand d-flex align-items-center justify-content-center" href="/client/landing">
                <img src={Embedlogo} alt="Logo" className="sidebar-logo" />
            </NavbarBrand>
            <hr className="sidebar-divider my-0" />
            <div className="overflow-y-scroll vh-75">
                <div className="blockmenu-title">
                    <BlockMenu menuLabel={props.menus.menuLabel} menus={props.menus.menus} faIconClass="" icon={''} />
                </div>
            </div>
            <hr className="sidebar-divider d-none d-md-block" />
            <div className="text-center d-none d-md-inline">
                <button
                    className="rounded-circle border-0"
                    id="sidebarToggle"
                    aria-label="Toggle Sidebar"
                    onClick={() => setToggleSideBar(!toggleSidebar)}
                />
            </div>
            <div className="profile-container">
                <UserInfoDropDown dropdownMenu="dropdown-menu-up" />
            </div>
        </Navbar>
    );
};

export default Sidebar;
