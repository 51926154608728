import React from 'react';
import { NavLink } from 'react-router-dom';
import NotFoundImage from '../../../assets/img/404-error.svg';
import { useSelector } from '../../../store';
import { isClientFlowActive } from '../../../selectors/clientSelector';

const NotFound = () => {
    const isClientFlow = useSelector(isClientFlowActive);

    return (
        <div className="row justify-content-center">
            <div className="col-lg-6">
                <div className="text-center mt-4">
                    <img className="img-fluid p-4" src={NotFoundImage} alt="Content Not Found" />
                    <p className="lead">Oops! content you are looking for does not exists.</p>
                    <a className="text-arrow-icon" href="dashboard-1.html">
                        <i className="fa fa-arrow-left m-2" />
                        {isClientFlow ? (
                            <NavLink to="/client/landing">Return to Dashboard</NavLink>
                        ) : (
                            <NavLink to="/">Return to Dashboard</NavLink>
                        )}
                    </a>
                </div>
            </div>
        </div>
    );
};
export default NotFound;
