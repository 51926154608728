import React, { useState } from 'react';

export const ClipboardCopy = ({ text }: { text: string }) => {
    const [copied, setCopied] = useState(false);

    const copyToClipboard = () => {
        // Create a temporary textarea element
        const textarea = document.createElement('textarea');

        // Set the text content to the provided text
        textarea.value = text;

        // Make the textarea hidden
        textarea.style.position = 'fixed';
        textarea.style.opacity = '0';

        // Append the textarea to the document body
        document.body.appendChild(textarea);

        // Select the text within the textarea
        textarea.select();

        // Execute the copy command
        document.execCommand('copy');

        // Remove the textarea from the document body
        document.body.removeChild(textarea);

        // Set copied state to true
        setCopied(true);

        // Reset copied state after 2 seconds
        setTimeout(() => {
            setCopied(false);
        }, 2000);
    };

    return (
        <span className="clipboard-copy">
            {!copied && (
                <i
                    className="fas fa-copy text-gray-700 cursor-pointer"
                    onClick={copyToClipboard}
                    title="Copy to Clipboard"
                />
            )}
            {copied && <i className="fas fa-check-circle text-success" title="Copied" />}
        </span>
    );
};
