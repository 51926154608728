import React, { useRef, useState } from 'react';
import { Form, Modal, ModalFooter, Tab, Tabs } from 'react-bootstrap';

const AccomodationDetail: React.FC = () => {
    const cardARef = useRef<HTMLDivElement>(null);
    const cardBRef = useRef<HTMLDivElement>(null);
    const cardCRef = useRef<HTMLDivElement>(null);
    const cardDRef = useRef<HTMLDivElement>(null);

    const scrollToCard = (ref: React.RefObject<HTMLDivElement>) => {
        if (ref.current) {
            const topOffset = ref.current.getBoundingClientRect().top + window.pageYOffset - 140; // Adjust '100' as needed for header height or padding
            window.scrollTo({
                top: topOffset,
                behavior: 'smooth',
            });
        }
    };

    const [key, setKey] = useState<string>('rentability');
    const [showRowModal, setShowRowModal] = useState(false); // new state for row detail modal

    const handleRowShow = () => {
        setShowRowModal(true);
    };

    const handleRowClose = () => {
        setShowRowModal(false);
    };

    return (
        <div className="container">
            {/* Sticky Card Name Container */}
            <div className="cardname-container">
                <div className="name-container row">
                    <h4>
                        <strong>Building A</strong>
                    </h4>
                </div>
                <div className="col-12 pl-4 pt-3 card-container">
                    <div onClick={() => scrollToCard(cardARef)}>
                        <strong>General Info</strong>
                    </div>
                    <div onClick={() => scrollToCard(cardBRef)}>
                        <strong>Representations</strong>
                    </div>
                    <div onClick={() => scrollToCard(cardCRef)}>
                        <strong>Card C</strong>
                    </div>
                    <div onClick={() => scrollToCard(cardDRef)}>
                        <strong>Card D</strong>
                    </div>
                </div>
            </div>

            <div className="card-detail">
                {/* Basic Card-1*/}
                <div ref={cardARef} className="p-4">
                    {''}
                </div>
                <div className="card shadow mb-4">
                    <div className="card-header py-4">
                        <h6 className="m-0 font-weight-bold text-black">
                            <strong>General Info</strong>
                        </h6>
                    </div>
                    <div className="details-container">
                        <div className="col-12">
                            <div className="card-body">
                                <div className="info-row">
                                    <p>
                                        <strong>Accomodation Name:</strong> Building A
                                    </p>
                                    <p>
                                        <strong>Accomodation Code: </strong> A001
                                    </p>
                                    <p>
                                        <strong>Accomodation ID: </strong> 35368
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Basic Card-2*/}
                <div ref={cardBRef} className="card shadow mb-4">
                    <div className="card-header py-3">
                        <h6 className="m-0 font-weight-bold text-black">
                            <strong>Representations</strong>
                        </h6>
                    </div>
                    <div className="card-body">
                        <div className="row">
                            <div className="col-6">
                                <Form.Group controlId="formEmployeeCategory">
                                    <Form.Label>
                                        <strong>Distribution Channel</strong>
                                    </Form.Label>
                                    <div className="dropdown-with-caret">
                                        <Form.Control as="select">
                                            <option>All</option>
                                            <option>Booking.com</option>
                                            <option>Ticket.com</option>
                                            <option>Trip.com</option>
                                            <option>Hotelbeds.com</option>
                                            <option>Traveloka.com</option>
                                        </Form.Control>
                                        <span className="caret" />
                                    </div>
                                </Form.Group>
                            </div>
                            <div className="col-6 mt-4 text-end">
                                <i className="fa fa-history" aria-hidden="true" />
                                <button className="btn btn-sm mb-1">History</button>
                                <input type="checkbox" className="ml-2" id="showArchived" /> Show archived
                            </div>
                        </div>
                        <hr />
                        <div className="table-responsive">
                            <table className="employee-table">
                                <thead>
                                    <tr>
                                        <th>
                                            Name
                                            <i className="fas fa-sort ml-2" />
                                        </th>
                                        <th>
                                            Internal name
                                            <i className="fas fa-sort ml-2" />
                                        </th>
                                        <th>
                                            Linked rentability / rentability sets
                                            <i className="fas fa-sort ml-2" />
                                        </th>
                                        <th>
                                            DC/DC group linking
                                            <i className="fas fa-sort ml-2" />
                                        </th>
                                        <th>
                                            Status
                                            <i className="fas fa-sort ml-2" />
                                        </th>
                                        <th />
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr onClick={handleRowShow}>
                                        <td>Cottage 4</td>
                                        <td>-</td>
                                        <td>Rentability set(s) linked</td>
                                        <td>Individual DC(s) linked</td>
                                        <td>
                                            <span className="badge badge-success">Bookable</span>
                                            <span className="badge badge-success ml-2">Visible</span>
                                        </td>
                                        <td>
                                            <a href="#" className="text-danger">
                                                Archive
                                            </a>
                                        </td>
                                    </tr>
                                    {/* Modal to edit representation card row*/}
                                    <Modal
                                        show={showRowModal}
                                        onHide={handleRowClose}
                                        size="xl"
                                        backdrop="static" // Disable clicking outside to close
                                        keyboard={false} // Disable pressing 'Esc' to close
                                    >
                                        <Modal.Header style={{ color: 'black', background: '#fff' }} closeButton>
                                            <Modal.Title>
                                                <strong>Cottage 4</strong>
                                            </Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <Tabs
                                                activeKey={key}
                                                onSelect={(k: string | null) => k && setKey(k)}
                                                id="rentability"
                                                className="mb-3"
                                            >
                                                <Tab eventKey="rentability" title="Rentability">
                                                    <div style={{ height: '500px', overflow: 'auto' }}>
                                                        <div className="d-flex pt-3 text-black">
                                                            <div>
                                                                <label>
                                                                    <strong>Stay date from - to and including</strong>
                                                                </label>
                                                                <div className="d-flex align-items-center">
                                                                    <input type="date" className="form-control" />
                                                                    <i className="fa-solid fa-arrow-right mx-2" />
                                                                    <input type="date" className="form-control" />
                                                                </div>
                                                            </div>
                                                            <div className="ml-5">
                                                                <label>
                                                                    <strong>Book date from - to</strong>
                                                                </label>
                                                                <div className="d-flex align-items-center">
                                                                    <input type="date" className="form-control" />
                                                                    <i className="fa-solid fa-arrow-right mx-2" />
                                                                    <input type="date" className="form-control" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {/* Basic Card-1*/}
                                                        <div className="card mt-3">
                                                            <div className="card-header py-3">
                                                                <h6 className="m-0 font-weight-bold text-black">
                                                                    <strong>Rentability Sets</strong>
                                                                </h6>
                                                            </div>
                                                            <div className="card-body">
                                                                <div className="employee-container">
                                                                    <table className="employee-table mt-4">
                                                                        <thead>
                                                                            <tr>
                                                                                <th>Name</th>
                                                                                <th>Code</th>
                                                                                <th>Stay date from</th>
                                                                                <th>Stay date to (including)</th>
                                                                                <th>Book date from</th>
                                                                                <th>Book date to</th>
                                                                                <th />
                                                                            </tr>
                                                                        </thead>

                                                                        <tbody>
                                                                            <tr>
                                                                                <td>Royal Retreat</td>
                                                                                <td>RR31</td>
                                                                                <td>Fri 01/01.2021</td>
                                                                                <td>Sun 03/11/2024</td>
                                                                                <td>Tue 28/12/2021</td>
                                                                                <td>Sun 03/11/2024</td>

                                                                                <td style={{ color: 'red' }}>Remove</td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                                <br />
                                                            </div>
                                                        </div>
                                                        {/* Basic Card-2*/}
                                                        <div className="card mt-4">
                                                            <div className="card-header py-3">
                                                                <h6 className="m-0 font-weight-bold text-black">
                                                                    <strong>Rentability rules</strong>
                                                                </h6>
                                                            </div>
                                                            <div className="card-body">
                                                                <div className="employee-container">
                                                                    <table className="employee-table mt-4">
                                                                        <thead>
                                                                            <tr>
                                                                                <th>Stay date from</th>
                                                                                <th>Stay date to</th>
                                                                                <th>Length of stay</th>
                                                                                <th>Deviation</th>
                                                                                <th>Gap between two bookings</th>
                                                                                <th>Book dates (UTC +05:30)</th>
                                                                                <th>Days of arrival/departure</th>
                                                                                <th>Actions</th>
                                                                            </tr>
                                                                        </thead>

                                                                        <tbody>
                                                                            <tr>
                                                                                <td>Fri 24/05/2024</td>
                                                                                <td>Mon 06/01/2025</td>
                                                                                <td>Min: 3</td>
                                                                                <td>-</td>
                                                                                <td>-</td>
                                                                                <td>
                                                                                    Sun 01/01/2023,
                                                                                    <br />
                                                                                    04:30
                                                                                </td>
                                                                                <td>
                                                                                    Arr. days: Mon, Fri
                                                                                    <br />
                                                                                    Dep. days: Mon, Fri
                                                                                </td>
                                                                                <td>
                                                                                    <i
                                                                                        className="fa-solid fa-pencil pl-2"
                                                                                        style={{
                                                                                            cursor: 'pointer',
                                                                                            color: 'primary',
                                                                                        }}
                                                                                    />
                                                                                    <i
                                                                                        className="fa-solid fa-ban pl-2"
                                                                                        style={{
                                                                                            cursor: 'pointer',
                                                                                            color: 'primary',
                                                                                        }}
                                                                                    />
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                                <br />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <Modal.Footer />
                                                </Tab>
                                                {/* Tab 2 COntent*/}
                                                <Tab
                                                    eventKey="linkeddistributionchannel"
                                                    title="Linked Distribution Channel"
                                                >
                                                    <div className="title-name text-black mt-4 ml-2">
                                                        <h5>
                                                            <strong>Individual linked distribution channels</strong>
                                                        </h5>
                                                    </div>

                                                    <div className="row">
                                                        <div className="col-10">
                                                            <form className="search-form" style={{ width: '100%' }}>
                                                                <input
                                                                    type="text"
                                                                    placeholder="Search for distribution channels with name, code..."
                                                                    className="search-input"
                                                                />
                                                                <button type="submit" className="search-button">
                                                                    <i className="fas fa-search" />
                                                                </button>
                                                            </form>
                                                        </div>
                                                        <div className="filter-component col-2">
                                                            <p>
                                                                <strong>
                                                                    <i className="fa fa-filter" /> Advance Filters
                                                                </strong>
                                                            </p>
                                                        </div>

                                                        <div className="distribution-title text-black ml-2 mt-5">
                                                            <h6>Distribution Channel linked to a representation</h6>
                                                        </div>

                                                        <div className="employee-container ml-2">
                                                            <table className="employee-table">
                                                                <thead>
                                                                    <tr>
                                                                        <th>Name</th>
                                                                        <th>Code</th>
                                                                        <th>Path</th>
                                                                        <th>Start book date </th>
                                                                        <th>End book date </th>
                                                                    </tr>
                                                                </thead>

                                                                <tbody>
                                                                    <tr>
                                                                        <td>Booking.com</td>
                                                                        <td>B111</td>
                                                                        <td>
                                                                            http://c21zsn.localhost:3000/client/channel-manager/distribution-channel/distribution-detail
                                                                        </td>
                                                                        <td>Sun 3/11/2024</td>
                                                                        <td>Tue 28/12/2021</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Hotelbeds.com</td>
                                                                        <td>H111</td>
                                                                        <td>
                                                                            http://c21zsn.localhost:3000/client/channel-manager/distribution-channel/distribution-detail
                                                                        </td>
                                                                        <td>Mon 5/11/2024</td>
                                                                        <td>Tue 28/12/2021</td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </Tab>
                                            </Tabs>
                                        </Modal.Body>
                                        <ModalFooter />
                                    </Modal>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                {/* Basic Card-3*/}
                <div ref={cardCRef} className="card shadow mb-4">
                    <div className="card-header py-3">
                        <h6 className="m-0 font-weight-bold text-black">
                            <strong>Card C</strong>
                        </h6>
                    </div>
                    <div className="card-body">
                        The styling for this basic card example is created by using default Bootstrap utility classes. By
                        using utility classes, the style of the card component can be easily modified with no need for any
                        custom CSS!
                    </div>
                </div>
                {/* Basic Card-4*/}
                <div ref={cardDRef} className="card shadow mb-4">
                    <div className="card-header py-3">
                        <h6 className="m-0 font-weight-bold text-black">
                            <strong>Card D</strong>
                        </h6>
                    </div>
                    <div className="card-body">
                        The styling for this basic card example is created by using default Bootstrap utility classes. By
                        using utility classes, the style of the card component can be easily modified with no need for any
                        custom CSS!
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AccomodationDetail;
