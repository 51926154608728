const Menu = {
    menuLabel: 'BackOfficeManager',
    menus: [
        { label: 'Employee', path: '/client/backoffice-manager/employee', faIconClass: 'fa fa-user' },
        { label: 'EmployeeCategory', path: '/client/backoffice-manager/employee-category', faIconClass: 'fa fa-users' },
        { label: 'Role', path: '/client/backoffice-manager/role', faIconClass: 'fa fa-tasks' },
    ],
};

export default Menu;
