const Menu = {
    menuLabel: 'Rate Manager',
    menus: [
        { label: 'Rate Type', path: '/client/rate-manager/ratetype', faIconClass: 'fa-solid fa-ranking-star' },
        { label: 'Rate Option', path: '/client/rate-manager/rateoption', faIconClass: 'fa-solid fa-chart-pie' },
        { label: 'RentabilitySet', path: '/client/rate-manager/rentabilityset', faIconClass: 'fa-solid fa-house' },
        { label: 'Accomodation Type', path: '/client/rate-manager/accomodationtype', faIconClass: 'fa-solid fa-hotel' },
    ],
};

export default Menu;
