import React from 'react';
import { Route } from 'react-router-dom';
import { RegisterBackofficeUser } from '../../components/client/Registration/RegisterBackofficeUser';
import ClientLandingPage from '../../components/client/Landing/LandingPage';
import { ViewUserProfile } from '../../components/common/UserProfile/ViewUserProfile';
import NoSidebarLayout from '../../components/common/NoSidebarLayout';
import CreateClientUserProfile from '../../components/client/UserProfile/CreateClientUserProfile';
import { BackOfficeManager } from '../../components/client/BackOffice';
import backofficeMenu from '../../components/client/BackOffice/BackOfficeMenu';
import { InventoryManager } from '../../components/client/Inventory';
import InventoryMenu from '../../components/client/Inventory/InventoryMenu';
import { ChannelManager } from '../../components/client/Channel';
import channelMenu from '../../components/client/Channel/ChannelMenu';
import { RateManager } from '../../components/client/Rate';
import RateMenu from '../../components/client/Rate/RateMenu';
import { EmbedsquareSetup } from '../../components/client/EmbedsquareSetup';
import { ReservationManager } from '../../components/client/Reservation';
import { OperationsManager } from '../../components/client/Operations';
import { FinanceManager } from '../../components/client/Finance';
import { FrontDeskManager } from '../../components/client/FrontDesk';
import { IntegrationManager } from '../../components/client/Integration';
import { NotificationManager } from '../../components/client/Notification';
import { OwnerManager } from '../../components/client/Owner';
import { CustomerManager } from '../../components/client/Customer';
import { WebsiteBuilder } from '../../components/client/WebsiteBuilder';
import ClientSidebarLayout from '../../components/client/ClientSidebarLayout';
import { Employee } from '../../components/client/BackOffice/Employee/Employee';
import { EmployeeGroup } from '../../components/client/BackOffice/EmployeeGroup/EmployeeGroup';
import Distribution from '../../components/client/Channel/DistributionChannel/Distribution';
import Reservation from '../../components/client/Channel/ReservationCategories/Reservation';
import Location from '../../components/client/Inventory/Location/Location';
import AccomodationType from '../../components/client/Inventory/AccomodationType/AccomodationType';
import Units from '../../components/client/Inventory/Units/Units';
import EmployeeDetail from '../../components/client/BackOffice/Employee/EmployeeDetail';
import EmployeeGroupDetail from '../../components/client/BackOffice/EmployeeGroup/EmployeeGroupDetail';
import LocationDetail from '../../components/client/Inventory/Location/LocationDetail';
import AccomodationDetail from '../../components/client/Inventory/AccomodationType/Accomodationdetail';
import UnitsDetail from '../../components/client/Inventory/Units/UnitsDetail';
import DistributionChannelDetail from '../../components/client/Channel/DistributionChannel/DistributionChannelDetail';
import ReservationDetail from '../../components/client/Channel/ReservationCategories/ReservationDetail';
import RateType from '../../components/client/Rate/RateType/Ratetype';
import RatetypeDetail from '../../components/client/Rate/RateType/Ratetypedetail';
import Rateoption from '../../components/client/Rate/RateOption/Rateoption';
import RateoptionDetail from '../../components/client/Rate/RateOption/Rateoptiondetail';
import Addons from '../../components/client/Inventory/Addons/Addons';
import AddonsDetail from '../../components/client/Inventory/Addons/AddonsDetail';
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default [
    <Route
        key="client-index"
        index
        element={
            <NoSidebarLayout>
                <ClientLandingPage />
            </NoSidebarLayout>
        }
    />,
    <Route
        key="client-landing"
        path="/client/user-profile/"
        element={
            <ClientSidebarLayout componentMenu={backofficeMenu}>
                <ViewUserProfile />
            </ClientSidebarLayout>
        }
    />,
    <Route key="client-profiles-create" path="/login/user/back-office/:key/:token" element={<RegisterBackofficeUser />} />,
    <Route
        key="client-landing"
        path="/client/landing"
        element={
            <NoSidebarLayout>
                <ClientLandingPage />
            </NoSidebarLayout>
        }
    />,
    <Route
        key="client-landing"
        path="/client/backoffice-manager"
        element={
            <ClientSidebarLayout componentMenu={backofficeMenu}>
                <BackOfficeManager />
            </ClientSidebarLayout>
        }
    />,
    <Route
        key="client-landing"
        path="/client/backoffice-manager/employee"
        element={
            <ClientSidebarLayout componentMenu={backofficeMenu}>
                <Employee />
            </ClientSidebarLayout>
        }
    />,
    <Route
        key="client-landing"
        path="/client/backoffice-manager/employee/employee-detail"
        element={
            <ClientSidebarLayout componentMenu={backofficeMenu}>
                <EmployeeDetail />
            </ClientSidebarLayout>
        }
    />,
    <Route
        key="client-landing"
        path="/client/backoffice-manager/employee-group"
        element={
            <ClientSidebarLayout componentMenu={backofficeMenu}>
                <EmployeeGroup />
            </ClientSidebarLayout>
        }
    />,
    <Route
        key="client-landing"
        path="/client/backoffice-manager/employee-group/employeegroup-detail"
        element={
            <ClientSidebarLayout componentMenu={backofficeMenu}>
                <EmployeeGroupDetail />
            </ClientSidebarLayout>
        }
    />,

    <Route
        key="client-landing"
        path="/client/inventory-manager"
        element={
            <ClientSidebarLayout componentMenu={InventoryMenu}>
                <InventoryManager />
            </ClientSidebarLayout>
        }
    />,
    <Route
        key="client-landing"
        path="/client/inventory-manager/location"
        element={
            <ClientSidebarLayout componentMenu={InventoryMenu}>
                <Location />
            </ClientSidebarLayout>
        }
    />,
    <Route
        key="client-landing"
        path="/client/inventory-manager/location/location-detail"
        element={
            <ClientSidebarLayout componentMenu={InventoryMenu}>
                <LocationDetail />
            </ClientSidebarLayout>
        }
    />,
    <Route
        key="client-landing"
        path="/client/inventory-manager/accomodationtype"
        element={
            <ClientSidebarLayout componentMenu={InventoryMenu}>
                <AccomodationType />
            </ClientSidebarLayout>
        }
    />,
    <Route
        key="client-landing"
        path="/client/inventory-manager/accomodationtype/accomodation-detail"
        element={
            <ClientSidebarLayout componentMenu={InventoryMenu}>
                <AccomodationDetail />
            </ClientSidebarLayout>
        }
    />,
    <Route
        key="client-landing"
        path="/client/inventory-manager/units"
        element={
            <ClientSidebarLayout componentMenu={InventoryMenu}>
                <Units />
            </ClientSidebarLayout>
        }
    />,
    <Route
        key="client-landing"
        path="/client/inventory-manager/units/units-detail"
        element={
            <ClientSidebarLayout componentMenu={InventoryMenu}>
                <UnitsDetail />
            </ClientSidebarLayout>
        }
    />,
    <Route
        key="client-landing"
        path="/client/inventory-manager/addons"
        element={
            <ClientSidebarLayout componentMenu={InventoryMenu}>
                <Addons />
            </ClientSidebarLayout>
        }
    />,
    <Route
        key="client-landing"
        path="/client/inventory-manager/addons/addons-detail"
        element={
            <ClientSidebarLayout componentMenu={InventoryMenu}>
                <AddonsDetail />
            </ClientSidebarLayout>
        }
    />,
    <Route
        key="client-landing"
        path="/client/channel-manager"
        element={
            <ClientSidebarLayout componentMenu={channelMenu}>
                <ChannelManager />
            </ClientSidebarLayout>
        }
    />,
    <Route
        key="client-landing"
        path="/client/channel-manager/distribution-channel"
        element={
            <ClientSidebarLayout componentMenu={channelMenu}>
                <Distribution />
            </ClientSidebarLayout>
        }
    />,
    <Route
        key="client-landing"
        path="/client/channel-manager/distribution-channel/distribution-detail"
        element={
            <ClientSidebarLayout componentMenu={channelMenu}>
                <DistributionChannelDetail />
            </ClientSidebarLayout>
        }
    />,

    <Route
        key="client-landing"
        path="/client/channel-manager/reservation-categories"
        element={
            <ClientSidebarLayout componentMenu={channelMenu}>
                <Reservation />
            </ClientSidebarLayout>
        }
    />,
    <Route
        key="client-landing"
        path="/client/channel-manager/reservation-categories/reservation-detail"
        element={
            <ClientSidebarLayout componentMenu={channelMenu}>
                <ReservationDetail />
            </ClientSidebarLayout>
        }
    />,
    <Route
        key="client-landing"
        path="/client/rate-manager/"
        element={
            <ClientSidebarLayout componentMenu={RateMenu}>
                <RateManager />
            </ClientSidebarLayout>
        }
    />,
    <Route
        key="client-landing"
        path="/client/rate-manager/ratetype"
        element={
            <ClientSidebarLayout componentMenu={RateMenu}>
                <RateType />
            </ClientSidebarLayout>
        }
    />,
    <Route
        key="client-landing"
        path="/client/rate-manager/ratetype/ratetype-detail"
        element={
            <ClientSidebarLayout componentMenu={RateMenu}>
                <RatetypeDetail />
            </ClientSidebarLayout>
        }
    />,
    <Route
        key="client-landing"
        path="/client/rate-manager/rateoption"
        element={
            <ClientSidebarLayout componentMenu={RateMenu}>
                <Rateoption />
            </ClientSidebarLayout>
        }
    />,
    <Route
        key="client-landing"
        path="/client/rate-manager/rateoption/rateoption-detail"
        element={
            <ClientSidebarLayout componentMenu={RateMenu}>
                <RateoptionDetail />
            </ClientSidebarLayout>
        }
    />,

    // TODO: To change the componentMenu={-----} Menuitems later.
    <Route
        key="client-landing"
        path="/client/feature-controller/"
        element={
            <ClientSidebarLayout componentMenu={backofficeMenu}>
                <EmbedsquareSetup />
            </ClientSidebarLayout>
        }
    />,
    <Route
        key="client-landing"
        path="/client/reservation-manager/"
        element={
            <ClientSidebarLayout componentMenu={backofficeMenu}>
                <ReservationManager />
            </ClientSidebarLayout>
        }
    />,
    <Route
        key="client-landing"
        path="/client/operations-manager/"
        element={
            <ClientSidebarLayout componentMenu={backofficeMenu}>
                <OperationsManager />
            </ClientSidebarLayout>
        }
    />,
    <Route
        key="client-landing"
        path="/client/finance-manager/"
        element={
            <ClientSidebarLayout componentMenu={backofficeMenu}>
                <FinanceManager />
            </ClientSidebarLayout>
        }
    />,
    <Route
        key="client-landing"
        path="/client/frontdesk-manager/"
        element={
            <ClientSidebarLayout componentMenu={backofficeMenu}>
                <FrontDeskManager />
            </ClientSidebarLayout>
        }
    />,
    <Route
        key="client-landing"
        path="/client/integration-manager/"
        element={
            <ClientSidebarLayout componentMenu={backofficeMenu}>
                <IntegrationManager />
            </ClientSidebarLayout>
        }
    />,
    <Route
        key="client-landing"
        path="/client/notification-manager/"
        element={
            <ClientSidebarLayout componentMenu={backofficeMenu}>
                <NotificationManager />
            </ClientSidebarLayout>
        }
    />,
    <Route
        key="client-landing"
        path="/client/owner-manager/"
        element={
            <ClientSidebarLayout componentMenu={backofficeMenu}>
                <OwnerManager />
            </ClientSidebarLayout>
        }
    />,
    <Route
        key="client-landing"
        path="/client/customer-manager/"
        element={
            <ClientSidebarLayout componentMenu={backofficeMenu}>
                <CustomerManager />
            </ClientSidebarLayout>
        }
    />,
    <Route
        key="client-landing"
        path="/client/website-manager/"
        element={
            <ClientSidebarLayout componentMenu={backofficeMenu}>
                <WebsiteBuilder />
            </ClientSidebarLayout>
        }
    />,
    <Route
        key="create-client-user"
        path="/client/manage-users/user/init/profile/update"
        element={
            <NoSidebarLayout>
                <CreateClientUserProfile />
            </NoSidebarLayout>
        }
    />,
];
