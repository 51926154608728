// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.search-form {
    display: flex;
    align-items: center;
    margin-right: 10px;
}

.search-input {
    padding: 10px;
    border-radius: 20px;
    border: 1px solid #ccc;
    margin-right: 10px;
    width: 800px;
}

.search-button {
    padding: 10px 20px;
    background-color: #777;
    color: white;
    border: none;
    border-radius: 20px;
    cursor: pointer;
    margin-right: 140px;
}

.search-button:hover {
    background-color: darkblue;
}
`, "",{"version":3,"sources":["webpack://./src/components/client/Inventory/Location/Location.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,sBAAsB;IACtB,kBAAkB;IAClB,YAAY;AAChB;;AAEA;IACI,kBAAkB;IAClB,sBAAsB;IACtB,YAAY;IACZ,YAAY;IACZ,mBAAmB;IACnB,eAAe;IACf,mBAAmB;AACvB;;AAEA;IACI,0BAA0B;AAC9B","sourcesContent":[".search-form {\r\n    display: flex;\r\n    align-items: center;\r\n    margin-right: 10px;\r\n}\r\n\r\n.search-input {\r\n    padding: 10px;\r\n    border-radius: 20px;\r\n    border: 1px solid #ccc;\r\n    margin-right: 10px;\r\n    width: 800px;\r\n}\r\n\r\n.search-button {\r\n    padding: 10px 20px;\r\n    background-color: #777;\r\n    color: white;\r\n    border: none;\r\n    border-radius: 20px;\r\n    cursor: pointer;\r\n    margin-right: 140px;\r\n}\r\n\r\n.search-button:hover {\r\n    background-color: darkblue;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
