/* eslint-disable no-console */
/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice } from '@reduxjs/toolkit';
// Slice
const slice = createSlice({
    name: 'token',
    initialState: {
        accessToken: null as null | string,
    },
    reducers: {
        setAuthToken: (state, action) => {
            state.accessToken = action.payload;
        },
    },
});
export default slice.reducer;

// Actions
const { setAuthToken } = slice.actions;
export const setAccessToken = (token: string | null) => async (dispatch: any) => {
    try {
        return dispatch(setAuthToken(token));
    } catch (e: any) {
        return console.error(e.message);
    }
};
