import { SUPER_CLIENT_CODE } from '../constants/app';
import { ApplicationEnvironment } from '../types/app';

export const getEnvironment = (): ApplicationEnvironment => {
    if (process.env.REACT_APP_ENV) {
        return process.env.REACT_APP_ENV as ApplicationEnvironment;
    }
    return ApplicationEnvironment.LOCAL;
};

export const getClientCode = (): string => {
    const hostname = window.location.hostname;
    const hostnameParts = hostname.split('.');

    // Get the current environment
    const environment = getEnvironment();

    let code = null;
    if (environment === ApplicationEnvironment.LOCAL) {
        // Local environment logic
        if (hostnameParts.length >= 2) {
            code = hostnameParts[0];
        } else {
            code = SUPER_CLIENT_CODE;
        }
    } else if (environment === ApplicationEnvironment.DEVELOPMENT || environment === ApplicationEnvironment.PRODUCTION) {
        // Production or development environment logic
        if (hostnameParts.length > 3) {
            code = hostnameParts[0];
        } else {
            code = SUPER_CLIENT_CODE;
        }
    } else {
        // Default to SUPER_CLIENT_CODE if environment is unknown
        code = SUPER_CLIENT_CODE;
    }

    return code;
};
