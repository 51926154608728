import React from 'react';
export const NoDataWarning = () => {
    return (
        <div className="mt-2 alert alert-warning alert-outline-coloured alert-dismissible" role="alert">
            <div className="alert-icon">
                <i className="far fa-fw fa-bell" />
            </div>
            <div className="alert-message">
                <p className="note">
                    There was some problem while fetching the data you requested. <br /> It can be that the data is not added
                    yet or there is some issue.{' '}
                </p>
                <p>
                    <strong>Note:</strong> If problem persist please contact support.
                </p>
            </div>
        </div>
    );
};
