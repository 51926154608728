import React, { useEffect, useState } from 'react';
//import { PageHeaderType } from '../../../types/menu';
//import PageHeader from '../../common/Header/PageHeader';
import { NavLink } from 'react-router-dom';
import { Client } from '../../../types/client';
import { getClientList } from '../../../services/client';
import { NoDataWarning } from '../../common/Alerts/NoDataWarning';

export const ViewClientList = () => {
    // const { triggerSuccess } = useNotificationContext();

    const [loadingData, setLoadingData] = useState(true);
    const [clientList, setClientList] = useState<Client[]>([]);

    useEffect(() => {
        const getClientData = async () => {
            setLoadingData(true);
            const response = await getClientList(0);
            if (response.data) {
                setClientList(response.data);
            }
            setLoadingData(false);
        };
        getClientData();
    }, []);
    return (
        <div className="container-fluid">
            <div className="view-client-profile">
                <div className="container-xl px-4 mt-4">
                    <div className="col-12 p-2">
                        <div className="card mb-4">
                            <div className="card-body">
                                {loadingData && (
                                    <div className="row mt-2">
                                        <div className="col-sm-12 mt-2 mb-2 text-center p-2 valign-middle">
                                            Fetching client details, please wait
                                            <i className="fa-solid fa-circle-notch fa-spin ms-2" />
                                        </div>
                                    </div>
                                )}
                                {!loadingData && clientList.length > 0 && (
                                    <div className="card mb-4">
                                        <div className="card-header py-3">
                                            <h6 className="m-0 font-weight-bold text-primary">
                                                Showing {clientList.length} Item(s)
                                            </h6>
                                        </div>
                                        <div className="card-body">
                                            <div className="table-responsive">
                                                <table
                                                    className="table table-bordered"
                                                    id="dataTable"
                                                    width="100%"
                                                    cellSpacing="0"
                                                >
                                                    <thead>
                                                        <tr>
                                                            <th>Name</th>
                                                            <th>Email</th>
                                                            <th>Code</th>
                                                            <th>Creation Date</th>
                                                            <th>Actions</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {clientList.map((client) => (
                                                            <tr key={client.uuid}>
                                                                <td>
                                                                    <NavLink
                                                                        to={`/admin/client-profiles/view/single/${client.uuid}`}
                                                                        title="View Details"
                                                                        className="me-2"
                                                                    >
                                                                        {client.name}
                                                                    </NavLink>
                                                                </td>
                                                                <td>{client.email}</td>
                                                                <td>{client.code}</td>
                                                                <td>{new Date(client.createdAt).toLocaleDateString()}</td>
                                                                <td>
                                                                    <i className="fa fa-fw fa-pencil me-2" />
                                                                    <i className="fa fa-fw fa-trash-can ms-2" />
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {!loadingData && clientList.length === 0 && <NoDataWarning />}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
