import React, { useEffect, useState } from 'react';
import jwt_decode from 'jwt-decode';
import { useDispatch } from 'react-redux';
import { SessionTimeoutWarningModal } from '../Modals/SessionTimeoutWarningModal';
import { handleGlobalError, resetGlobalError } from '../../../store/errorHandler';
import { setAccessToken } from '../../../store/token';
import { AppDispatch, IRootState, useSelector } from '../../../store';
import { extendSession } from '../../../services/authentication';
import { LogoutWarningModal } from '../Modals/LogoutWarningModal';

type JWT = {
    exp: number;
};
const expiryNotificationThreshHold = 300; // 5 minutes = 300 seconds

const Footer = () => {
    const dispatch: AppDispatch = useDispatch();
    const [showSessionTimeoutWarningModal, setShowSessionTimeoutWarningModal] = useState(false);
    const [showLogoutModal, setShowLogoutModal] = useState(false);
    const [modalNudged, setModalNudged] = useState(false);
    const [timer, setTimer] = useState(0);

    const token = useSelector((state: IRootState) => state.token);
    const decoded = token.accessToken && jwt_decode(token.accessToken);
    const decodedToken = decoded as JWT;
    const handleClose = () => setShowSessionTimeoutWarningModal(false);

    useEffect(() => {
        // Start the timer
        const interval = setInterval(() => {
            // Get the current timestamp in seconds
            const currentTimestamp = Math.floor(Date.now() / 1000);
            // Modal Logic
            const expirationThreshold = currentTimestamp + expiryNotificationThreshHold;
            const isTokenAboutToExpire = decodedToken.exp <= expirationThreshold;
            if (isTokenAboutToExpire && !modalNudged) {
                setShowSessionTimeoutWarningModal(true);
                setModalNudged(true);
            }

            // Calculate the remaining time in seconds
            const remainingTime = decodedToken.exp - currentTimestamp;

            // Update the timer state
            setTimer(remainingTime);

            // Stop the timer when it reaches 0
            if (remainingTime <= 0) {
                setShowLogoutModal(true);
                setTimeout(() => {
                    dispatch(setAccessToken(null));
                }, 5000);
            }
        }, 1000);

        // Clean up the interval when the component unmounts
        return () => {
            clearInterval(interval);
        };
    }, [decodedToken, modalNudged, dispatch]);
    // Format the timer value as minutes and seconds
    const formatTimer = (seconds: number) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
    };
    const handleExtendSession = async () => {
        try {
            const authToken = (await extendSession()) as { token: string };
            dispatch(setAccessToken(authToken.token));
            setShowSessionTimeoutWarningModal(false);
            setModalNudged(false);
        } catch (e) {
            dispatch(resetGlobalError());
            dispatch(handleGlobalError({ message: 'Something went wrong, logging you out right now', isError: true }));
        }
    };

    return (
        <footer className="bg-white py-3">
            <div className="container">
                <div className="row">
                    <div className="col-md-6 text-center text-md-left mb-2 mb-md-0 text-uppercase">
                        <small>
                            &copy; {new Date().getFullYear()} All rights reserved. {formatTimer(timer)}
                        </small>
                    </div>
                    <div className="col-md-6 text-center text-md-right">
                        <small>Embed Square Solutions Pvt. Ltd.</small>
                    </div>
                </div>
            </div>
            <LogoutWarningModal showModal={showLogoutModal} />
            <SessionTimeoutWarningModal
                showModal={showSessionTimeoutWarningModal}
                handleClose={handleClose}
                handleExtendSession={handleExtendSession}
            />
        </footer>
    );
};

export default Footer;
