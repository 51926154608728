import React, { ReactElement } from 'react';
import Header from './Header/Header';

type Prop = {
    children: ReactElement;
};
const NoSidebarLayout = ({ children }: Prop) => {
    return (
        <div id="wrapper">
            <div id="content-wrapper" className="d-flex flex-column">
                <Header />
                <div className="container-fluid">{children}</div>
            </div>
        </div>
    );
};

export default NoSidebarLayout;
