import React, { ReactElement } from 'react';
//import Header from '../common/Header/Header';
//import Footer from '../common/Footer/Footer';
import Sidebar from './Sidebar/Sidebar';

type Prop = {
    children: ReactElement;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    componentMenu: any;
};
const ClientSidebarLayout = ({ children, componentMenu }: Prop) => {
    return (
        <div id="wrapper">
            <Sidebar menus={componentMenu} />
            <div id="content-wrapper" className="d-flex flex-column">
                {/**<Header />**/}
                <>{children}</>
                {/**<Footer />**/}
            </div>
        </div>
    );
};

export default ClientSidebarLayout;
