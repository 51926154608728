import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap'; // Added Form for form controls
import { Link } from 'react-router-dom'; // Import Link for navigation
import { PageHeaderType } from '../../../../types/menu';
import PageHeader from '../../../common/Header/PageHeader';
import './Rateoption.css';
import CreateRateoptionModal from './CreateRateoptionModal';

export const RateOption = () => {
    const [showModal, setShowModal] = useState(false);
    const [createRateoptionShowModal, setCreateRateoptionShowModal] = useState(false);

    // State for actual columns display
    const [columns, setColumns] = useState({
        rateoptionName: true,
        lastName: true,
        rateoptionCode: true,
        rateoptionID: true,
    });

    // Temporary state for modal
    const [tempColumns, setTempColumns] = useState(columns);

    const handleCloseCreateRateoptionShowModal = () => {
        setCreateRateoptionShowModal(false);
    };

    const handleShowCreateRateoptionShowModal = () => {
        setCreateRateoptionShowModal(true);
    };

    const pageHeader: PageHeaderType = {
        title: 'Rate Option',
        prevNavLabel: 'Dashboard',
        prevNavLink: '/client/landing',
        iconClass: 'fa-solid fa-chart-pie',
        modal: {
            showModalHandler: handleShowCreateRateoptionShowModal,
        },
        createPageType: 'modal',
    };

    const handleConfigureColumnsClick = () => {
        setTempColumns(columns); // Sync tempColumns with current columns state
        setShowModal(true);
    };

    const handleClose = () => {
        setShowModal(false);
    };

    // Function to handle checkbox changes in modal
    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { id, checked } = event.target;
        setTempColumns((prevState) => ({
            ...prevState,
            [id]: checked,
        }));
    };

    // Function to save changes
    const handleSave = () => {
        setColumns(tempColumns);
        handleClose();
    };

    return (
        <div className="container-fluid">
            <PageHeader {...pageHeader} />
            <CreateRateoptionModal
                showModal={createRateoptionShowModal}
                handleClose={handleCloseCreateRateoptionShowModal}
            />
            <div className="main-container">
                <div className="configure-columns" onClick={handleConfigureColumnsClick}>
                    <i className="fas fa-cogs configure-icon" />
                    <span>Configure Columns</span>
                </div>
                <div className="employee-container">
                    <table className="employee-table">
                        <thead>
                            <tr>
                                {columns.rateoptionName && (
                                    <th>
                                        Rate Option Name
                                        <i className="fas fa-sort ml-2" />
                                    </th>
                                )}
                                {columns.rateoptionCode && (
                                    <th>
                                        Rate Option Code
                                        <i className="fas fa-sort ml-2" />
                                    </th>
                                )}
                                {columns.rateoptionID && (
                                    <th>
                                        Rate Option ID
                                        <i className="fas fa-sort ml-2" />
                                    </th>
                                )}
                            </tr>
                        </thead>
                        <tbody>
                            {/* Your table rows */}
                            <tr>
                                {columns.rateoptionName && (
                                    <td>
                                        <Link to="/client/rate-manager/rateoption/rateoption-detail">Per Night</Link>
                                    </td>
                                )}

                                {columns.rateoptionCode && (
                                    <td>
                                        <Link to="/client/rate-manager/rateoption/rateoption-detail">Night</Link>
                                    </td>
                                )}
                                {columns.rateoptionID && (
                                    <td>
                                        <Link to="/client/rate-manager/rateoption/rateoption-detail">83633</Link>
                                    </td>
                                )}
                            </tr>
                            <tr>
                                {columns.rateoptionName && (
                                    <td>
                                        <Link to="/client/rate-manager/rateoption/rateoption-detail">Per Week</Link>
                                    </td>
                                )}

                                {columns.rateoptionCode && (
                                    <td>
                                        <Link to="/client/rate-manager/rateoption/rateoption-detail">Week</Link>
                                    </td>
                                )}
                                {columns.rateoptionID && (
                                    <td>
                                        <Link to="/client/rate-manager/rateoption/rateoption-detail">86632</Link>
                                    </td>
                                )}
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <Modal show={showModal} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Configure Columns</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Check
                            type="checkbox"
                            id="rateoptionName"
                            label="Rate Option Name"
                            checked={tempColumns.rateoptionName}
                            onChange={handleCheckboxChange}
                        />
                        <Form.Check
                            type="checkbox"
                            id="rateoptionCode"
                            label="Rate Option Code"
                            checked={tempColumns.rateoptionCode}
                            onChange={handleCheckboxChange}
                        />
                        <Form.Check
                            type="checkbox"
                            id="rateoptionID"
                            label="Rate Option ID"
                            checked={tempColumns.rateoptionID}
                            onChange={handleCheckboxChange}
                        />
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleSave}>
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default RateOption;
