import React from 'react';
import { Modal, Button, Form, Row, Col } from 'react-bootstrap';

type Props = {
    showModal: boolean;
    handleClose: () => void;
};
const CreateDistributionModal = (props: Props) => {
    const { showModal, handleClose } = props;
    return (
        <>
            <Modal show={showModal} onHide={handleClose} size="xl">
                <Modal.Header closeButton>
                    <Modal.Title>Create Distribution-Channel</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div style={{ height: '400px', overflow: 'auto' }}>
                        <div className="container-xl px-4 mt-4">
                            <Row>
                                <Col md={6}>
                                    <div className="card mb-4">
                                        <div className="card-header">General Information</div>
                                        <div className="card-body">
                                            <Form>
                                                <Form.Group controlId="formFirstName">
                                                    <Form.Label>Name</Form.Label>
                                                    <Form.Control type="text" defaultValue="" />
                                                </Form.Group>

                                                <Form.Group controlId="formFirstName">
                                                    <Form.Label>Code</Form.Label>
                                                    <Form.Control type="text" defaultValue="" />
                                                </Form.Group>

                                                <Form.Group controlId="formFirstName">
                                                    <Form.Label>Description</Form.Label>
                                                    <Form.Control type="text" defaultValue="" />
                                                </Form.Group>
                                            </Form>
                                        </div>
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className="card mb-4">
                                        <div className="card-header">Address Details</div>
                                        <div className="card-body">
                                            <Form>
                                                <Form.Group controlId="formStreet1">
                                                    <Form.Label>Street 1</Form.Label>
                                                    <Form.Control type="text" defaultValue="" />
                                                </Form.Group>

                                                <Form.Group controlId="formStreet2">
                                                    <Form.Label>Street 2</Form.Label>
                                                    <Form.Control type="text" defaultValue="" />
                                                </Form.Group>

                                                <Form.Group controlId="formStreet2">
                                                    <Form.Label>Pincode</Form.Label>
                                                    <Form.Control type="text" defaultValue="" />
                                                </Form.Group>

                                                <Form.Group controlId="formCity">
                                                    <Form.Label>City</Form.Label>
                                                    <Form.Control type="text" defaultValue="" />
                                                </Form.Group>

                                                <Form.Group controlId="formState">
                                                    <Form.Label>State</Form.Label>
                                                    <Form.Control type="text" defaultValue="" />
                                                </Form.Group>

                                                <Form.Group controlId="formCountry">
                                                    <Form.Label>Country</Form.Label>
                                                    <Form.Control type="text" defaultValue="" />
                                                </Form.Group>
                                            </Form>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleClose}>
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default CreateDistributionModal;
