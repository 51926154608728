// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Light grey color */
/* Coral color */
/* Tomato color */
/* White color */
.client-landing {
  background-color: #f8f9fa;
}

.module-block {
  background-color: #277c9e;
  border-radius: 8px;
  border: 2px solid #212A31;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
  height: 150px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.module-block:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
}
.module-block i {
  color: #ffffff;
  font-size: 2rem;
  margin-bottom: 8px;
}
.module-block span {
  font-size: 1rem;
  color: #ffffff;
  text-align: center;
}

@media (max-width: 768px) {
  .module-block {
    height: 100px;
  }
  .module-block i {
    font-size: 1.2rem;
  }
  .module-block span {
    font-size: 0.7rem;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/client/Landing/LandingPage.scss"],"names":[],"mappings":"AAC4B,qBAAA;AAGM,gBAAA;AACJ,iBAAA;AACF,gBAAA;AAE5B;EACI,yBARe;AAKnB;;AAMA;EACI,yBATqB;EAUrB,kBAAA;EACA,yBAAA;EACA,wCAAA;EACA,2CAAA;EACA,aAAA;EACA,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,mBAAA;AAHJ;AAKI;EACI,2BAAA;EACA,yCAAA;AAHR;AAMI;EACI,cAxBW;EAyBX,eAAA;EACA,kBAAA;AAJR;AAOI;EACI,eAAA;EACA,cA/BW;EAgCX,kBAAA;AALR;;AASA;EACI;IACI,aAAA;EANN;EAQM;IACI,iBAAA;EANV;EASM;IACI,iBAAA;EAPV;AACF","sourcesContent":["// Color variables\n$background-color: #f8f9fa; /* Light grey color */\n\n// Block colors\n$block-background-color: #277c9e; /* Coral color */\n$block-border-color: #212A31; /* Tomato color */\n$block-text-color: #ffffff; /* White color */\n\n.client-landing {\n    background-color: $background-color;\n}\n\n.module-block {\n    background-color: $block-background-color;\n    border-radius: 8px;\n    border: 2px solid $block-border-color;\n    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);\n    transition: transform 0.3s, box-shadow 0.3s; \n    height: 150px;\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n\n    &:hover {\n        transform: translateY(-5px);\n        box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);\n    }\n\n    i {\n        color: $block-text-color;\n        font-size: 2rem;\n        margin-bottom: 8px;\n    }\n\n    span {\n        font-size: 1rem;\n        color: $block-text-color;\n        text-align: center;\n    }\n}\n\n@media (max-width: 768px) {\n    .module-block {\n        height: 100px;\n\n        i {\n            font-size: 1.2rem;\n        }\n\n        span {\n            font-size: 0.7rem;\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
