/* eslint-disable no-console */
import { get } from 'lodash';
import qs from 'qs';
import store from '../store/';
import { handleGlobalError } from '../store/errorHandler';

export type OnProgress = (progress: number, event: ProgressEvent<XMLHttpRequestEventTarget>) => void;

export const globalErrorHandler = (err: ResponseError, showError: boolean) => {
    if (err.name !== 'AbortError') {
        let message = err.message;
        if (err.message === 'Failed to fetch') {
            message = 'Unable to contact the system at the moment please try again';
        }
        // Abort errors occur when a newer request aborted the previous request; we can safely ignore these
        store?.dispatch(handleGlobalError({ message: message, isError: true, showError }));
    }

    // throw err; // Rethrow so that the source component can still take action if needed
};

interface RequestOptions {
    url: string;
    method: string;
    headers?: HeadersInit;
    skipAuth?: boolean;
    showError?: boolean;
    noContentType?: boolean;
    data?: unknown;
    file?: File | FormData;
    queryParams?: Record<string, unknown>;
    signal?: AbortSignal;
}

interface ResponseError extends Error {
    response?: Response;
    meta?: Record<string, unknown>;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const checkStatus = async (data: any, response: Response) => {
    if (response.status >= 200 && response.status < 300) {
        return;
    }
    // TODO: error maps needs to be created for handling error based on status codes.
    const error = new Error(`${get(data, 'error.message', null) || response.statusText}`) as ResponseError;
    error.response = response;
    error.meta = get(data, 'error.meta', undefined);
    throw error;
};

function getBody({ data, file }: RequestOptions) {
    if (file) {
        return file;
    }
    return data ? JSON.stringify(data) : undefined;
}

/**
 * When making a request take care to:
 * - define queryParams as request options, not as part of the url
 */
export async function request<T>(options: RequestOptions): Promise<T> {
    const { url, method, signal, skipAuth, showError = true, noContentType } = options;
    const { queryParams } = options;
    const presetHeaders = {} as { [key: string]: string };
    if (!noContentType) {
        presetHeaders['Content-Type'] = 'application/json';
    }

    if (!skipAuth) {
        // eslint-disable-next-line no-console
        presetHeaders.Authorization = `Bearer ${store.getState().token.accessToken}`;
    }
    // Add x-client-code header
    const clientCode = store.getState().client.code;
    if (clientCode) {
        presetHeaders['x-client-code'] = clientCode;
    }
    const headers = options.headers ? { ...presetHeaders, ...options.headers } : presetHeaders;
    const body = getBody(options);
    const urlWithQuery = queryParams ? `${url}?${qs.stringify(queryParams)}` : url;

    try {
        const response = await fetch(urlWithQuery, {
            method,
            body,
            headers,
            signal,
        });

        let data = null;
        const contentType = response.headers.get('Content-Type');
        if (contentType && /application\/json/i.test(contentType)) {
            data = await response.json();
        } else if (
            contentType?.includes('image/png') ||
            contentType?.includes('image/jpeg') ||
            contentType?.includes('application/pdf')
        ) {
            data = await response.blob();
        }
        await checkStatus(data, response);

        return data;
    } catch (err: unknown) {
        globalErrorHandler(err as ResponseError, showError);
        return Promise.resolve('Something went wrong' as T);
    }
}
