/* eslint-disable no-console */
/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice } from '@reduxjs/toolkit';
import { SUPER_CLIENT_CODE } from '../constants/app';

// Slice
const clientSlice = createSlice({
    name: 'client',
    initialState: {
        code: SUPER_CLIENT_CODE,
        name: '',
    },
    reducers: {
        setClientInfo: (state, action) => {
            return { ...state, ...action.payload };
        },
    },
});

export default clientSlice.reducer;

// Actions
export const { setClientInfo } = clientSlice.actions;
