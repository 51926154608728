import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap'; // Added Form for form controls
import { Link } from 'react-router-dom'; // Import Link for navigation
import { PageHeaderType } from '../../../../types/menu';
import PageHeader from '../../../common/Header/PageHeader';
import './Location.css';
import CreateLocationModal from './CreateLocationModal';

export const Location = () => {
    const [showModal, setShowModal] = useState(false);
    const [createLocationShowModal, setCreateLocationShowModal] = useState(false);

    // State for actual columns display
    const [columns, setColumns] = useState({
        locationName: true,
        lastName: true,
        locationCode: true,
        locationId: true,
    });

    // Temporary state for modal
    const [tempColumns, setTempColumns] = useState(columns);

    const handleCloseCreateLocationShowModal = () => {
        setCreateLocationShowModal(false);
    };

    const handleShowCreateLocationShowModal = () => {
        setCreateLocationShowModal(true);
    };

    const pageHeader: PageHeaderType = {
        title: 'Location',
        prevNavLabel: 'Dashboard',
        prevNavLink: '/client/landing',
        iconClass: 'fa-sharp fa-solid fa-location-dot',
        modal: {
            showModalHandler: handleShowCreateLocationShowModal,
        },
        createPageType: 'modal',
    };

    const handleConfigureColumnsClick = () => {
        setTempColumns(columns); // Sync tempColumns with current columns state
        setShowModal(true);
    };

    const handleClose = () => {
        setShowModal(false);
    };

    // Function to handle checkbox changes in modal
    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { id, checked } = event.target;
        setTempColumns((prevState) => ({
            ...prevState,
            [id]: checked,
        }));
    };

    // Function to save changes
    const handleSave = () => {
        setColumns(tempColumns);
        handleClose();
    };

    return (
        <div className="container-fluid">
            <PageHeader {...pageHeader} />
            <CreateLocationModal showModal={createLocationShowModal} handleClose={handleCloseCreateLocationShowModal} />
            <div className="main-container">
                <div className="configure-columns" onClick={handleConfigureColumnsClick}>
                    <i className="fas fa-cogs configure-icon" />
                    <span>Configure Columns</span>
                </div>
                <div className="employee-container">
                    <table className="employee-table">
                        <thead>
                            <tr>
                                {columns.locationName && (
                                    <th>
                                        Location Name
                                        <i className="fas fa-sort ml-2" />
                                    </th>
                                )}
                                {columns.locationCode && (
                                    <th>
                                        Location Code
                                        <i className="fas fa-sort ml-2" />
                                    </th>
                                )}
                                {columns.locationId && (
                                    <th>
                                        Location ID
                                        <i className="fas fa-sort ml-2" />
                                    </th>
                                )}
                            </tr>
                        </thead>
                        <tbody>
                            {/* Your table rows */}
                            <tr>
                                {columns.locationName && (
                                    <td>
                                        <Link to="/client/backoffice-manager/employee/employee-detail">Verina</Link>
                                    </td>
                                )}

                                {columns.locationCode && (
                                    <td>
                                        <Link to="/client/backoffice-manager/employee/employee-detail">1101</Link>
                                    </td>
                                )}
                                {columns.locationId && (
                                    <td>
                                        <Link to="/client/backoffice-manager/employee/employee-detail">VER01</Link>
                                    </td>
                                )}
                            </tr>
                            <tr>
                                {columns.locationName && (
                                    <td>
                                        <Link to="/client/backoffice-manager/employee/employee-detail">Siena</Link>
                                    </td>
                                )}

                                {columns.locationCode && (
                                    <td>
                                        <Link to="/client/backoffice-manager/employee/employee-detail">1201</Link>
                                    </td>
                                )}
                                {columns.locationId && (
                                    <td>
                                        <Link to="/client/backoffice-manager/employee/employee-detail">SIE02</Link>
                                    </td>
                                )}
                            </tr>
                            <tr>
                                {columns.locationName && (
                                    <td>
                                        <Link to="/client/backoffice-manager/employee/employee-detail">Sorrento</Link>
                                    </td>
                                )}

                                {columns.locationCode && (
                                    <td>
                                        <Link to="/client/backoffice-manager/employee/employee-detail">1301</Link>
                                    </td>
                                )}
                                {columns.locationId && (
                                    <td>
                                        <Link to="/client/backoffice-manager/employee/employee-detail">SOR03</Link>
                                    </td>
                                )}
                            </tr>
                            <tr>
                                {columns.locationName && (
                                    <td>
                                        <Link to="/client/backoffice-manager/employee/employee-detail">Capri</Link>
                                    </td>
                                )}

                                {columns.locationCode && (
                                    <td>
                                        <Link to="/client/backoffice-manager/employee/employee-detail">1401</Link>
                                    </td>
                                )}
                                {columns.locationId && (
                                    <td>
                                        <Link to="/client/backoffice-manager/employee/employee-detail">CAP04</Link>
                                    </td>
                                )}
                            </tr>
                            <tr>
                                {columns.locationName && (
                                    <td>
                                        <Link to="/client/backoffice-manager/employee/employee-detail">Frankfrut</Link>
                                    </td>
                                )}

                                {columns.locationCode && (
                                    <td>
                                        <Link to="/client/backoffice-manager/employee/employee-detail">1501</Link>
                                    </td>
                                )}
                                {columns.locationId && (
                                    <td>
                                        <Link to="/client/backoffice-manager/employee/employee-detail">FRA05</Link>
                                    </td>
                                )}
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <Modal show={showModal} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Configure Columns</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Check
                            type="checkbox"
                            id="locationName"
                            label="Location Name"
                            checked={tempColumns.locationName}
                            onChange={handleCheckboxChange}
                        />
                        <Form.Check
                            type="checkbox"
                            id="locationCode"
                            label="Location Code"
                            checked={tempColumns.locationCode}
                            onChange={handleCheckboxChange}
                        />
                        <Form.Check
                            type="checkbox"
                            id="locationId"
                            label="Location ID"
                            checked={tempColumns.locationId}
                            onChange={handleCheckboxChange}
                        />
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleSave}>
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default Location;
