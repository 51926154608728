import { request } from '../api/';
import { Client, ClientExtended, CreateClient } from '../types/client';
import { ClientUserSignup } from '../types/user';

export const createClient = async (clientProfile: CreateClient) => {
    const requestOptions = {
        method: 'POST',
        url: `${process.env.REACT_APP_API_ENDPOINT}/client/profile/create`,
        data: { ...clientProfile },
    };
    return request<{ message: string }>(requestOptions);
};

export const preSigninClientUser = async (clientUser: ClientUserSignup) => {
    const requestOptions = {
        method: 'POST',
        url: `${process.env.REACT_APP_API_ENDPOINT}/non-auth/client/user/pre-signin`,
        skipAuth: true,
        data: { ...clientUser },
    };

    return request(requestOptions);
};

export const getClientDetailsById = async (clientId: string) => {
    const requestOptions = {
        method: 'POST',
        url: `${process.env.REACT_APP_API_ENDPOINT}/client/profile/get/single`,
        data: { clientId },
    };

    return request<{ data: ClientExtended }>(requestOptions);
};

export const getClientDetailsByCode = async (clientCode: string) => {
    const requestOptions = {
        method: 'POST',
        url: `${process.env.REACT_APP_API_ENDPOINT}/client/profile/get/single`,
        data: { clientCode },
    };

    return request<{ data: ClientExtended }>(requestOptions);
};

export const generateSignUpLink = async (client: Client) => {
    const requestOptions = {
        method: 'POST',
        url: `${process.env.REACT_APP_API_ENDPOINT}/client/generate/signup/link`,
        data: { ...client },
    };

    return request<{ data: { signUpLink: string } }>(requestOptions);
};

export const getClientList = async (page: number, keyword?: string) => {
    const requestOptions = {
        method: 'GET',
        url: `${process.env.REACT_APP_API_ENDPOINT}/client/list`,
        queryParams: { page, keyword },
    };

    return request<{ data: Client[] }>(requestOptions);
};
