import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap'; // Added Form for form controls
import { Link } from 'react-router-dom'; // Import Link for navigation
import { PageHeaderType } from '../../../../types/menu';
import PageHeader from '../../../common/Header/PageHeader';
import './EmployeeCategory.css';
import CreateEmployeeCategoryModal from './CreateEmployeeCategoryModal';

export const EmployeeCategory = () => {
    const [showModal, setShowModal] = useState(false);
    const [createEmployeeCategoryShowModal, setCreateEmployeeCategoryShowModal] = useState(false);

    // State for actual columns display
    const [columns, setColumns] = useState({
        Name: true,
        lastName: true,
        code: true,
        employeecategoryID: true,
    });

    // Temporary state for modal
    const [tempColumns, setTempColumns] = useState(columns);

    const handleCloseCreateEmployeeCategoryShowModal = () => {
        setCreateEmployeeCategoryShowModal(false);
    };

    const handleShowCreateEmployeeCategoryShowModal = () => {
        setCreateEmployeeCategoryShowModal(true);
    };

    const pageHeader: PageHeaderType = {
        title: 'Employee Category',
        prevNavLabel: 'Dashboard',
        prevNavLink: '/client/landing',
        iconClass: 'fa-users',
        modal: {
            showModalHandler: handleShowCreateEmployeeCategoryShowModal,
        },
        createPageType: 'modal',
    };

    const handleConfigureColumnsClick = () => {
        setTempColumns(columns); // Sync tempColumns with current columns state
        setShowModal(true);
    };

    const handleClose = () => {
        setShowModal(false);
    };

    // Function to handle checkbox changes in modal
    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { id, checked } = event.target;
        setTempColumns((prevState) => ({
            ...prevState,
            [id]: checked,
        }));
    };

    // Function to save changes
    const handleSave = () => {
        setColumns(tempColumns);
        handleClose();
    };

    return (
        <div className="container-fluid">
            <PageHeader {...pageHeader} />
            <CreateEmployeeCategoryModal
                showModal={createEmployeeCategoryShowModal}
                handleClose={handleCloseCreateEmployeeCategoryShowModal}
            />
            <div className="main-container">
                <div className="configure-columns" onClick={handleConfigureColumnsClick}>
                    <i className="fas fa-cogs configure-icon" />
                    <span>Configure Columns</span>
                </div>
                <div className="employee-container">
                    <table className="employee-table">
                        <thead>
                            <tr>
                                {columns.Name && (
                                    <th>
                                        Name
                                        <i className="fas fa-sort ml-2" />
                                    </th>
                                )}
                                {columns.code && (
                                    <th>
                                        Code
                                        <i className="fas fa-sort ml-2" />
                                    </th>
                                )}
                                {columns.employeecategoryID && (
                                    <th>
                                        Employee Category ID
                                        <i className="fas fa-sort ml-2" />
                                    </th>
                                )}
                            </tr>
                        </thead>
                        <tbody>
                            {/* Your table rows */}
                            <tr>
                                {columns.Name && (
                                    <td>
                                        <Link to="/client/backoffice-manager/employee-category/employeecategory-detail">
                                            Accounting
                                        </Link>
                                    </td>
                                )}

                                {columns.code && (
                                    <td>
                                        <Link to="/client/backoffice-manager/employee-category/employeecategory-detail">
                                            98322
                                        </Link>
                                    </td>
                                )}
                                {columns.employeecategoryID && (
                                    <td>
                                        <Link to="/client/backoffice-manager/employee-category/employeecategory-detail">
                                            23343
                                        </Link>
                                    </td>
                                )}
                            </tr>
                            <tr>
                                {columns.Name && (
                                    <td>
                                        <Link to="/client/backoffice-manager/employee-category/employeecategory-detail">
                                            Finance
                                        </Link>
                                    </td>
                                )}

                                {columns.code && (
                                    <td>
                                        <Link to="/client/backoffice-manager/employee-category/employeecategory-detail">
                                            98922
                                        </Link>
                                    </td>
                                )}
                                {columns.employeecategoryID && (
                                    <td>
                                        <Link to="/client/backoffice-manager/employee-category/employeecategory-detail">
                                            93827
                                        </Link>
                                    </td>
                                )}
                            </tr>
                            <tr>
                                {columns.Name && (
                                    <td>
                                        <Link to="/client/backoffice-manager/employee-category/employeecategory-detail">
                                            Marketing
                                        </Link>
                                    </td>
                                )}

                                {columns.code && (
                                    <td>
                                        <Link to="/client/backoffice-manager/employee-category/employeecategory-detail">
                                            8362
                                        </Link>
                                    </td>
                                )}
                                {columns.employeecategoryID && (
                                    <td>
                                        <Link to="/client/backoffice-manager/employee-category/employeecategory-detail">
                                            9823
                                        </Link>
                                    </td>
                                )}
                            </tr>
                            <tr>
                                {columns.Name && (
                                    <td>
                                        <Link to="/client/backoffice-manager/employee-category/employeecategory-detail">
                                            Sales
                                        </Link>
                                    </td>
                                )}

                                {columns.code && (
                                    <td>
                                        <Link to="/client/backoffice-manager/employee-category/employeecategory-detail">
                                            67621
                                        </Link>
                                    </td>
                                )}
                                {columns.employeecategoryID && (
                                    <td>
                                        <Link to="/client/backoffice-manager/employee-category/employeecategory-detail">
                                            62632
                                        </Link>
                                    </td>
                                )}
                            </tr>
                            <tr>
                                {columns.Name && (
                                    <td>
                                        <Link to="/client/backoffice-manager/employee-category/employeecategory-detail">
                                            Customer Care
                                        </Link>
                                    </td>
                                )}

                                {columns.code && (
                                    <td>
                                        <Link to="/client/backoffice-manager/employee-category/employeecategory-detail">
                                            98226
                                        </Link>
                                    </td>
                                )}
                                {columns.employeecategoryID && (
                                    <td>
                                        <Link to="/client/backoffice-manager/employee-category/employeecategory-detail">
                                            87536
                                        </Link>
                                    </td>
                                )}
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <Modal show={showModal} onHide={handleClose}>
                <Modal.Header style={{ color: 'black', background: '#89c8ff' }} closeButton>
                    <Modal.Title>Configure Columns</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Check
                            type="checkbox"
                            id="Name"
                            label="Name"
                            checked={tempColumns.Name}
                            onChange={handleCheckboxChange}
                        />
                        <Form.Check
                            type="checkbox"
                            id="code"
                            label="Code"
                            checked={tempColumns.code}
                            onChange={handleCheckboxChange}
                        />
                        <Form.Check
                            type="checkbox"
                            id="employeecategoryID"
                            label="Employee Category ID"
                            checked={tempColumns.employeecategoryID}
                            onChange={handleCheckboxChange}
                        />
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleSave}>
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default EmployeeCategory;
