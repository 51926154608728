import React, { useState } from 'react';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import { PageHeaderType } from '../../../types/menu';
import PageHeader from '../Header/PageHeader';
import './ViewUserProfile.css';

export const ViewUserProfile = () => {
    const pageHeader: PageHeaderType = {
        title: 'User profile',
        prevNavLabel: 'Dashboard',
        prevNavLink: '/client/landing',
        iconClass: 'fa-user-tie',
    };

    const [key, setKey] = useState<string>('current');
    const [isEditingCurrent, setIsEditingCurrent] = useState<boolean>(false);
    const [isEditingPermanent, setIsEditingPermanent] = useState<boolean>(false);

    const [currentAddress, setCurrentAddress] = useState({
        address: '153 Caren Pike, Ricehav MA 235089, USA',
        country: 'USA',
        state: 'Texas',
        city: 'Houston',
        pincode: '45402',
    });

    const [permanentAddress, setPermanentAddress] = useState({
        address: '153 Caren Pike, Ricehav MA 235089, USA',
        country: 'USA',
        state: 'Texas',
        city: 'Houston',
        pincode: '45402',
    });

    const handleCurrentAddressChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setCurrentAddress({ ...currentAddress, [e.target.name]: e.target.value });
    };

    const handlePermanentAddressChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPermanentAddress({ ...permanentAddress, [e.target.name]: e.target.value });
    };

    return (
        <div className="container-fluid">
            <PageHeader {...pageHeader} />
            <div className="container rounded bg-white mt-5 mb-5">
                <div className="row">
                    <div className="col-md-3">
                        <div className="d-flex flex-column align-items-center text-center p-3 py-5">
                            <img
                                className="rounded-circle mt-5"
                                width="150px"
                                src="https://st3.depositphotos.com/15648834/17930/v/600/depositphotos_179308454-stock-illustration-unknown-person-silhouette-glasses-profile.jpg"
                                alt="Profile"
                            />
                            <span className="font-weight-bold">Lodha Agarwal</span>
                            <span className="text-black-50">Lodhaagarwal@gmail.com</span>
                            <div className="row mt-2">
                                <div className="col-md-6">
                                    <label className="labels">Joining Date:</label>
                                    <input type="text" className="form-control" placeholder="" value="17-06-24" readOnly />
                                </div>
                                <div className="col-md-6">
                                    <label className="labels">Relieving Date:</label>
                                    <input type="text" className="form-control" value="27-08-24" placeholder="" readOnly />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="p-3 py-5">
                            <div className="d-flex justify-content-between align-items-center mb-3">
                                <h3 className="text-right">Personal Details</h3>
                                <i className="fa fa-id-card" />
                            </div>
                            <div className="row mt-2">
                                <div className="col-md-6">
                                    <label className="labels">First Name:</label>
                                    <input type="text" className="form-control" placeholder="" value="Lodha" readOnly />
                                </div>
                                <div className="col-md-6">
                                    <label className="labels">Last Name:</label>
                                    <input type="text" className="form-control" value="Agarwal" placeholder="" readOnly />
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-md-12">
                                    <label className="labels">User Id:</label>
                                    <input type="text" className="form-control" placeholder="" value="C21ZSN" readOnly />
                                </div>
                                <div className="col-md-12">
                                    <label className="labels">DOB:</label>
                                    <input type="text" className="form-control" placeholder="" value="26-01-1990" readOnly />
                                </div>
                                <div className="col-md-12">
                                    <label className="labels">Gender:</label>
                                    <input type="text" className="form-control" placeholder="" value="Male" readOnly />
                                </div>
                                <div className="col-md-12">
                                    <label className="labels">Blood Group:</label>
                                    <input type="text" className="form-control" placeholder="" value="A+" readOnly />
                                </div>
                                <div className="col-md-12">
                                    <label className="labels">Marital Status:</label>
                                    <input type="text" className="form-control" placeholder="" value="Married" readOnly />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="p-3 py-5">
                            <div className="d-flex justify-content-between align-items-center mb-3">
                                <h3 className="text-right">Contact Details</h3>
                                <i className="fa fa-phone" />
                            </div>
                            <div className="row mt-3">
                                <div className="col-md-6">
                                    <label className="labels">Contact Number:</label>
                                    <input type="text" className="form-control" placeholder="" value="9998899988" readOnly />
                                </div>
                                <br />
                                <div className="col-md-6">
                                    <label className="labels">Personal Email:</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder=""
                                        value="lodhaagarwal@gmail.com"
                                        readOnly
                                    />
                                </div>
                                <br />
                                <div className="col-md-6">
                                    <label className="labels">Emergency Contact Primary:</label>
                                    <input type="text" className="form-control" placeholder="" value="Assistant" readOnly />
                                </div>
                                <br />
                                <div className="col-md-6">
                                    <label className="labels">Emergency Contact Primary Number:</label>
                                    <input type="text" className="form-control" placeholder="" value="9998889997" readOnly />
                                </div>
                                <br />
                                <div className="col-md-6">
                                    <label className="labels">Emergency Contact Secondary:</label>
                                    <input type="text" className="form-control" placeholder="" value="Manager" readOnly />
                                </div>
                                <br />
                                <div className="col-md-6">
                                    <label className="labels">Emergency Contact Secondary Number:</label>
                                    <input type="text" className="form-control" placeholder="" value="8889998887" readOnly />
                                </div>
                            </div>
                            <br />
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="d-flex justify-content-between align-items-center mb-3">
                            <h3 className="text-right">Address Details</h3>
                            <i className="fa fa-map-marker" />
                        </div>
                        <Tabs
                            id="address-tabs"
                            activeKey={key}
                            onSelect={(k: string | null) => k && setKey(k)}
                            className="mb-3"
                        >
                            <Tab eventKey="current" title="Current Address">
                                <div className="col-md-12">
                                    <label className="labels">Address:</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="address"
                                        value={currentAddress.address}
                                        onChange={handleCurrentAddressChange}
                                        readOnly={!isEditingCurrent}
                                    />
                                </div>
                                <div className="row mt-3">
                                    <div className="col-md-6">
                                        <label className="labels">Country:</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="country"
                                            value={currentAddress.country}
                                            onChange={handleCurrentAddressChange}
                                            readOnly={!isEditingCurrent}
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <label className="labels">State:</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="state"
                                            value={currentAddress.state}
                                            onChange={handleCurrentAddressChange}
                                            readOnly={!isEditingCurrent}
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <label className="labels">City:</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="city"
                                            value={currentAddress.city}
                                            onChange={handleCurrentAddressChange}
                                            readOnly={!isEditingCurrent}
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <label className="labels">Pincode:</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="pincode"
                                            value={currentAddress.pincode}
                                            onChange={handleCurrentAddressChange}
                                            readOnly={!isEditingCurrent}
                                        />
                                    </div>
                                </div>
                                <button
                                    className="btn btn-primary mt-3"
                                    onClick={() => setIsEditingCurrent(!isEditingCurrent)}
                                >
                                    {isEditingCurrent ? 'Save' : 'Edit'}
                                </button>
                            </Tab>
                            <Tab eventKey="permanent" title="Permanent Address">
                                <div className="col-md-12">
                                    <label className="labels">Address:</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="address"
                                        value={permanentAddress.address}
                                        onChange={handlePermanentAddressChange}
                                        readOnly={!isEditingPermanent}
                                    />
                                </div>
                                <div className="row mt-3">
                                    <div className="col-md-6">
                                        <label className="labels">Country:</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="country"
                                            value={permanentAddress.country}
                                            onChange={handlePermanentAddressChange}
                                            readOnly={!isEditingPermanent}
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <label className="labels">State:</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="state"
                                            value={permanentAddress.state}
                                            onChange={handlePermanentAddressChange}
                                            readOnly={!isEditingPermanent}
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <label className="labels">City:</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="city"
                                            value={permanentAddress.city}
                                            onChange={handlePermanentAddressChange}
                                            readOnly={!isEditingPermanent}
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <label className="labels">Pincode:</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="pincode"
                                            value={permanentAddress.pincode}
                                            onChange={handlePermanentAddressChange}
                                            readOnly={!isEditingPermanent}
                                        />
                                    </div>
                                </div>
                                <button
                                    className="btn btn-primary mt-3"
                                    onClick={() => setIsEditingPermanent(!isEditingPermanent)}
                                >
                                    {isEditingPermanent ? 'Save' : 'Edit'}
                                </button>
                            </Tab>
                        </Tabs>
                    </div>
                </div>
            </div>
        </div>
    );
};
