import React from 'react';
import { PageHeaderType } from '../../../types/menu';

const PageHeader = (props: PageHeaderType) => {
    const { title, iconClass, modal, createPageType } = props;

    return (
        <>
            <header className="page-header page-header-compact page-header-light border-bottom bg-white mb-4">
                <div className="container-xl px-4">
                    <div className="page-header-content">
                        <div className="row align-items-center justify-content-between pt-3">
                            <div className="col-auto mb-3">
                                <h1 className="page-header-title">
                                    <div className="page-header-icon">
                                        <i className={`fa ${iconClass}`} />
                                    </div>
                                    {title ?? 'PMS System'}
                                </h1>
                            </div>
                            <div className="row align-items-center justify-content-between pt-3">
                                <div className="col-auto mb-3">
                                    <form className="search-form">
                                        <input type="text" placeholder="Search by name, code..." className="search-input" />
                                        <button type="submit" className="search-button">
                                            <i className="fas fa-search" />
                                        </button>
                                    </form>
                                </div>
                                <div className="col-auto mb-3">
                                    <div className="filter-component">
                                        <p>
                                            <strong>
                                                <i className="fa fa-filter" /> Advance Filters
                                            </strong>
                                        </p>
                                    </div>
                                </div>
                                {createPageType && createPageType === 'modal' && (
                                    <div className="col-auto mb-3">
                                        <button type="button" className="create-btn" onClick={modal?.showModalHandler}>
                                            Create
                                        </button>
                                    </div>
                                )}

                                {createPageType && createPageType === 'link' && (
                                    <div className="col-auto mb-3">
                                        <button type="button" className="create-btn">
                                            Create
                                        </button>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </>
    );
};

export default PageHeader;
