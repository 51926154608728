import { createSelector } from '@reduxjs/toolkit';
import { IRootState } from '../store';

// Define selector functions to extract various user properties from the state
const selectUser = (state: IRootState) => state.user;

// Create memoized selectors using createSelector
export const makeSelectUsername = createSelector(selectUser, (user) => user.username);

export const makeSelectOfficialEmail = createSelector(selectUser, (user) => user.officialEmail);

export const makeSelectRole = createSelector(selectUser, (user) => user.role);

export const makeSelectFirstName = createSelector(selectUser, (user) => user.firstName);

export const makeSelectLastName = createSelector(selectUser, (user) => user.lastName);

export const makeSelectUserLevel = createSelector(selectUser, (user) => user.userLevel);
