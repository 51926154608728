/* eslint-disable no-console */
/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice } from '@reduxjs/toolkit';
import { LoggedInUser } from '../types/user';
// Slice
const slice = createSlice({
    name: 'user',
    initialState: {} as LoggedInUser,
    reducers: {
        setProfile: (state, action) => {
            return {
                ...state,
                ...action.payload,
            };
        },
    },
});
export default slice.reducer;

// Actions
const { setProfile } = slice.actions;
export const setLoggedInUser = (user: LoggedInUser) => async (dispatch: any) => {
    try {
        return dispatch(setProfile(user));
    } catch (e: any) {
        return console.error(e.message);
    }
};
