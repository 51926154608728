import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { PageHeaderType } from '../../../../types/menu';
import PageHeader from '../../../common/Header/PageHeader';
import CreateRentabilityModal from './CreateRentabilitySetModal';
import './RentabilitySet.css';

export const Rentability = () => {
    const [createRentabilityShowModal, setCreateRentabilityShowModal] = useState(false);

    const handleCloseCreateRentabilityShowModal = () => {
        setCreateRentabilityShowModal(false);
    };

    const handleShowCreateRentabilityShowModal = () => {
        setCreateRentabilityShowModal(true);
    };

    const pageHeader: PageHeaderType = {
        title: 'RentabilitySet',
        prevNavLabel: 'Dashboard',
        prevNavLink: '/client/landing',
        iconClass: 'fa-solid fa fa-house',
        modal: {
            showModalHandler: handleShowCreateRentabilityShowModal,
        },
        createPageType: 'modal',
    };

    return (
        <div className="container-fluid">
            <PageHeader {...pageHeader} />
            <CreateRentabilityModal
                showModal={createRentabilityShowModal}
                handleClose={handleCloseCreateRentabilityShowModal}
            />
            <div className="main-container">
                <div className="rentability-container">
                    <div className="row ">
                        <Link to="/client/rate-manager/rentabilityset/rentabilityset-detail">
                            <h5>
                                <strong>Royal Retreat</strong>
                            </h5>
                        </Link>

                        <div className="col-12 pl-4">
                            <a href="/client/rate-manager/rentabilityset/rentabilityset-detail">
                                <strong>
                                    Code: 1101, Location: Verina, Starting Date: 18/09/2024, End Date: 20/09/2025
                                </strong>
                            </a>
                        </div>
                    </div>

                    <hr />

                    <div className="row ">
                        <Link to="/client/rate-manager/rentabilityset/rentabilityset-detail">
                            <h5>
                                <strong>Luxe Lagoon</strong>
                            </h5>
                        </Link>

                        <div className="col-12 pl-4">
                            <a href="/client/rate-manager/rentabilityset/rentabilityset-detail">
                                <strong>Code: 1201, Location: Siena, Starting Date: 3/07/2024, End Date: 5/07/2025</strong>
                            </a>
                        </div>
                    </div>

                    <hr />

                    <div className="row ">
                        <Link to="/client/rate-manager/rentabilityset/rentabilityset-detail">
                            <h5>
                                <strong>Gided Garden</strong>
                            </h5>
                        </Link>

                        <div className="col-12 pl-4">
                            <a href="/client/rate-manager/rentabilityset/rentabilityset-detail">
                                <strong>
                                    Code: 1301, Location: Sorrento, Starting Date: 31/05/2024, End Date: 1/05/2025
                                </strong>
                            </a>
                        </div>
                    </div>

                    <hr />

                    <div className="row ">
                        <Link to="/client/rate-manager/rentabilityset/rentabilityset-detail">
                            <h5>
                                <strong>Majestic Manor</strong>
                            </h5>
                        </Link>

                        <div className="col-12 pl-4">
                            <a href="/client/rate-manager/rentabilityset/rentabilityset-detail">
                                <strong>
                                    Code: 1401, Location: capri , Starting Date: 16/01/2024, End Date: 20/01/2025
                                </strong>
                            </a>
                        </div>
                    </div>

                    <hr />
                    <div className="row ">
                        <Link to="/client/rate-manager/rentabilityset/rentabilityset-detail">
                            <h5>
                                <strong>Splendid Sanctuary</strong>
                            </h5>
                        </Link>

                        <div className="col-12 pl-4">
                            <a href="/client/rate-manager/rentabilityset/rentabilityset-detail">
                                <strong>
                                    Code: 1501, Location: Frankfrut, Starting Date: 9/02/2024, End Date: 11/02/2025
                                </strong>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Rentability;
