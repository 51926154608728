import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap'; // Added Form for form controls
import { Link } from 'react-router-dom'; // Import Link for navigation
import { PageHeaderType } from '../../../../types/menu';
import PageHeader from '../../../common/Header/PageHeader';
import './Distribution.css';
import CreateDistributionModal from './CreateDistributionModal';

export const Distribution = () => {
    const [showModal, setShowModal] = useState(false);
    const [createDistributionShowModal, setCreateDistributionShowModal] = useState(false);

    // State for actual columns display
    const [columns, setColumns] = useState({
        Name: true,
        lastName: true,
        code: true,
        distributionID: true,
    });

    // Temporary state for modal
    const [tempColumns, setTempColumns] = useState(columns);

    const handleCloseCreateDistributionShowModal = () => {
        setCreateDistributionShowModal(false);
    };

    const handleShowCreateDistributionShowModal = () => {
        setCreateDistributionShowModal(true);
    };

    const pageHeader: PageHeaderType = {
        title: 'Distribution Channel',
        prevNavLabel: 'Dashboard',
        prevNavLink: '/client/landing',
        iconClass: 'fa-solid fa-truck-plane',
        modal: {
            showModalHandler: handleShowCreateDistributionShowModal,
        },
        createPageType: 'modal',
    };

    const handleConfigureColumnsClick = () => {
        setTempColumns(columns); // Sync tempColumns with current columns state
        setShowModal(true);
    };

    const handleClose = () => {
        setShowModal(false);
    };

    // Function to handle checkbox changes in modal
    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { id, checked } = event.target;
        setTempColumns((prevState) => ({
            ...prevState,
            [id]: checked,
        }));
    };

    // Function to save changes
    const handleSave = () => {
        setColumns(tempColumns);
        handleClose();
    };

    return (
        <div className="container-fluid">
            <PageHeader {...pageHeader} />
            <CreateDistributionModal
                showModal={createDistributionShowModal}
                handleClose={handleCloseCreateDistributionShowModal}
            />
            <div className="main-container">
                <div className="configure-columns" onClick={handleConfigureColumnsClick}>
                    <i className="fas fa-cogs configure-icon" />
                    <span>Configure Columns</span>
                </div>
                <div className="employee-container">
                    <table className="employee-table">
                        <thead>
                            <tr>
                                {columns.Name && (
                                    <th>
                                        Name
                                        <i className="fas fa-sort ml-2" />
                                    </th>
                                )}
                                {columns.code && (
                                    <th>
                                        Code
                                        <i className="fas fa-sort ml-2" />
                                    </th>
                                )}
                                {columns.distributionID && (
                                    <th>
                                        Distribution Channel ID
                                        <i className="fas fa-sort ml-2" />
                                    </th>
                                )}
                            </tr>
                        </thead>
                        <tbody>
                            {/* Your table rows */}
                            <tr>
                                {columns.Name && (
                                    <td>
                                        <Link to="/client/backoffice-manager/employee/employee-detail">Booking.com</Link>
                                    </td>
                                )}

                                {columns.code && (
                                    <td>
                                        <Link to="/client/backoffice-manager/employee/employee-detail">B111</Link>
                                    </td>
                                )}
                                {columns.distributionID && (
                                    <td>
                                        <Link to="/client/backoffice-manager/employee/employee-detail">63821</Link>
                                    </td>
                                )}
                            </tr>
                            <tr>
                                {columns.Name && (
                                    <td>
                                        <Link to="/client/backoffice-manager/employee/employee-detail">Tiket.com</Link>
                                    </td>
                                )}

                                {columns.code && (
                                    <td>
                                        <Link to="/client/backoffice-manager/employee/employee-detail">T112</Link>
                                    </td>
                                )}
                                {columns.distributionID && (
                                    <td>
                                        <Link to="/client/backoffice-manager/employee/employee-detail">83362</Link>
                                    </td>
                                )}
                            </tr>
                            <tr>
                                {columns.Name && (
                                    <td>
                                        <Link to="/client/backoffice-manager/employee/employee-detail">Trip.com</Link>
                                    </td>
                                )}

                                {columns.code && (
                                    <td>
                                        <Link to="/client/backoffice-manager/employee/employee-detail">TR113</Link>
                                    </td>
                                )}
                                {columns.distributionID && (
                                    <td>
                                        <Link to="/client/backoffice-manager/employee/employee-detail">14263</Link>
                                    </td>
                                )}
                            </tr>
                            <tr>
                                {columns.Name && (
                                    <td>
                                        <Link to="/client/backoffice-manager/employee/employee-detail">Hotelbeds.com</Link>
                                    </td>
                                )}

                                {columns.code && (
                                    <td>
                                        <Link to="/client/backoffice-manager/employee/employee-detail">H114</Link>
                                    </td>
                                )}
                                {columns.distributionID && (
                                    <td>
                                        <Link to="/client/backoffice-manager/employee/employee-detail">03832</Link>
                                    </td>
                                )}
                            </tr>
                            <tr>
                                {columns.Name && (
                                    <td>
                                        <Link to="/client/backoffice-manager/employee/employee-detail">Traveloka.com</Link>
                                    </td>
                                )}

                                {columns.code && (
                                    <td>
                                        <Link to="/client/backoffice-manager/employee/employee-detail">TL115</Link>
                                    </td>
                                )}
                                {columns.distributionID && (
                                    <td>
                                        <Link to="/client/backoffice-manager/employee/employee-detail">29465</Link>
                                    </td>
                                )}
                            </tr>
                            <tr>
                                {columns.Name && (
                                    <td>
                                        <Link to="/client/backoffice-manager/employee/employee-detail">go.mmt.com</Link>
                                    </td>
                                )}

                                {columns.code && (
                                    <td>
                                        <Link to="/client/backoffice-manager/employee/employee-detail">G116</Link>
                                    </td>
                                )}
                                {columns.distributionID && (
                                    <td>
                                        <Link to="/client/backoffice-manager/employee/employee-detail">51042</Link>
                                    </td>
                                )}
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <Modal show={showModal} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Configure Columns</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Check
                            type="checkbox"
                            id="Name"
                            label="Name"
                            checked={tempColumns.Name}
                            onChange={handleCheckboxChange}
                        />
                        <Form.Check
                            type="checkbox"
                            id="code"
                            label="Code"
                            checked={tempColumns.code}
                            onChange={handleCheckboxChange}
                        />
                        <Form.Check
                            type="checkbox"
                            id="distributionID"
                            label="Distribution ID"
                            checked={tempColumns.distributionID}
                            onChange={handleCheckboxChange}
                        />
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleSave}>
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default Distribution;
